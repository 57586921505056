import moment from 'moment'
export const callNumber = (num) => {
    return new Intl.NumberFormat('us', {}).format(num)
}

export const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format('LLLL')
    return buildDate
}

const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}` : key)
        })
    } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
    }
}
export const jsonToFormData = (data) => {
    const formData = new FormData()
    buildFormData(formData, data)
    return formData
}
