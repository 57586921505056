import React from 'react'
import { TextField } from '@material-ui/core/'
import './style.css'
import Skeleton from '@material-ui/lab/Skeleton'
const FileUploadimg = (props) => {
    const removeFile = (file, from) => {
        props.removeImage(file)
    }

    const viewFile = (file, from) => {
        console.log('======================', file)
        if (props.name === from) {
            const newWindow = window.open(
                file.type
                    ? URL.createObjectURL(file)
                    : `${global.site_url}uploads/${props.folder}/${file}`,
                '_blank',
                'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
        }
    }
    return (
        <div className={`custom-upload`}>
            <label htmlFor={props.id} className={` ${props.uploadClass}`}>
                <input
                    type={'file'}
                    className={`${props.uploadClassName} `}
                    name={props.name}
                    onClick={props.onClick}
                    id={props.id}
                    hidden
                    multiple={props.multiple ? true : false}
                    onChange={props.onChange ? (e) => props.onChange(e) : null}
                    accept={props.accept ? `${props.accept}/*` : '*'}
                />
                <p>{props.label}</p>
                <span className={props.buttonClass}>{props.test}</span>
            </label>
            <span className="text-danger">{props.error}</span>
            <div className="row pt-5">
                <aside className="thumbsContainer">
                    {props.preview_image &&
                    props.preview_image instanceof Array &&
                    props.preview_image.length > 0
                        ? props.preview_image.map((file, index) => (
                              <div className="thumb" key={index}>
                                  <div className="thumbInner">
                                      {file.type === 'image/jpeg' ||
                                      file.type === 'image/png' ||
                                      file.type === 'image/jpeg' ? (
                                          <div className="thumbCnt">
                                              <img
                                                  src={URL.createObjectURL(file)}
                                                  className="img"
                                              />
                                              <div className="fileActions">
                                                  <span
                                                      onClick={() => removeFile(file, props.name)}
                                                      className="cancelBtn material-icons"
                                                  >
                                                      delete
                                                  </span>
                                                  <span
                                                      onClick={() => viewFile(file, props.name)}
                                                      className="viewBtn material-icons"
                                                  >
                                                      visibility
                                                  </span>
                                              </div>
                                              <div>{file.name}</div>
                                          </div>
                                      ) : (
                                          <>
                                              <div className="thumbCnt">
                                                  <div className="defaultThumb">
                                                      <span className="material-icons">
                                                          {file.type === 'pdf'
                                                              ? 'picture_as_pdf'
                                                              : file.type === 'doc' ||
                                                                file.type === 'docx'
                                                              ? 'description'
                                                              : file.type === 'mp4' ||
                                                                file.type === 'mpeg'
                                                              ? 'movie'
                                                              : 'description'}
                                                      </span>
                                                  </div>
                                                  <div className="fileActions">
                                                      <span
                                                          onClick={() =>
                                                              removeFile(file, props.name)
                                                          }
                                                          className="cancelBtn material-icons"
                                                      >
                                                          delete
                                                      </span>
                                                      <span
                                                          onClick={() => viewFile(file, props.name)}
                                                          className="viewBtn material-icons"
                                                      >
                                                          visibility
                                                      </span>
                                                  </div>
                                                  <div>{file.name}</div>
                                              </div>
                                          </>
                                      )}
                                  </div>
                              </div>
                          ))
                        : null}
                </aside>
            </div>
        </div>
    )
}

export default FileUploadimg
