import React from 'react'
import { Link } from 'react-router-dom'
import './welcomeHeader.css'
const WelcomeHeader = (props) => {
    return (
        <div className="sign-up">
            <div className="sign-up-header">
                <div className="sign-up-logo pt-3 welcome-header-logo">
                    <Link to="/">
                        <img
                            src="/assets/svg/gavel-logo.svg"
                            alt="gavel-logo"
                            className="logo-img"
                        />
                    </Link>
                </div>
                {props.children}
            </div>
        </div>
    )
}

export default WelcomeHeader
