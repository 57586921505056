import React, { useState } from 'react'

import './liveAuction.css'
import EndingSoon from '../Homepage/EndingSoon'

const LiveAuction = () => {
    const [filterData, setFilterData] = useState({
        make: '',
        model: '',
        transmission: '',
        orderby: 6,
    })
    const [searchTrigger, setSearchTrigger] = useState(false)

    const handleFilterChange = (e) => {
        const { name, value } = e.target
        setFilterData({ ...filterData, [name]: value })
    }

    return (
        <div className="live-auction customContainer pt-5">
            <EndingSoon
                filterData={filterData}
                searchTrigger={searchTrigger}
                setSearchTrigger={setSearchTrigger}
                handleFilterChange={handleFilterChange}
                heading="Live Auction"
                page={true}
                className="live-auction-productGrid productGrid"
            />
        </div>
    )
}

export default LiveAuction
