import React from 'react'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined'

import './productList.css'
import LazyImage from '../../atoms/LazyImage'
import FavoriteCheckbox from '../../atoms/FavoriteCheckbox'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../../../Utils/Timer'
import numeral from 'numeral'
const ProductList = (props) => {
    const { data } = props
    return (
        <div className="product-list">
            <div className="prdt-img-list-view">
                <LazyImage
                    src={`${process.env.REACT_APP_PRODUCT_URL}/${data.file_name}`}
                    alt={data.protitle}
                    className="product-img-list"
                />{' '}
                <FavoriteCheckbox
                    checked={data.watch}
                    productId={data.id}
                    onChecked={props.handleChecked}
                />
            </div>
            <div className="prdt-list-dtls">
                <div className="product-price w-100">
                    <div className="prod-title">
                        <p className="mb-1">
                            {data.tags} &nbsp;{data.title} &nbsp;{data.category_id}
                        </p>
                        {parseInt(data.rprice) === 0 ? (
                            <span className="noRsrvTxt">No Reserve</span>
                        ) : parseInt(data.rprice) <= data.wprice ? (
                            <span className="yesRsrvTxt">Reserve Met</span>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="w-100">
                        <div className="row d-flex align-items-center w-100 mb-3">
                            <div className="col-4">
                                <span className="bid-title">Start Bid</span>
                                <span className="product-price-dynamic">
                                    &nbsp; $
                                    {parseInt(data.wprice).toLocaleString().length > 5
                                        ? numeral(parseInt(data.wprice).toLocaleString()).format(
                                              '0 a',
                                          )
                                        : `${parseInt(data.wprice).toLocaleString()}`}
                                </span>
                            </div>
                            <div className="col-8">
                                {/* <span className="bid-time-left">Time Left :</span>&nbsp; */}
                                <span className="lstTimerCntnr">
                                    <Timer
                                        date_added={data.date_added}
                                        date_closed={data.date_closed}
                                        withText={1}
                                    />
                                </span>
                            </div>
                        </div>
                        <div>
                            <p className="bid-desc">Description :</p>
                            <p className="dscTextWrap">{data.auctionnotes}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <PrimaryButton onClick={props.onClick}>View Product</PrimaryButton>
                </div>
            </div>
        </div>
    )
}
export default ProductList
