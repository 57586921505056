import React, { useEffect } from 'react'
import './emailVerification.css'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import { request } from '../../Utils/Request'
import { useLocation, useHistory } from 'react-router-dom'

const EmailVerification = () => {
    const location = useLocation()
    const history = useHistory()
    const urlString = location.search.split('=')

    const emailVerifiedUser = async () => {
        try {
            const res = await request(
                'get',
                `${apiEndPoint.activate}/${urlString[1]}`,
                {},
                false,
                3,
            )
            if (res.data.success === true) {
                // alert('this is redirected')
                history.push('/login')
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        emailVerifiedUser()
    }, [])

    return (
        <div className="emailverification">
            <img src="/assets/svg/gavel-logo.svg" alt="gavel-logo" className="logo-img" />

            <div className="verify-note">
                <h5>Thank you for verfiying your email address.</h5>
            </div>
        </div>
    )
}

export default EmailVerification
