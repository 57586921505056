import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import './style.css'
import { step1Schema, step7Schema, step8Schema } from '../../Utils/Validatior'
import { useFormik } from 'formik'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import { yearList, countryList, objectFilter } from '../../Utils/Constants'
import { getAllMake, getModelsOfMake, getMakeById, getModelById } from '../../Utils/makemodel'
import { mapData } from '../../common/components'
import RadioBox from '../../components/atoms/RadioBox'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import CustomInputUpload from '../../components/atoms/Inputs/FileUpload'
import imageCompression from 'browser-image-compression'
import { callNumber } from '../../Utils/common'
import csc from 'country-state-city'
import { request } from '../../Utils/Request'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}))

const PostCar = () => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const steps = getSteps()
    const [details, setDetails] = useState({
        //Step 1 Data
        make: '',
        model: '',
        year: '',
        sold_by: '',
        country: '',
        city: '',
        state: '',
        vin: '',
        current_miles: '',
        body_style: '',
        exterior: '',
        interior: '',
        engine_size: '',
        original: 1,
        drive_train: '',
        transmission: '',
        records: 1,
        race_car: 2,
        repaint: '',
        title_status: '',

        //Step 2 data
        noteworthy_1: '',
        noteworthy_2: '',
        noteworthy_3: '',
        noteworthy_4: '',
        noteworthy_5: '',
        noteworthy_6: '',
        noteworthy_7: '',
        noteworthy_8: '',
        noteworthy_9: '',
        noteworthy_10: '',

        //step 3 data
        modification_1: '',
        modification_2: '',
        modification_3: '',
        modification_4: '',
        modification_5: '',
        modification_6: '',
        modification_7: '',
        modification_8: '',
        modification_9: '',
        modification_10: '',

        //step 4 data
        issue_1: '',
        issue_2: '',
        issue_3: '',
        issue_4: '',
        issue_5: '',
        issue_6: '',
        issue_7: '',
        issue_8: '',
        issue_9: '',
        issue_10: '',

        //step 5 data
        service_1: '',
        service_2: '',
        service_3: '',
        service_4: '',
        service_5: '',
        service_6: '',
        service_7: '',
        service_8: '',
        service_9: '',
        service_10: '',

        //step 6 data
        additional_1: '',
        additional_2: '',
        additional_3: '',
        additional_4: '',
        additional_5: '',
        additional_6: '',
        additional_7: '',
        additional_8: '',
        additional_9: '',
        additional_10: '',

        //step 7 data
        ownership: '',
        yourself_miles: '',

        //step 8 data
        url_1: '',
        url_2: '',
        url_3: '',
        url_4: '',
        url_5: '',
        url_6: '',
    })
    const [images, setImages] = useState([])
    const [document, setDocument] = useState([])
    const [schema] = useState([step1Schema, '', '', '', '', '', step7Schema, step8Schema])
    const { enqueueSnackbar } = useSnackbar()

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    const formik = useFormik({
        initialValues: details,
        validationSchema: schema[activeStep],
        // enableReinitialize: trigger,
        onSubmit: (values) => {
            if (activeStep === 7) {
                postProduct(values)
            } else if (activeStep === 0) {
                if (images.length < 2) {
                    return enqueueSnackbar('Please upload at least 25 images', {
                        variant: 'info',
                    })
                }
                handleNext()
            } else {
                handleNext()
            }
        },
    })
    const postProduct = async (val) => {
        try {
            const noteworthy = objectFilter(val, ([key]) => key.includes('noteworthy'))
            const modification = objectFilter(val, ([key]) => key.includes('modification'))
            const issue = objectFilter(val, ([key]) => key.includes('issue'))
            const service = objectFilter(val, ([key]) => key.includes('service'))
            const url = objectFilter(val, ([key]) => key.includes('url'))
            const data = {
                auction: 1,
                auctiontype: 'auction',
                market_status: 'draft',
                upccode: '1',
                protitle: getMakeById(val.make).value,
                category_id: getModelById(val.model).value,
                tags: val.year,
                qcedby: val.sold_by,
                location: csc.getCountryById(val.country).name,
                state: csc.getStateById(val.state).name,
                city: val.city,
                modelnumber: val.vin,
                sell_location: val.records,
                manufacturer: val.ownership,
                contenthead1: JSON.stringify(noteworthy),
                contenthead2: JSON.stringify(modification),
                contenthead3: JSON.stringify(issue),
                contenthead4: JSON.stringify(service),
                contenthead5: val.current_miles,
                contenthead6: val.yourself_miles,
                customfield1: val.body_style,
                customfield2: val.exterior,
                customfield3: val.interior,
                customfield4: val.race_car,
                customfield5: val.original,
                customfield6: val.engine_size,
                customfield7: val.repaint,
                customfield8: JSON.stringify(url),
                customfield9: val.drive_train,
                subcategory: val.transmission,
                auctionnotes: val.title_status,
            }
            const formData = new FormData()
            for (const [key, value] of Object.entries(data)) {
                formData.set(key, value)
            }
            images.map((value) => formData.append('product_image', value))
            const res = await request('post', '/sellerpostprojectlive', formData, true)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [activeStep])
    function getSteps() {
        return [
            'Highlights',
            'Noteworthy Equipment',
            'Modifications',
            'Issues & Flaws',
            'Service History',
            'Additional Items Included',
            'Ownership History',
            'Videos',
        ]
    }
    const handleChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.value)
    }
    const handleChangeRadio = (e) => {
        formik.setFieldValue(e.target.name, parseInt(e.target.value))
    }

    const removeImage = (index) => {
        images.splice(images.indexOf(index), 1)
        setImages([...images])
    }
    const removeDocument = (index) => {
        document.splice(document.indexOf(index), 1)
        setDocument([...document])
    }
    const compressionOptions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        // onProgress: onProgress,
    }
    const handleImageChange = (e) => {
        try {
            // console.log(e.target.files.length)
            var placeholder = new Array(e.target.files.length).fill('/assets/svg/loader.svg')
            var arr = [...images, ...placeholder]
            setImages(arr)
            Object.keys(e.target.files).map(async (value, key) => {
                var img = await imageCompression(e.target.files[key], compressionOptions)
                var temp = arr
                var index = temp.indexOf('/assets/svg/loader.svg')
                temp[index] = img
                console.log(temp)
                setImages((prevState) => [...temp])
                // setImages(temp)
                // var img = e.target.files[key]
            })
            e.target.value = ''
        } catch (error) {
            console.log(error)
        }
    }

    const handleDocumentChange = (e) => {
        try {
            if (document.length >= 2 || e.target.files.length > 2) {
                return enqueueSnackbar('Unable to upload more than two images!', {
                    variant: 'warning',
                })
            }
            var placeholder = new Array(e.target.files.length).fill('/assets/svg/loader.svg')
            var arr = [...document, ...placeholder]
            setDocument(arr)
            Object.keys(e.target.files).map(async (value, key) => {
                if (key > 1) {
                    return false
                }
                var img = await imageCompression(e.target.files[key], compressionOptions)
                var temp = arr
                var index = temp.indexOf('/assets/svg/loader.svg')
                temp[index] = img
                setDocument((prevState) => [...temp])
                // var img = e.target.files[key]
            })
            e.target.value = ''
        } catch (error) {
            console.log(error)
        }
    }
    const radioValue = [
        { id: 1, description: 'Yes' },
        { id: 2, description: 'No' },
    ]
    const body_style = ['Convertible', 'Coupe', 'Sedan', 'SUV', 'Other']
    const drive_train = ['Automatic', 'Manual', 'Other']
    const transmission = [
        'All Wheel Drive',
        'Four Wheel Drive',
        'Front Wheel Drive',
        'Rear Wheel Drive',
        'Other',
    ]
    const title_status = ['Clean', 'Salvage', 'Rebuild']

    // console.log(images)
    const handleSelectChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.value)
    }
    const firsthalf = [
        {
            label: 'What Country Is Vehicle Located In ?',
            type: 'select',
            placeholder: 'Select a country',
            class: 'col-6',
            options: countryList(),
            name: 'country',
            formik: formik,
            labelClass: 'text-left',
            onChange: handleChange,
        },
        {
            label: 'What City Is Vehicle Located In ?',
            type: 'text',
            placeholder: 'What City Is Vehicle Located In ?',
            class: 'col-6',
            name: 'city',
            formik: formik,
            labelClass: 'text-left',
            onChange: handleChange,
        },
        {
            label: 'What State, Provence, or Territory Is Vehicle Located In ?',
            type: 'select',
            placeholder: 'Select a state',
            class: 'col-6',
            options: csc.getStatesOfCountry(formik.values.country),
            name: 'state',
            formik: formik,
            labelClass: 'text-left',
            onChange: handleChange,
        },
    ]
    const step2Fields = Array.from(Array(10), (_, index) => ({
        label: `Noteworthy Equipment ${index + 1}`,
        type: 'text',
        placeholder: `Noteworthy Equipment ${index + 1}`,
        class: 'col-6',
        name: `noteworthy_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step3Fields = Array.from(Array(10), (_, index) => ({
        label: `Modification ${index + 1}`,
        type: 'text',
        placeholder: `Modification ${index + 1}`,
        class: 'col-6',
        name: `modification_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step4Fields = Array.from(Array(10), (_, index) => ({
        label: `Issue/Flaw ${index + 1}`,
        type: 'text',
        placeholder: `Issue/Flaw ${index + 1}`,
        class: 'col-6',
        name: `issue_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step5Fields = Array.from(Array(10), (_, index) => ({
        label: `Service History Note ${index + 1}`,
        type: 'text',
        placeholder: `Service History Note ${index + 1}`,
        class: 'col-6',
        name: `service_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step6Fields = Array.from(Array(10), (_, index) => ({
        label: `Additional Item Included ${index + 1}`,
        type: 'text',
        placeholder: `Additional Item Included ${index + 1}`,
        class: 'col-6',
        name: `additional_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step8Names = ['Exterior', 'Interior', 'Driving', 'Engine', 'Cold Start', 'Additional']
    const step8Fields = Array.from(Array(6), (_, index) => ({
        label: `Provide link to ${step8Names[index]} video of vehicle (Youtube or Vimeo)`,
        type: 'text',
        placeholder: `Provide link to ${step8Names[index]} video of vehicle (Youtube or Vimeo)`,
        class: 'col-6',
        name: `url_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step1 = (
        <div>
            <div className="row product-fist-half">
                <CustomSelect
                    label="Year of Vehicle"
                    id="year"
                    value={formik.values.year}
                    name="year"
                    onChange={handleSelectChange}
                    placeholder="Year of Vehicle"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.year && formik.errors.year}
                    helperText={formik.errors.year && formik.touched.year && formik.errors.year}
                    selectClassName="col-6"
                    labelClass="text-left"
                >
                    <option value="">Year of Vehicle</option>
                    {yearList().map((opt, optindex) => (
                        <option key={optindex} value={opt}>
                            {opt}
                        </option>
                    ))}
                    <option value="Other">Other</option>
                </CustomSelect>
                <CustomSelect
                    label="Vehicle Make"
                    id="make"
                    value={formik.values.make}
                    name="make"
                    onChange={handleSelectChange}
                    placeholder="Vehicle Make"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.make && formik.errors.make}
                    helperText={formik.errors.make && formik.touched.make && formik.errors.make}
                    selectClassName="col-3"
                    labelClass="text-left"
                >
                    <option value="">Vehicle Make</option>
                    {getAllMake().map((opt, optindex) => (
                        <option key={optindex} value={opt.id}>
                            {opt.value}
                        </option>
                    ))}
                    <option value="Other">Other</option>
                </CustomSelect>
                <CustomSelect
                    label="Vehicle model"
                    id="model"
                    value={formik.values.model}
                    name="model"
                    onChange={handleSelectChange}
                    placeholder="Vehicle model"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.model && formik.errors.model}
                    helperText={formik.errors.model && formik.touched.model && formik.errors.model}
                    selectClassName="col-3"
                    labelClass="text-left"
                >
                    <option value="">Vehicle model</option>
                    {formik.values.make &&
                        getModelsOfMake(formik.values.make).map((opt, optindex) => (
                            <option key={optindex} value={opt.id}>
                                {opt.value}
                            </option>
                        ))}
                    <option value="Other">Other</option>
                </CustomSelect>
                <CustomSelect
                    label="Vehicle Sold By"
                    id="sold_by"
                    value={formik.values.sold_by}
                    name="sold_by"
                    onChange={handleSelectChange}
                    placeholder="Vehicle Sold By"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.sold_by && formik.errors.sold_by}
                    helperText={
                        formik.errors.sold_by && formik.touched.sold_by && formik.errors.sold_by
                    }
                    selectClassName="col-12"
                    labelClass="text-left"
                >
                    <option value="">Vehicle Sold By</option>
                    <option value="Private Party">Private Party</option>
                    <option value="Dealer">Dealer</option>
                </CustomSelect>
                {Object.values(mapData(firsthalf))}
                <div className=" col-6 input-boxes">
                    <CustomInput
                        label="What is the Vehicle Identification Number (VIN#) / Registration Number for Boat, Airplane, RV"
                        placeholder="What is the Vehicle Identification Number (VIN#) / Registration Number for Boat, Airplane, RV"
                        labelClass="vch-id-label"
                        name="vin"
                        id="vin"
                        value={formik.values.vin}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.vin && formik.errors.vin ? formik.errors.vin : null
                        }
                        error={formik.touched.vin && formik.errors.vin}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="Current miles/hours on vehicle"
                        placeholder="Current miles/hours on vehicle"
                        labelClass="curnt-mile-label"
                        name="current_miles"
                        id="current_miles"
                        value={
                            callNumber(formik.values.current_miles.split(',').join('')) == 'NaN'
                                ? 0
                                : callNumber(formik.values.current_miles.split(',').join(''))
                        }
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.current_miles && formik.errors.current_miles
                                ? formik.errors.current_miles
                                : null
                        }
                        error={formik.touched.current_miles && formik.errors.current_miles}
                    />
                </div>
                <CustomSelect
                    label="Body Style"
                    id="body_style"
                    value={formik.values.body_style}
                    name="body_style"
                    onChange={handleSelectChange}
                    placeholder="Body Style"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.body_style && formik.errors.body_style}
                    helperText={
                        formik.errors.body_style &&
                        formik.touched.body_style &&
                        formik.errors.body_style
                    }
                    selectClassName="col-6"
                    labelClass="text-left"
                >
                    <option value="">Body Style</option>
                    {body_style.map((ele) => (
                        <option value={ele} key={ele}>
                            {ele}
                        </option>
                    ))}
                </CustomSelect>
                <div className=" col-6 input-boxes">
                    <CustomInput
                        label="Exterior Color"
                        placeholder="Exterior Color"
                        labelClass="exterior"
                        name="exterior"
                        id="exterior"
                        value={formik.values.exterior}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.exterior && formik.errors.exterior
                                ? formik.errors.exterior
                                : null
                        }
                        error={formik.touched.exterior && formik.errors.exterior}
                    />
                </div>
                <div className=" col-6 input-boxes">
                    <CustomInput
                        label="Interior Color"
                        placeholder="Interior Color"
                        labelClass="interior"
                        name="interior"
                        id="interior"
                        value={formik.values.interior}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.interior && formik.errors.interior
                                ? formik.errors.interior
                                : null
                        }
                        error={formik.touched.interior && formik.errors.interior}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="What size engine ?"
                        placeholder="What size engine ?"
                        labelClass="size-eng-label"
                        name="engine_size"
                        id="engine_size"
                        value={formik.values.engine_size}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.engine_size && formik.errors.engine_size
                                ? formik.errors.engine_size
                                : null
                        }
                        error={formik.touched.engine_size && formik.errors.engine_size}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <p>Is Engine original ?</p>
                    <RadioBox
                        items={radioValue}
                        name="original"
                        value={formik.values.original}
                        onChange={handleChangeRadio}
                    />
                </div>
                <CustomSelect
                    label="Drivetrain"
                    id="drive_train"
                    value={formik.values.drive_train}
                    name="drive_train"
                    onChange={handleSelectChange}
                    placeholder="Drivetrain"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.drive_train && formik.errors.drive_train}
                    helperText={
                        formik.errors.drive_train &&
                        formik.touched.drive_train &&
                        formik.errors.drive_train
                    }
                    selectClassName="col-6"
                    labelClass="text-left"
                >
                    <option value="">Drivetrain</option>
                    {drive_train.map((ele) => (
                        <option value={ele} key={ele}>
                            {ele}
                        </option>
                    ))}
                </CustomSelect>
                <CustomSelect
                    label="Transmission Type"
                    id="transmission"
                    value={formik.values.transmission}
                    name="transmission"
                    onChange={handleSelectChange}
                    placeholder="Transmission Type"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.transmission && formik.errors.transmission}
                    helperText={
                        formik.errors.transmission &&
                        formik.touched.transmission &&
                        formik.errors.transmission
                    }
                    selectClassName="col-6"
                    labelClass="text-left"
                >
                    <option value="">Transmission Type</option>
                    {transmission.map((ele) => (
                        <option value={ele} key={ele}>
                            {ele}
                        </option>
                    ))}
                </CustomSelect>
                <div className="col-6 listed-site">
                    <p>Do You Have Service Record History ?</p>
                    <RadioBox
                        items={radioValue}
                        name="records"
                        value={formik.values.records}
                        onChange={handleChangeRadio}
                    />
                </div>
                <div className="col-6 listed-site">
                    <p>Is This Vehicle a Racecar ?</p>
                    <RadioBox
                        items={radioValue}
                        name="race_car"
                        value={formik.values.race_car}
                        onChange={handleChangeRadio}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="Has the vehicle been repainted? When?"
                        placeholder="Has the vehicle been repainted? When?"
                        labelClass="repaint-label"
                        name="repaint"
                        id="repaint"
                        value={formik.values.repaint}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.repaint && formik.errors.repaint
                                ? formik.errors.repaint
                                : null
                        }
                        error={formik.touched.repaint && formik.errors.repaint}
                    />
                </div>
                <CustomSelect
                    label="Title Status"
                    id="title_status"
                    value={formik.values.title_status}
                    name="title_status"
                    onChange={handleSelectChange}
                    placeholder="Title Status"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.title_status && formik.errors.title_status}
                    helperText={
                        formik.errors.title_status &&
                        formik.touched.title_status &&
                        formik.errors.title_status
                    }
                    selectClassName="col-6"
                    labelClass="text-left"
                >
                    <option value="">Title Status</option>
                    {title_status.map((ele) => (
                        <option value={ele} key={ele}>
                            {ele}
                        </option>
                    ))}
                </CustomSelect>
                {/* 
                <div className="col-12 upload-area">
                    <CustomInputUpload
                        uploadClassName={'fileupload-signup'}
                        test={'Browse'}
                        label="Please upload clear, color images of the front and back of vehicles's title or registration"
                        htmlFor="document"
                        id="document"
                        uploadClass="img-upload-wrapper"
                        buttonClass="browse-upload"
                        name="document"
                        onChange={handleDocumentChange}
                        multiple={true}
                        error={formik.errors.document ? formik.errors.document : null}
                        accept="image"
                        preview_image={document}
                        removeImage={removeDocument}
                    />
                    <br />
                    <small style={{ color: 'red' }}>
                        {formik.errors.document ? formik.errors.document : null}
                    </small>
                </div> */}
                <div className="col-12 upload-area">
                    <CustomInputUpload
                        uploadClassName={'fileupload-signup'}
                        test={'Browse'}
                        label="Please upload at least 25 clear images of the interior, exterior, undercarriage, and engine compartment"
                        htmlFor="images"
                        id="images"
                        uploadClass="img-upload-wrapper"
                        buttonClass="browse-upload"
                        name="images"
                        onChange={handleImageChange}
                        multiple={true}
                        error={formik.errors.img_upload ? formik.errors.img_upload : null}
                        accept="image"
                        preview_image={images}
                        removeImage={removeImage}
                    />
                    <br />
                    <small style={{ color: 'red' }}>
                        {formik.errors.images ? formik.errors.images : null}
                    </small>
                </div>
            </div>
        </div>
    )
    const step7 = (
        <div className="row">
            <div className="col-6 input-boxes">
                <CustomInput
                    label="Length of Ownership"
                    placeholder="Length of Ownership"
                    labelClass="curnt-mile-label"
                    name="ownership"
                    id="ownership"
                    value={formik.values.ownership}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.ownership && formik.errors.ownership
                            ? formik.errors.ownership
                            : null
                    }
                    error={formik.touched.ownership && formik.errors.ownership}
                />
            </div>
            <div className="col-6 input-boxes">
                <CustomInput
                    label="How Many miles/hour have you added yourself ?"
                    placeholder="How Many miles/hour have you added yourself ?"
                    labelClass="curnt-mile-label"
                    name="yourself_miles"
                    id="yourself_miles"
                    value={
                        callNumber(formik.values.yourself_miles.split(',').join('')) == 'NaN'
                            ? 0
                            : callNumber(formik.values.yourself_miles.split(',').join(''))
                    }
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.yourself_miles && formik.errors.yourself_miles
                            ? formik.errors.yourself_miles
                            : null
                    }
                    error={formik.touched.yourself_miles && formik.errors.yourself_miles}
                />
            </div>
        </div>
    )
    function getStepContent(step) {
        switch (step) {
            case 0:
                return step1
            case 1:
                return <div className="row">{Object.values(mapData(step2Fields))}</div>
            case 2:
                return <div className="row">{Object.values(mapData(step3Fields))}</div>
            case 3:
                return <div className="row">{Object.values(mapData(step4Fields))}</div>
            case 4:
                return <div className="row">{Object.values(mapData(step5Fields))}</div>
            case 5:
                return <div className="row">{Object.values(mapData(step6Fields))}</div>
            case 6:
                return step7
            case 7:
                return <div className="row">{Object.values(mapData(step8Fields))}</div>
            default:
                return 'Unknown form'
        }
    }
    return (
        <div className={classes.root}>
            <form className="product-list-page-one" onSubmit={formik.handleSubmit}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <div className={classes.actionsContainer}>
                                    {getStepContent(index)}
                                    <div className="registration-submit row">
                                        <div className="col-6">
                                            <PrimaryButton
                                                type="button"
                                                onClick={handleBack}
                                                label={'Back'}
                                                customClassName="product-post-next"
                                                disabled={activeStep === 0}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <PrimaryButton
                                                type="submit"
                                                label={
                                                    activeStep === steps.length - 1
                                                        ? 'Finish'
                                                        : 'Next'
                                                }
                                                customClassName="product-post-next"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </form>
        </div>
    )
}
export default PostCar
