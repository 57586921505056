import React, { Fragment, useState, useEffect } from 'react'
import { request } from './Request'
import ReactHtmlParser from 'react-html-parser'
import Loader from '../components/organisms/Loader'
const StaticPage = ({ page_id }) => {
    const [value, setValue] = useState('<p></p>')
    const [loading, setLoading] = useState(true)
    const getPage = async () => {
        try {
            const res = await request('post', '/show_static_content', { page_id })
            if (res.data.status === 'true') {
                setValue(res.data.data[0].content)
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        getPage()
    }, [])
    return (
        <div className="staticPageWrapper p-5 text-left">
            {loading ? <Loader /> : <div className="customContainer">{ReactHtmlParser(value)}</div>}
        </div>
    )
}

export default StaticPage
