import csc from 'country-state-city'

export const VehicleMake = [
    { id: 'Toyata', value: 'Toyata', show: 'Toyata' },
    { id: 'Tata', value: 'Tata', show: 'Tata' },
    { id: 'BMW', value: 'BMW', show: 'BMW' },
    { id: 'Mercedes Benz', value: 'Mercedes Benz', show: 'Mercedes Benz' },
    { id: 'Hyndai', value: 'Hyndai', show: 'Hyndai' },
]

export const VehicleModel = [
    {
        id: '2008',
        value: '2008',
        show: '2008',
    },
    {
        id: '2009',
        value: '2009',
        show: '2009',
    },
    {
        id: '2010',
        value: '2010',
        show: '2010',
    },
    {
        id: '2011',
        value: '2011',
        show: '2011',
    },
    {
        id: '2012',
        value: '2012',
        show: '2012',
    },
]

export const transmissionType = [
    {
        id: 'Automatic',
        type: 'Automatic',
    },
    {
        id: 'Manual',
        type: 'Manual',
    },
]

export const dateTimeFormat = 'MMMM Do, YYYY h:mm a'

export const dateTimeYearFormat = 'MMMM Do, YYYY'

export const countryList = () => {
    const move = (from, to, ...arr) =>
        from === to ? arr : (arr.splice(to, 0, ...arr.splice(from, 1)), arr)
    var US_INDEX = csc.getAllCountries().findIndex((val) => val.name === 'United States')
    var CA_INDEX = csc.getAllCountries().findIndex((val) => val.name === 'Canada')

    var temp = move(CA_INDEX, 0, ...csc.getAllCountries())
    return move(US_INDEX, 0, ...temp)
}

export const yearList = () =>
    Array.from(Array(new Date().getFullYear() - 1899), (_, i) => (i + 1900).toString())

export const objectFilter = (obj, predicate) =>
    Object.fromEntries(Object.entries(obj).filter(predicate))
