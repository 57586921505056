import * as Yup from 'yup'

// Registration 1 validation schema
export const register1Schema = Yup.object().shape({
    full_name: Yup.string()
        .required('Full Name is required!')
        .matches(/^[aA-zZ\s]+$/, 'Full Name contains only alphabets!'),
    user_name: Yup.string()
        .matches(/^[aA-zZ0-9\s]+$/, 'Username contains only alphabets and numbers!')
        .required('User Name is required!'),
    email: Yup.string().email('Please enter a valid email!').required('Email is required!'),
    password: Yup.string()
        .min(8, 'Password contain alteast 8 characters!')
        .required('Password is required!'),
})

// Registration 2 validation schema
const FILE_SIZE = 1000000
const FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png']
export const register2Schema = Yup.object().shape({
    street_address: Yup.string().required('Street address is required!'),
    mobile_number: Yup.number()
        .typeError('Invalid mobile number!')
        .test('len', 'Invalid mobile number!', (val) => val && val.toString().length === 10)
        .required('Mobile number is required!'),
    city: Yup.string().required('City is required!'),
    state: Yup.string().required('State is required!'),
    country: Yup.string().required('Country is required!'),
    postal_zipCode: Yup.number()
        .typeError('Invalid postal code!')
        .required('Postal code is required!'),
    billing_card: Yup.string().required('Billing info is required!'),
    card_holder_name: Yup.string().when('billing_card', {
        is: 'credit_card',
        then: Yup.string()
            .matches(/^[aA-zZ\s]+$/, 'Card holder name contains only alphabets!')
            .required('Card holder name is required!'),
    }),
    card_number: Yup.string().when('billing_card', {
        is: 'credit_card',
        then: Yup.string()
            .required('Card number is required!')
            .min(15, 'Must be minimum of 15 digits'),
    }),
    exp_date: Yup.string().when('billing_card', {
        is: 'credit_card',
        then: Yup.string().required('Expiration Date is required!'),
    }),
    cvv: Yup.string().when('billing_card', {
        is: 'credit_card',
        then: Yup.string()
            .required('CVV Code is required!')
            .min(3, 'Must be minimum of 3 digits')
            .max(4, 'Must be maximum of 4 digits only'),
    }),
    account_number: Yup.string().when('billing_card', {
        is: 'ach',
        then: Yup.string().required('Account number is required!'),
    }),
    routing_number: Yup.string().when('billing_card', {
        is: 'ach',
        then: Yup.string().required('Routing number is required!'),
    }),
    dealer: Yup.string(),
    img_upload: Yup.mixed().when('dealer', {
        is: (dealer) => dealer === '2',
        then: Yup.mixed().required("Driver's Licence is required!"),
        otherwise: '',
    }),
    // .test(
    //     'fileFormat',
    //     'File Format should be jpg / jpeg / png',
    //     (value) => value && FILE_TYPES.includes(value[0].type),
    // ),
    // .test(
    //     'fileSize',
    //     'File size should be lesser than 1 MB!',
    //     (value) => value && value.size <= FILE_SIZE,
    // )
})

// Change Password validation Schema
export const changePasswordSchema = Yup.object().shape({
    current_password: Yup.string().required('Current password is required!'),
    new_password: Yup.string()
        .min(8, 'New password contain alteast 8 characters!')
        .required('New password is required!'),
    confirm_new_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'New password and confirm password should match!')
        .required('Confirm password is required!'),
})

// Profile validation schema
export const profileSchema = Yup.object().shape({
    first_name: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Full Name contains only alphabets!')
        .required('Full Name is required!'),
    phone: Yup.number()
        .typeError('Invalid mobile number!')
        .test('len', 'Invalid mobile number!', (val) => val.toString().length === 10)
        .required('Mobile number is required!'),
    username: Yup.string()
        .matches(/^[aA-zZ0-9\s]+$/, 'Username contains only alphabets and numbers!')
        .required('Username is required!'),
    address1: Yup.string().required('Street address is required!'),
    country: Yup.string().required('Country is required!'),
    state: Yup.string().required('State is required!'),
    city: Yup.string().required('City is required!'),
    zip: Yup.number().typeError('Invalid postal code!').required('Postal code is required!'),
})

// Post a product step 1 validation schema
export const product1Schema = Yup.object().shape({
    year: Yup.string().when('make', {
        is: 'Other',
        then: Yup.string(),
        otherwise: Yup.string().required('Vehicle year is required!'),
    }),
    other: Yup.string().when('make', {
        is: 'Other',
        then: Yup.string().required('This field is required!'),
    }),
    make: Yup.string().required('Vehicle make is required!'),
    model: Yup.string().required('Vehicle model is required!'),
    sold_by: Yup.string().required('Vehicle sold by is required!'),
    country: Yup.string().required('Vehicle country is required!'),
    state: Yup.string().required('State is required!'),
    city: Yup.string().required('Vehicle city is required!'),
    reconsidering: Yup.string().when('denied', {
        is: 1,
        then: Yup.string().required('This field is required!'),
    }),
    dealership: Yup.string().when('dealer', {
        is: 1,
        then: Yup.string().required('Name of your dealership is required!'),
    }),
    url_1: Yup.string()
        .required('Video link is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
    url_2: Yup.string()
        .required('Video link is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
    url_3: Yup.string()
        .required('Video link is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
    url_4: Yup.string()
        .required('Video link is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
    url_5: Yup.string()
        .required('Video link is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
})

// Post a product step 2 validation schema
export const product2Schema = Yup.object().shape({
    vin: Yup.string().required('VIN is required!'),
    ownership: Yup.string().required('Length of ownership is required!'),
    current_miles: Yup.string()
        .matches(/^[0-9,]+$/, 'Miles must be a number!')
        .required('Current miles/hour is required!'),
    yourself_miles: Yup.string()
        .matches(/^[0-9,]+$/, 'Miles must be a number!')
        .required('Yourself miles/hour is required!'),
    engine_size: Yup.string().required('Engine Size is required!'),
    titled_name: Yup.string().when('titled', {
        is: 2,
        then: Yup.string()
            .matches(/^[aA-zZ\s]+$/, 'Name contains only alphabets!')
            .required('Name is required!'),
    }),
    transmission: Yup.string().required('Transmission Type is required!'),
    // issues: Yup.string().required('Issues is required!'),
    // records: Yup.string().required('Service Record History is required!'),
    repaint: Yup.string().required('This field is required!'),
    rust: Yup.string().required('This field is required!'),
    rprice: Yup.number().when('reserve', {
        is: 1,
        then: Yup.number()
            .typeError('Reserve price must be a number!')
            .required('Reserve price is required!')
            .moreThan(Yup.ref('sprice'), 'Reserve price must be greater than reserve price!'),
    }),
    sprice: Yup.number()
        .required('Start price is required!')
        .typeError('Start price must be a number!')
        .required('Start price is required!')
        .when('reserve', {
            is: 1,
            then: Yup.number()
                .typeError('Start price must be a number!')
                .required('Start price is required!')
                .lessThan(Yup.ref('rprice'), 'Start price must be lesser than reserve price!'),
        }),
    start_date: Yup.date().required('Start date is required!'),
    // end_date: Yup.date()
    //     .min(Yup.ref('start_date'), 'End date must be greater than start date!')
    //     .required('End date is required!'),
    service_done_by: Yup.string().required('This field is required!'),
    hear_about_us: Yup.string().required('This field is required!'),
    description: Yup.string().required('Description is required!'),
    exterior: Yup.string().required('Exterior color is required!'),
    interior: Yup.string().required('Interior color is required!'),
    body_style: Yup.string().required('Body Style is required!'),
    drive_train: Yup.string().required('Drivetrain is required!'),
    title_status: Yup.string().required('Title Status is required!'),
})

export const cardSchema = Yup.object().shape({
    card_name: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Card name contains only alphabets!')
        .required('Card name is required!'),
    card_number: Yup.string()
        .length(19, 'Invalid card number!')
        .required('Card number is required!'),
    cvv: Yup.string().length(3, 'Invalid cvv!').required('CVV is required!'),
    exp_date: Yup.string().required('Expiry date is required!'),
})

// Change Password validation Schema
export const ForgotchangePasswordSchema = Yup.object().shape({
    new_password: Yup.string()
        .min(8, 'New password contain alteast 8 characters!')
        .required('New password is required!'),
    confirm_new_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'New password and confirm password should match!')
        .required('Confirm password is required!'),
})

export function validateCard(values) {
    let errors = {}

    if (!values.card_name) {
        errors.card_name = 'Card Name is required!'
    }
    if (!values.card_number) {
        errors.card_number = 'Card Number is required!'
    } else if (values.card_number.split(' ').join('').length < 16) {
        errors.card_number = 'Card Number is invalid!'
    }
    if (!values.date) {
        errors.date = 'Date is required!'
    } else {
        let date = new Date()
        let year = date.getFullYear().toString().substr(-2)
        let month = date.getMonth() + 1
        const [valueMonth, valueYear] = values.date.split('/')
        if (!values.date.includes('/')) {
            errors.date = 'Date is invalid!'
        }
        if (
            Number(valueMonth) === 0 ||
            Number(valueMonth) > 12 ||
            Number(valueYear) < Number(year)
        ) {
            errors.date = 'Date is invalid!'
        }
        if (Number(month) > Number(valueMonth) && Number(valueYear) === Number(year)) {
            errors.date = 'Date is invalid!'
        }
    }
    if (!values.cvv) {
        errors.cvv = 'CVV is required!'
    } else if (values.cvv.length < 3) {
        errors.cvv = 'CVV is invalid!'
    }
    return errors
}

//Seller Payment Card Validator
export const sellerPaymentCardValidation = Yup.object().shape({
    card_name: Yup.string().required('Full name is required!'),
    card_number: Yup.string().required('Card Number is required!'),
    expiry_date: Yup.string().required('Expiry is required!'),
    cvv: Yup.string().required('Expiry is required!'),
})

// Post a product step 1 validation schema
export const step1Schema = Yup.object().shape({
    year: Yup.string().required('Vehicle year is required!'),
    make: Yup.string().required('Vehicle make is required!'),
    model: Yup.string().required('Vehicle model is required!'),
    sold_by: Yup.string().required('Vehicle sold by is required!'),
    country: Yup.string().required('Vehicle country is required!'),
    city: Yup.string().required('Vehicle city is required!'),
    state: Yup.string().required('Vehicle state is required!'),
    vin: Yup.string().required('VIN number is required!'),
    current_miles: Yup.string().required('Current miles is required!'),
    body_style: Yup.string().required('Body style is required!'),
    exterior: Yup.string().required('Exterior color is required!'),
    interior: Yup.string().required('Interior color is required!'),
    engine_size: Yup.string().required('Engine size is required!'),
    drive_train: Yup.string().required('Drivetrain is required!'),
    transmission: Yup.string().required('Transmission type is required!'),
    repaint: Yup.string().required('This field is required!'),
    title_status: Yup.string().required('Title status is required!'),
})

// Post a product step 7 validation schema
export const step7Schema = Yup.object().shape({
    ownership: Yup.string().required('Length of ownership is required!'),
    yourself_miles: Yup.string()
        .matches(/^[0-9,]+$/, 'Miles must be a number!')
        .required('Yourself miles/hour is required!'),
})

// Post a product step 8 validation schema
export const step8Schema = Yup.object().shape({
    url_1: Yup.string()
        .required('This field is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
    url_2: Yup.string()
        .required('This field is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
    url_3: Yup.string()
        .required('This field is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
    url_4: Yup.string()
        .required('This field is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
    url_5: Yup.string()
        .required('This field is required!')
        .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
})

export const bankSchema = Yup.object().shape({
    email: Yup.string().required('Email is required!'),
    country: Yup.string().required('Country is required!'),
    business_type: Yup.string().required('Business type is required!'),
    first_name: Yup.string().required('First name is required!'),
    last_name: Yup.string().required('Last name is required!'),
    dob: Yup.string().required('DOB is required!'),
    line1: Yup.string().required('Address line 1 is required!'),
    city: Yup.string().required('City is required!'),
    state: Yup.string().required('State is required!'),
    postal_code: Yup.string().required('Postal code is required!'),
    phone: Yup.string().required('Phone number is required!'),
    id_number: Yup.string()
        // .typeError('Invalid social security number!')
        // .test('len', 'Invalid social security number!', (val) => val && val.toString().length === 9)
        .length(9, 'Invalid social security number!')
        .required('Social security number is required!'),
    // account_holder_name: Yup.string().required('Account holder name is required!'),
    // account_holder_type: Yup.string().required('Account holder type is required!'),
    // routing_number: Yup.string().required('Routing number is required!'),
    // account_number: Yup.string().required('Account number is required!'),
    card_address_line1: Yup.string().required('Card address line 1 is required!'),
    card_address_city: Yup.string().required('Card city is required!'),
    card_address_country: Yup.string().required('Card country is required!'),
    card_address_state: Yup.string().required('Card state is required!'),
    card_address_zip: Yup.string().required('Card postal code is required!'),
    card_name: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Card name contains only alphabets!')
        .required('Card name is required!'),
    card_number: Yup.string()
        .length(19, 'Invalid card number!')
        .required('Card number is required!'),
    card_cvc: Yup.string().length(3, 'Invalid CVC!').required('CVC is required!'),
    card_exp_date: Yup.string().required('Expiry date is required!'),
})
