import React, { useEffect, useState } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { request } from '../../Utils/Request'
import { useFormik } from 'formik'
import { changePasswordSchema } from '../../Utils/Validatior'
import { useSnackbar } from 'notistack'

const ChangePassword = () => {
    const { enqueueSnackbar } = useSnackbar()
    const formik = useFormik({
        initialValues: {
            current_password: '',
            new_password: '',
            confirm_new_password: '',
        },
        validationSchema: changePasswordSchema,
        onSubmit: (values) => {
            updatePassword(values)
        },
    })

    const updatePassword = async (val) => {
        try {
            const res = await request('post', '/updatepassword_in_profile', val, true)
            if (res.data.status === 'no') {
                return enqueueSnackbar("Current password doesn't match!", {
                    variant: 'error',
                })
            }
            enqueueSnackbar('Password changed successfully!', {
                variant: 'success',
            })
            formik.resetForm()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <form className="row paswrd-row" onSubmit={formik.handleSubmit}>
            <div className="col-6 input-left">
                {' '}
                <CustomInput
                    label="Current Password"
                    placeholder="Current Password"
                    type="password"
                    id="current_password"
                    name="current_password"
                    value={formik.values.current_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.current_password && formik.errors.current_password
                            ? formik.errors.current_password
                            : null
                    }
                    error={formik.touched.current_password && formik.errors.current_password}
                />
            </div>
            <div className="col-6 input-left">
                {' '}
                <CustomInput
                    label="New Password"
                    placeholder="New Password"
                    type="password"
                    id="new_password"
                    name="new_password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.new_password && formik.errors.new_password
                            ? formik.errors.new_password
                            : null
                    }
                    error={formik.touched.new_password && formik.errors.new_password}
                />
            </div>
            <div className="col-6 input-left">
                {' '}
                <CustomInput
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    type="password"
                    id="confirm_new_password"
                    name="confirm_new_password"
                    value={formik.values.confirm_new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.confirm_new_password && formik.errors.confirm_new_password
                            ? formik.errors.confirm_new_password
                            : null
                    }
                    error={
                        formik.touched.confirm_new_password && formik.errors.confirm_new_password
                    }
                />
            </div>
            <div className="col-12 save-button-buyer-profile ">
                <PrimaryButton label="CHANGE" className="save-basic-info" type="submit" />
                {/* <PrimaryButton label="Cancel" className="cancel-basic-info" /> */}
            </div>
        </form>
    )
}

export default ChangePassword
