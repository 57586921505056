import React, { Fragment } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const LazyImage = ({ alt, height, src, width, className, caption, onError }) => {
    return (
        <Fragment>
            <LazyLoadImage
                alt={alt}
                height={height}
                src={src}
                onError={onError}
                width={width}
                effect="blur"
                className={className}
                placeholder={<div className="compression-box">loading...</div>}
            />
            {caption ? <span>{caption}</span> : null}
        </Fragment>
    )
}

export default LazyImage
