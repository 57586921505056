import React, { Fragment, useEffect, useState } from 'react'
import ProductCard from '../../components/molecules/ProductCard'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { useHistory } from 'react-router-dom'
import { request } from '../../Utils/Request'
import { useSnackbar } from 'notistack'
import Pagination from '../../components/atoms/Pagination/Pagination'
import { getModelById, getMakeById } from '../../Utils/makemodel'
const Past = ({
    page,
    className,
    filterData,
    searchTrigger,
    handleFilterChange,
    setSearchTrigger,
}) => {
    const [productDetail, setProductDetail] = useState([])
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({
        sh_limit: 20,
        page: 1,
        orderby: 1,
        soldlisting: 1,
        from: 'soldlisting',
        length: 0,
    })
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const getProducts = async () => {
        try {
            setLoader(true)
            const res = await request(
                'post',
                '/mobilesearch',
                {
                    ...data,
                    category:
                        filterData && filterData.model
                            ? getModelById(filterData.model).value
                            : undefined,
                    searchbar:
                        filterData && filterData.make
                            ? getMakeById(filterData.make).value
                            : undefined,
                    contenthead2: filterData ? filterData.transmission : '',
                    orderby: filterData ? filterData.orderby : '',
                },
                true,
            )
            if (res.data.success === 'yes') {
                if (res.data.results instanceof Array) {
                    setProductDetail(res.data.results)
                    setData({ ...data, length: res.data.total_pagecnt })
                }
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    const onHandleChecked = async (checked, propsData) => {
        try {
            const data = {
                product_id: propsData,
                watchlistemail: 1,
            }
            const res = await request(
                'post',
                !checked ? '/add_watchlist' : '/remove_watchlist',
                data,
                true,
            )
            if (res.data.status === 'yes') {
                enqueueSnackbar(res.data.message, {
                    variant: 'success',
                })
            }
        } catch (error) {
            console.log(error)
        }
        setProductDetail(
            productDetail.map((ele) => (ele.id === propsData ? { ...ele, checked } : ele)),
        )
    }

    const handleSortOrderChange = (e) => {
        handleFilterChange(e)
        setSearchTrigger(!searchTrigger)
    }
    useEffect(() => {
        console.log('+++++++++++++done1')
        getProducts()
    }, [searchTrigger, data.page])
    return (
        <Fragment>
            <div className="heading-area">
                <h4 className="product-list-title">Past Auctions</h4>
                {/* <Link className="view-all">View All</Link> */}
            </div>
            {loader ? (
                <div>
                    <img src="./assets/svg/search.svg" className="img-fluid mx-auto-d-block" />
                </div>
            ) : productDetail.length > 0 ? (
                <Fragment>
                    <div className={className ? className : 'productGrid'}>
                        {productDetail.map((el) => (
                            <ProductCard
                                data={el}
                                onClick={() => history.push(`/product/${el.id}/${el.title}`)}
                                key={el.id}
                                handleChecked={onHandleChecked}
                            />
                        ))}
                    </div>
                    {page ? (
                        <div className="clearfix mb-4">
                            <div className="float-right">
                                <Pagination
                                    length={data.length}
                                    limit={data.sh_limit}
                                    page={data.page}
                                    onChange={(e, v) => setData({ ...data, page: v })}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="view-all-btn">
                            <PrimaryButton
                                label="View All"
                                onClick={() => history.push('/pastauction')}
                            />
                        </div>
                    )}
                </Fragment>
            ) : (
                <div>
                    <img
                        src="./assets/images/no_result_found.png"
                        className="img-fluid mx-auto-d-block noResult"
                    />
                </div>
            )}
        </Fragment>
    )
}

export default Past
