import React from 'react'
import PropTypes from 'prop-types'
import './staticpages.css'

function JoinEmailList(props) {
    const {} = props

    return <>JoinEmailList</>
}

JoinEmailList.propTypes = {}

export default JoinEmailList
