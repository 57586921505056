import React, { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { request } from '../../Utils/Request'
import moment from 'moment'
import { dateTimeFormat } from '../../Utils/Constants'
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
const Invoice = () => {
    const [buyer, setBuyer] = useState({})
    const [seller, setSeller] = useState({})
    const [product, setProduct] = useState({})
    const [loader, setLoader] = useState(false)
    const { id, title } = useParams()
    const getProduct = async () => {
        try {
            setLoader(true)
            const res = await request('post', '/view-invoice', { id }, true)
            if (res.data.success === 'yes') {
                res.data.results.arr_temp.SlibuyPayments[0].amount = parseFloat(
                    res.data.results.arr_temp.SlibuyPayments[0].amount,
                )
                var fee = parseFloat(
                    parseFloat(res.data.results.arr_temp.SlibuyPayments[0].amount) *
                        (parseFloat(res.data.results.arr_temp.configValues[0].value) / 100),
                )
                if (fee > 2500) {
                    fee = 2500
                } else if (fee < 125) {
                    fee = 125
                }
                res.data.results.arr_temp.SlibuyPayments[0].buyers_fee = fee
                res.data.results.arr_temp.SlibuyPayments[0].total =
                    res.data.results.arr_temp.SlibuyPayments[0].amount +
                    res.data.results.arr_temp.SlibuyPayments[0].buyers_fee
                setProduct(res.data.results.arr_temp.SlibuyPayments[0])
                setBuyer(res.data.results.arr_temp.userShippdetail)
                setSeller(res.data.results.arr_temp.selleraddress)
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    const InvoiceData = (
        <div className="container mt-3 mb-3 pt-3 pb-3  shadow rounded">
            <table
                style={{
                    maxWidth: '800px',
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingTop: '25px',
                    color: 'black',
                }}
            >
                <thead style={{ height: '80px', background: '#173e80' }}>
                    <tr>
                        <th
                            colSpan="2"
                            style={{
                                textAlign: 'center',
                                height: 'auto',
                                padding: '15px',
                                verticalAlign: 'top',
                            }}
                        >
                            <img
                                src="http://coachgavel.moblearn.net/assets/svg/gavel-logo.svg"
                                style={{ height: '55px' }}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ verticalAlign: 'top' }} className="invoice-top">
                        <td style={{ textAlign: 'left' }}>
                            <h1
                                style={{
                                    fontSize: '20px',
                                    marginBottom: '0',
                                    paddingTop: '15px',
                                }}
                            >
                                Coach & Gavel
                            </h1>
                            <p
                                style={{
                                    maxWidth: '150px',
                                    fontSize: '16px',
                                    lineHeight: '25px',
                                    marginTop: '5px',
                                }}
                            >
                                Bahnhofstrasse 100 8001 Zurich Switzerland
                            </p>
                            {/* <p style={{ fontSize: '16px', marginTop: '20px' }}>
                                VAT Nr.: CHE-444.479.443
                            </p> */}
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            <h1
                                style={{
                                    fontSize: '40px',
                                    marginBottom: '0',
                                    marginTop: '0',
                                    paddingTop: '15px',
                                }}
                            >
                                INVOICE
                            </h1>
                            {/* <p
                                style={{
                                    fontSize: '16px',
                                    marginTop: '28px',
                                    marginBottom: '0',
                                }}
                            >
                                Nr.: YY-MM-10001
                            </p> */}
                            <p
                                style={{
                                    fontSize: '16px',
                                    marginTop: '4px',
                                    marginBottom: '0',
                                }}
                            >
                                Date: {moment(product.date_added).format('MMMM Do YYYY')}
                            </p>
                            <p
                                style={{
                                    fontSize: '16px',
                                    marginTop: '4px',
                                    marginBottom: '0',
                                }}
                            >
                                Invoice ID: {id}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ verticalAlign: 'top' }}>
                        <td style={{ textAlign: 'left' }}>
                            <h1
                                style={{
                                    fontSize: '18px',
                                    marginBottom: '0',
                                    marginTop: '45px',
                                }}
                            >
                                BILLED TO:
                            </h1>
                            <p
                                style={{
                                    fontSize: '16px',
                                    marginTop: '28px',
                                    marginBottom: '0',
                                }}
                            >
                                {buyer.first_name}
                            </p>
                            <p
                                style={{
                                    fontSize: '16px',
                                    marginTop: '8px',
                                    marginBottom: '0',
                                }}
                            >
                                {buyer.email}
                            </p>
                            <p
                                style={{
                                    fontSize: '16px',
                                    marginTop: '8px',
                                    marginBottom: '0',
                                }}
                            >
                                {buyer.address}
                            </p>
                            {buyer.address1 && (
                                <p
                                    style={{
                                        fontSize: '16px',
                                        marginTop: '8px',
                                        marginBottom: '0',
                                    }}
                                >
                                    {buyer.address1}
                                </p>
                            )}
                            <p
                                style={{
                                    fontSize: '16px',
                                    marginTop: '8px',
                                    marginBottom: '0',
                                }}
                            >
                                {buyer.state} &amp; {buyer.city}
                            </p>
                            <p
                                style={{
                                    fontSize: '16px',
                                    marginTop: '8px',
                                    marginBottom: '0',
                                }}
                            >
                                {buyer.country}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" className="invoice-inner-table">
                            <table
                                style={{
                                    width: '100%',
                                    marginTop: '35px',
                                    color: '#000',
                                    borderCollapse: 'collapse',
                                }}
                            >
                                <thead
                                    style={{
                                        fontSize: '18px',
                                        background: '#a48843',
                                        color: 'white',
                                    }}
                                >
                                    <tr>
                                        <th
                                            style={{
                                                textAlign: 'left',
                                                padding: '10px 6px',
                                            }}
                                        >
                                            TITLE
                                        </th>
                                        <th style={{ textAlign: 'left' }}>AMOUNT DUE</th>
                                        <th style={{ textAlign: 'left' }}>
                                            BUYER &apos; S FEE (PAID)
                                        </th>
                                        <th
                                            style={{
                                                textAlign: 'right',
                                                paddingRight: '10px',
                                            }}
                                        >
                                            TOTAL
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        style={{
                                            verticalAlign: 'top',
                                            borderBottom: '1px solid #000',
                                        }}
                                    >
                                        <td
                                            style={{
                                                textAlign: 'left',
                                                padding: '15px 6px',
                                            }}
                                        >
                                            {product.title},{title}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'left',
                                                paddingTop: '15px',
                                            }}
                                        >
                                            ${product.amount && product.amount.toLocaleString()}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'left',
                                                paddingTop: '15px',
                                            }}
                                        >
                                            $
                                            {product.buyers_fee &&
                                                product.buyers_fee.toLocaleString()}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                                paddingTop: '15px',
                                                paddingRight: '10px',
                                            }}
                                        >
                                            ${product.total && product.total.toLocaleString()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" style={{ textAlign: 'center' }}>
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    lineHeight: '30px',
                                    fontSize: '17px',
                                }}
                            >
                                PAYMENT RECEIVED ON{' '}
                                {moment(product.date_added).format('MMMM Do YYYY')}
                                <br />
                                THANK YOU FOR CHOOSING C&G.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" style={{ textAlign: 'center' }}>
                            <p
                                style={{
                                    margin: 'auto',
                                    background: '#173e80',
                                    padding: '15px',
                                    color: 'white',
                                }}
                            >
                                If you have any questions concerning this invoice please contact:
                                support@C&G.com
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
    const DownloadableInvoice = () => (
        <Document>
            <Page>{InvoiceData}</Page>
        </Document>
    )
    useEffect(() => {
        getProduct()
    }, [])
    return (
        <Fragment>
            {loader ? (
                <div className="invoice-wrapper">
                    <img src="/assets/svg/loader.svg" className="img-fluid mx-auto d-block" />
                </div>
            ) : (
                <div className="invoice-wrapper">
                    {InvoiceData}
                    {/* <PDFDownloadLink
                        document={<DownloadableInvoice />}
                        fileName={`${product.title}_${title}_${moment().format('LL')}.pdf`}
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading Document...' : 'Download As PDF'
                        }
                    </PDFDownloadLink> */}
                </div>
            )}
        </Fragment>
    )
}
export default Invoice
