import React, { Fragment, useEffect, useState } from 'react'
import ProductCard from '../../components/molecules/ProductCard'
import ProductList from '../../components/molecules/ProductList'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { useHistory, useLocation } from 'react-router-dom'
import { request } from '../../Utils/Request'
import { isAuthenticated } from '../../Utils/Auth'
import { useSnackbar } from 'notistack'
import Pagination from '../../components/atoms/Pagination/Pagination'
import { getModelById, getMakeById } from '../../Utils/makemodel'
import ListGrid from '../../components/atoms/ListGrid'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import Select from '@material-ui/core/Select'
import { bidAddtime } from '../../Utils/Socket'
const EndingSoon = ({
    filterData,
    searchTrigger,
    heading,
    page,
    className,
    handleFilterChange,
    setSearchTrigger,
}) => {
    let [productDetail, setProductDetail] = useState([])
    const [loader, setLoader] = useState(false)
    const [viewListType, setViewListType] = useState(0)
    const [user, setUser] = React.useState(
        localStorage.getItem('coachUser') ? JSON.parse(localStorage.getItem('coachUser')) : {},
    )
    const [data, setData] = useState({
        sh_limit: 20,
        page: 1,
        length: 0,
    })
    var product_arr = []
    const sortValueEnding = [
        { id: 6, value: 'Ending Soon' },
        { id: 3, value: 'Bid Low to High' },
        { id: 4, value: 'Bid High to Low' },
        { id: 1, value: 'Ending Last' },
        // { id: 2, value: 'Closest to Zip Code' },
    ]

    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const getProducts = async () => {
        try {
            setLoader(true)
            const res = await request(
                'post',
                '/mobilesearch',
                {
                    ...data,
                    category: filterData.model ? getModelById(filterData.model).value : undefined,
                    searchbar: filterData.make ? getMakeById(filterData.make).value : undefined,
                    contenthead2: filterData.transmission,
                    orderby: filterData.orderby,
                },
                true,
            )
            if (res.data.success === 'yes') {
                if (res.data.results instanceof Array) {
                    setProductDetail(res.data.results)
                    setData({ ...data, length: res.data.total_pagecnt })
                }
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const onHandleChecked = async (checked, propsData) => {
        try {
            if (!isAuthenticated()) {
                enqueueSnackbar('Kindly login to add watchlist!', {
                    variant: 'info',
                })
                return history.push(`/login?return=${window.location.pathname}`)
            }
            const data = {
                product_id: propsData,
                watchlistemail: user.role == 'seller' ? 0 : 1,
            }
            const res = await request(
                'post',
                !checked ? '/add_watchlist' : '/remove_watchlist',
                data,
                true,
            )
            if (res.data.status === 'yes') {
                enqueueSnackbar(
                    !checked
                        ? 'Product added to watchlist successfully!'
                        : 'Product removed from watchlist successfully!',
                    {
                        variant: 'success',
                    },
                )
            }
        } catch (error) {
            console.log(error)
        }
        setProductDetail(
            productDetail.map((ele) => (ele.id === propsData ? { ...ele, checked } : ele)),
        )
    }
    const handleSortOrderChange = (e) => {
        handleFilterChange(e)
        setSearchTrigger(!searchTrigger)
    }
    useEffect(() => {
        console.log('+++++++++++++done')
        getProducts()
    }, [searchTrigger, data.page])
    const endlisting = (defaultval) => {
        setViewListType(defaultval)
    }

    useEffect(() => {
        bidAddtime((data) => {
            console.log('product details', product_arr, productDetail)
            var inside = false
            var product_arr = productDetail.map((ele, key) => {
                if (parseInt(ele.id) === parseInt(data.id)) {
                    ele.wprice = data.bpop_wprice
                    inside = true
                }
                return ele
            })

            console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnn++++', product_arr)
            if (inside) {
                setProductDetail(product_arr)
            }
        })
    }, [productDetail])

    // useEffect(() => {
    //     if (productDetail.length > 0) {
    //console.log('iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii', productDetail)
    //         product_arr = productDetail
    //     }
    // }, [productDetail])

    return (
        <Fragment>
            <div className="heading-area">
                <div>
                    <h4 className="product-list-title">{heading}</h4>
                </div>
                {/* <Link className="view-all">View All</Link> */}
                <div className="list-sort">
                    <div>
                        <ListGrid
                            viewValue={endlisting}
                            defaultView={0}
                            activeIconColor="acive-icons"
                        />
                    </div>
                    <div>
                        <CustomSelect
                            // label={'Make'}
                            // labelClass={'text-left'}
                            // selectClassName={'search-filter-make'}
                            name="orderby"
                            value={filterData.orderby}
                            onChange={(e) => handleSortOrderChange(e)}
                        >
                            {sortValueEnding.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.value}
                                </option>
                            ))}
                        </CustomSelect>
                    </div>
                </div>
            </div>
            {loader ? (
                <div>
                    <img src="./assets/svg/search.svg" className="img-fluid mx-auto-d-block" />
                </div>
            ) : productDetail.length > 0 ? (
                <Fragment>
                    <div
                        className={
                            className && viewListType !== 1
                                ? className
                                : viewListType === 0
                                ? 'productGrid'
                                : ''
                        }
                    >
                        {productDetail.map((el) =>
                            viewListType === 0 ? (
                                <ProductCard
                                    data={el}
                                    onClick={() => history.push(`/product/${el.id}/${el.title}`)}
                                    key={el.id}
                                    handleChecked={onHandleChecked}
                                />
                            ) : (
                                <div className="ending-list-view">
                                    <ProductList
                                        data={el}
                                        onClick={() =>
                                            history.push(`/product/${el.id}/${el.title}`)
                                        }
                                        key={el.id}
                                        handleChecked={onHandleChecked}
                                    />
                                </div>
                            ),
                        )}
                        {console.log('nnnnnnnnnnnnnnnnnnnnnnnnnnn', productDetail)}
                    </div>
                    {page ? (
                        <div className="clearfix mb-4">
                            <div className="float-right">
                                <Pagination
                                    length={data.length}
                                    limit={data.sh_limit}
                                    page={data.page}
                                    onChange={(e, v) => setData({ ...data, page: v })}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="view-all-btn">
                            <PrimaryButton
                                label="View All"
                                onClick={() => history.push('/liveauction')}
                            />
                        </div>
                    )}
                </Fragment>
            ) : (
                <div>
                    <img
                        src="./assets/images/no_result_found.png"
                        className="img-fluid mx-auto-d-block noResult"
                    />
                </div>
            )}
        </Fragment>
    )
}

export default EndingSoon
