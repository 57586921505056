import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import { mapData } from '../../../common/components'
import './postListingPageOne.css'
import PrimaryButton from '../../../components/atoms/PrimaryButton'
import RadioBox from '../../../components/atoms/RadioBox'
import CustomSelect from '../../../components/atoms/Inputs/CustomSelect'
import CustomInput from '../../../components/atoms/Inputs/CustomInput'
import CustomInputUpload from '../../../components/atoms/Inputs/FileUpload'
import ImagewithpdfUpload from '../../../components/atoms/Inputs/fileuploadpdfimg'
import csc from 'country-state-city'
import { getAllMake, getModelsOfMake } from '../../../Utils/makemodel'
import { useSnackbar } from 'notistack'
import { product1Schema } from '../../../Utils/Validatior'
import imageCompression from 'browser-image-compression'
import { useLocation, Prompt } from 'react-router-dom'
import { countryList, yearList } from '../../../Utils/Constants'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import Uploader from '../../../Utils/uploader'
const PostListingPageOne = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const [trigger, settrigger] = useState(true)
    const location = useLocation()
    const { setScreenChange } = props
    const [details, setDetails] = useState({
        make: '',
        model: '',
        year: '',
        country: '',
        city: '',
        state: '',
        listed: 2,
        denied: 2,
        dealer: 2,
        reconsidering: '',
        dealership: '',
        url_1: '',
        url_2: '',
        url_3: '',
        url_4: '',
        url_5: '',
        url_6: '',
        sold_by: '',
        product_image: [],
        document: [],

        //Step 2 data
        noteworthy_1: '',
        noteworthy_2: '',
        noteworthy_3: '',
        noteworthy_4: '',
        noteworthy_5: '',
        noteworthy_6: '',
        noteworthy_7: '',
        noteworthy_8: '',
        noteworthy_9: '',
        noteworthy_10: '',

        //step 3 data
        modification_1: '',
        modification_2: '',
        modification_3: '',
        modification_4: '',
        modification_5: '',
        modification_6: '',
        modification_7: '',
        modification_8: '',
        modification_9: '',
        modification_10: '',

        //step 4 data
        issue_1: '',
        issue_2: '',
        issue_3: '',
        issue_4: '',
        issue_5: '',
        issue_6: '',
        issue_7: '',
        issue_8: '',
        issue_9: '',
        issue_10: '',

        //step 5 data
        service_1: '',
        service_2: '',
        service_3: '',
        service_4: '',
        service_5: '',
        service_6: '',
        service_7: '',
        service_8: '',
        service_9: '',
        service_10: '',

        //step 6 data
        additional_1: '',
        additional_2: '',
        additional_3: '',
        additional_4: '',
        additional_5: '',
        additional_6: '',
        additional_7: '',
        additional_8: '',
        additional_9: '',
        additional_10: '',
    })
    const [isBlocking, setIsBlocking] = useState(false)
    const [images, setImages] = useState([])
    const [document, setDocument] = useState([])
    const formik = useFormik({
        initialValues: details,
        validationSchema: product1Schema,
        enableReinitialize: trigger,
        onSubmit: (values) => {
            if (values.product_image.length < 2) {
                return enqueueSnackbar('Please upload at least 25 images', {
                    variant: 'warning',
                })
            }
            if (document.length < 2) {
                return enqueueSnackbar(
                    'Please upload front and back of vehicless title or registration',
                    {
                        variant: 'warning',
                    },
                )
            }
            if (location.state) {
                if (location.state.step2Data) {
                    location.state = {
                        step1Data: { ...values, images, document },
                        step2Data: location.state.step2Data,
                    }
                } else {
                    location.state = {
                        step1Data: { ...values, images, document },
                    }
                }
            } else {
                location.state = {
                    step1Data: { ...values, images, document },
                }
            }

            setScreenChange(1)
        },
    })
    useEffect(() => {
        if (location.state) {
            if (location.state.step1Data) {
                setIsBlocking(true)
                setDetails(location.state.step1Data)
                setImages(location.state.step1Data.images)
                setDocument(location.state.step1Data.document)
            }
        }
        window.scrollTo(0, 0)
    }, [])
    const handleChange = (e) => {
        settrigger(false)
        setIsBlocking(true)
        formik.setFieldValue(e.target.name, e.target.value)
    }

    const firsthalf = [
        {
            label: 'What Country Is Vehicle Located In ?',
            type: 'select',
            placeholder: 'Select a country',
            class: 'col-6',
            options: countryList(),
            name: 'country',
            formik: formik,
            labelClass: 'text-left',
            onChange: handleChange,
        },
        {
            label: 'What City Is Vehicle Located In ?',
            type: 'text',
            placeholder: 'What City Is Vehicle Located In ?',
            class: 'col-6',
            name: 'city',
            formik: formik,
            labelClass: 'text-left',
            onChange: handleChange,
        },
        // {
        //     label:"What State, Provence, or Territory Is Vehicle Located In ?",
        //     type:"select",
        //     placeholder: 'Select a State',
        //     class: 'col-6',
        //     options: csc.getStatesOfCountry(step1Data.country),
        //     labelClass="mls-added-label",
        //     name="state",
        //     id="state",
        //     value={formik.values.state},
        //     onChange={handleChange},
        //     onBlur={formik.handleBlur},
        // },
    ]
    const step2Fields = Array.from(Array(10), (_, index) => ({
        label: `Noteworthy Equipment ${index + 1}`,
        type: 'text',
        placeholder: `Noteworthy Equipment ${index + 1}`,
        class: 'col-6',
        name: `noteworthy_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step3Fields = Array.from(Array(10), (_, index) => ({
        label: `Modification ${index + 1}`,
        type: 'text',
        placeholder: `Modification ${index + 1}`,
        class: 'col-6',
        name: `modification_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step4Fields = Array.from(Array(10), (_, index) => ({
        label: `Issue/Flaw ${index + 1}`,
        type: 'text',
        placeholder: `Issue/Flaw ${index + 1}`,
        class: 'col-6',
        name: `issue_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step5Fields = Array.from(Array(10), (_, index) => ({
        label: `Service History Note ${index + 1}`,
        type: 'text',
        placeholder: `Service History Note ${index + 1}`,
        class: 'col-6',
        name: `service_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step6Fields = Array.from(Array(10), (_, index) => ({
        label: `Additional Item Included ${index + 1}`,
        type: 'text',
        placeholder: `Additional Item Included ${index + 1}`,
        class: 'col-6',
        name: `additional_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const step8Names = ['Exterior', 'Interior', 'Driving', 'Engine', 'Cold Start', 'Additional']
    const step8Fields = Array.from(Array(6), (_, index) => ({
        label: `Provide link to ${step8Names[index]} video of vehicle (Youtube or Vimeo)`,
        type: 'text',
        placeholder: `Provide link to ${step8Names[index]} video of vehicle (Youtube or Vimeo)`,
        class: 'col-6',
        name: `url_${index + 1}`,
        formik: formik,
        labelClass: 'text-left',
        onChange: handleChange,
    }))
    const handleChangeRadio = (e) => {
        settrigger(false)
        setIsBlocking(true)
        formik.setFieldValue(e.target.name, parseInt(e.target.value))
        setDetails({
            ...details,
            [e.target.name]: parseInt(e.target.value),
        })
    }

    const removeImage = (index) => {
        images.splice(images.indexOf(index), 1)
        setImages([...images])
    }
    const removeDocument = (index) => {
        document.splice(document.indexOf(index), 1)
        setDocument([...document])
    }
    const onProgress = (value) => {
        // console.log(value)
    }
    const compressionOptions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: false,
        onProgress: onProgress,
    }
    const handleImageChange = (e) => {
        settrigger(false)
        setIsBlocking(true)
        try {
            // console.log(e.target.files.length)
            var all_files = { ...e.target.files }
            e.target.value = ''
            // var placeholder = new Array(Object.keys(all_files).length).fill(true)
            var arr = [...images]
            // setImages(arr)

            Object.keys(all_files).map(async (value, key) => {
                // var img = await imageCompression(all_files[key], compressionOptions)
                // var temp = arr
                // var index = temp.indexOf(true)
                // temp[index] = img
                arr.push(all_files[key])
                // console.log(temp)
                setImages((prevState) => [...arr])
                // setImages(temp)
                // var img = e.target.files[key]
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleDocumentChange = (e) => {
        settrigger(false)
        setIsBlocking(true)
        try {
            if (document.length >= 2 || e.target.files.length > 2) {
                return enqueueSnackbar('Unable to upload more than two images!', {
                    variant: 'warning',
                })
            }
            var placeholder = new Array(e.target.files.length).fill('/assets/svg/loader.svg')
            var arr = [...document, ...placeholder]
            setDocument(arr)
            Object.keys(e.target.files).map(async (value, key) => {
                if (key > 1) {
                    return false
                }
                if (
                    value.type === 'image/jpeg' ||
                    value.type === 'image/png' ||
                    value.type === 'image/jpeg'
                ) {
                    var img = await imageCompression(e.target.files[key], compressionOptions)
                } else {
                    var img = e.target.files[key]
                }
                // console.log('oooooooooooooooooooooooooo', e.target.files[key])
                var temp = arr
                var index = temp.indexOf('/assets/svg/loader.svg')
                temp[index] = img
                setDocument((prevState) => [...temp])
                // var img = e.target.files[key]
            })
            e.target.value = ''
        } catch (error) {
            console.log(error)
        }
    }
    const radioValue = [
        { id: 1, description: 'Yes' },
        { id: 2, description: 'No' },
    ]
    // console.log(images)
    const handleSelectChange = (e) => {
        setIsBlocking(true)
        settrigger(false)
        formik.setFieldValue(e.target.name, e.target.value)
        setDetails({
            ...details,
            [e.target.name]: e.target.value,
        })
    }
    useEffect(() => {
        if (isBlocking) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }, [isBlocking])
    // console.log(formik.values.make, details.make)
    return (
        <form className="product-list-page-one" onSubmit={formik.handleSubmit}>
            <Prompt
                when={isBlocking}
                message="Your work is not saved! Are you sure you want to leave ?"
            />
            <div className="row product-fist-half">
                <CustomSelect
                    label="Year of Vehicle"
                    id="year"
                    value={formik.values.year}
                    name="year"
                    onChange={handleSelectChange}
                    placeholder="Year of Vehicle"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.year && formik.errors.year}
                    helperText={formik.errors.year && formik.touched.year && formik.errors.year}
                    selectClassName="col-md-3 col-12"
                    labelClass="text-left"
                    disabled={formik.values.make === 'Other'}
                >
                    <option value="">Year of Vehicle</option>
                    {yearList().map((opt, optindex) => (
                        <option key={optindex} value={opt}>
                            {opt}
                        </option>
                    ))}
                    <option value="Other">Other</option>
                </CustomSelect>
                <CustomSelect
                    label="Vehicle Make"
                    id="make"
                    value={formik.values.make}
                    name="make"
                    onChange={handleSelectChange}
                    placeholder="Vehicle Make"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.make && formik.errors.make}
                    helperText={formik.errors.make && formik.touched.make && formik.errors.make}
                    selectClassName="col-md-3 col-12"
                    labelClass="text-left"
                >
                    <option value="">Vehicle Make</option>
                    {getAllMake().map((opt, optindex) => (
                        <option key={optindex} value={opt.id}>
                            {opt.value}
                        </option>
                    ))}
                    <option value="Other">Other</option>
                </CustomSelect>
                <CustomSelect
                    label="Vehicle model"
                    id="model"
                    value={formik.values.model}
                    name="model"
                    onChange={handleSelectChange}
                    placeholder="Vehicle model"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.model && formik.errors.model}
                    helperText={formik.errors.model && formik.touched.model && formik.errors.model}
                    selectClassName="col-6"
                    labelClass="text-left"
                >
                    <option value="">Vehicle model</option>
                    {details.make &&
                        getModelsOfMake(details.make).map((opt, optindex) => (
                            <option key={optindex} value={opt.id}>
                                {opt.value}
                            </option>
                        ))}
                    <option value="Other">Other</option>
                </CustomSelect>
                {formik.values.make === 'Other' && (
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 chng-Vehicle-area">
                                <CustomInput
                                    label="What is it ?"
                                    labelClass="chng-vehicle"
                                    placeholder="(i.e. 1974 Ducati 750 Super Sport)"
                                    name="other"
                                    id="other"
                                    value={formik.values.other}
                                    onChange={handleChange}
                                    onBlur={formik.handleBlur}
                                    helperText={
                                        formik.touched.other && formik.errors.other
                                            ? formik.errors.other
                                            : null
                                    }
                                    error={formik.touched.other && formik.errors.other}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {Object.values(mapData(firsthalf))}
                <div className="col-6 input-boxes">
                    <CustomSelect
                        label="What State, Provence, or Territory Is Vehicle Located In ?"
                        labelClass="mls-added-label"
                        name="state"
                        id="state"
                        value={formik.values.state}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.state && formik.errors.state ? formik.errors.state : null
                        }
                        error={formik.touched.state && formik.errors.state}
                    >
                        <option value="">Select a state</option>
                        {csc.getStatesOfCountry(formik.values.country).map((ele) => (
                            <option value={ele.id} key={ele.id}>
                                {ele.name}
                            </option>
                        ))}
                    </CustomSelect>
                </div>
                <div className="col-6 sale-owned">
                    <p>Is the vehicle currently listed for sale on any other website? </p>
                    <RadioBox
                        items={radioValue}
                        name="listed"
                        value={details.listed}
                        onChange={handleChangeRadio}
                    />
                </div>
                <div className="col-6 listed-site">
                    <p>Has this vehicle been denied for listing by us before?</p>
                    <RadioBox
                        items={radioValue}
                        name="denied"
                        value={details.denied}
                        onChange={handleChangeRadio}
                    />
                </div>
                <div className="col-6 chng-Vehicle-area">
                    <CustomInput
                        label="What changes have been made to the vehicle to justify us reconsidering?"
                        labelClass="chng-vehicle"
                        placeholder="What changes have been made to the vehicle to justify us reconsidering?"
                        name="reconsidering"
                        id="reconsidering"
                        value={formik.values.reconsidering}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.reconsidering && formik.errors.reconsidering
                                ? formik.errors.reconsidering
                                : null
                        }
                        error={formik.touched.reconsidering && formik.errors.reconsidering}
                        disabled={details.denied === 2}
                    />
                </div>
                <div className="col-6 is-dealer">
                    <p>
                        Are you a Dealer{' '}
                        <span>(cars, motorcycle, boats,airplanes, RVs, tailers)</span>
                    </p>
                    <RadioBox
                        items={radioValue}
                        name="dealer"
                        value={details.dealer}
                        onChange={handleChangeRadio}
                    />
                </div>
                <div className="col-6 dealership">
                    <CustomInput
                        label="What is the name of your dealership ?"
                        placeholder="What is the name of your dealership ?"
                        labelClass="dealership-label"
                        name="dealership"
                        id="dealership"
                        value={formik.values.dealership}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.dealership && formik.errors.dealership
                                ? formik.errors.dealership
                                : null
                        }
                        error={formik.touched.dealership && formik.errors.dealership}
                        disabled={details.dealer === 2}
                    />
                </div>
                <CustomSelect
                    label="Vehicle Sold By"
                    id="sold_by"
                    value={formik.values.sold_by}
                    name="sold_by"
                    onChange={handleSelectChange}
                    placeholder="Vehicle Sold By"
                    onBlur={formik.handleBlur}
                    type="select"
                    error={formik.touched.sold_by && formik.errors.sold_by}
                    helperText={
                        formik.errors.sold_by && formik.touched.sold_by && formik.errors.sold_by
                    }
                    selectClassName="col-6"
                    labelClass="text-left"
                >
                    <option value="">Vehicle Sold By</option>
                    <option value="Private Party">Private Party</option>
                    <option value="Dealer">Dealer</option>
                </CustomSelect>
                {Object.values(mapData(step8Fields))}
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="step2-content"
                        id="step2-header"
                    >
                        <p>Noteworthy Equipment</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">{Object.values(mapData(step2Fields))}</div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="step3-content"
                        id="step3-header"
                    >
                        <p>Modifications</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">{Object.values(mapData(step3Fields))}</div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="step4-content"
                        id="step4-header"
                    >
                        <p>Issues & Flaws</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">{Object.values(mapData(step4Fields))}</div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="step5-content"
                        id="step5-header"
                    >
                        <p>Service History</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">{Object.values(mapData(step5Fields))}</div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="step6-content"
                        id="step6-header"
                    >
                        <p>Additional Items Included</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">{Object.values(mapData(step6Fields))}</div>
                    </AccordionDetails>
                </Accordion>

                {/* <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="step8-content"
                        id="step8-header"
                    >
                        <p>Videos</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">{Object.values(mapData(step8Fields))}</div>
                    </AccordionDetails>
                </Accordion> */}

                <div className="col-12 upload-area mt-4">
                    <ImagewithpdfUpload
                        uploadClassName={'fileupload-signup'}
                        test={'Browse'}
                        label="Please upload clear, color images(PDF or Photo format) of the front and back of vehicles's title"
                        htmlFor="document"
                        id="document"
                        uploadClass="img-upload-wrapper"
                        buttonClass="browse-upload"
                        name="document"
                        onChange={handleDocumentChange}
                        multiple={true}
                        error={formik.errors.document ? formik.errors.document : null}
                        accept="application/pdf,image"
                        preview_image={document}
                        removeImage={removeDocument}
                    />
                    <br />
                    <small style={{ color: 'red' }}>
                        {formik.errors.document ? formik.errors.document : null}
                    </small>
                </div>
                <div className="col-12 upload-area">
                    {/* <CustomInputUpload
                        uploadClassName={'fileupload-signup'}
                        test={'Browse'}
                        label="Please upload at least 25 clear images of the interior, exterior, undercarriage, and engine compartment"
                        htmlFor="images"
                        id="images"
                        uploadClass="img-upload-wrapper"
                        buttonClass="browse-upload"
                        name="images"
                        onChange={handleImageChange}
                        multiple={true}
                        error={formik.errors.img_upload ? formik.errors.img_upload : null}
                        accept="image"
                        preview_image={images}
                        removeImage={removeImage}
                    /> */}
                    <Uploader
                        formik={formik}
                        name="product_image"
                        titleText="Upload product images"
                        innerText="Recommended dimensions: 500px * 500px"
                        folder="product"
                        multiple={true}
                        reorder={true}
                        accept="image/*"
                    />
                    <br />
                    <small style={{ color: 'red' }}>
                        {formik.errors.images ? formik.errors.images : null}
                    </small>
                </div>
            </div>
            <div className="nxt-page-btn">
                <PrimaryButton type="submit" label={'Next'} customClassName="product-post-next" />
            </div>
        </form>
    )
}

export default PostListingPageOne
