import React, { useState, useEffect } from 'react'
import LazyImage from '../../components/atoms/LazyImage'
import { dummyProductItems } from '../../components/molecules/ProductCard/dummyProductItems'

const UpcomingAuction = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="live-auction customContainer pt-5">
            <div className="heading-area mb-4">
                <div>
                    <h4 className="product-list-title">Example Listings Coming Soon</h4>
                </div>
            </div>
            <div className="productGrid upcomingcard mb-5">
                {dummyProductItems.map((data) => (
                    <div className="product-card">
                        <div className="pcImgWrapper" onClick={props.onClick}>
                            <LazyImage
                                src={data.src}
                                alt={data.protitle}
                                onError={(e) => (e.target.src = data.fallback)}
                                className="product-img"
                            />
                        </div>
                        <div className="product-description mt-2">
                            <div className="product-title">
                                <p>{data.title}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UpcomingAuction
