import React, { useEffect, useState, useRef } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import './dashboard.css'

import DashboardLayout from '../../components/templates/DashboardLayout/DashboardLayout'
import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import Timer from '../../Utils/Timer'
import Pagination from '../../components/atoms/Pagination/Pagination'
import { dateTimeFormat, dateTimeYearFormat } from '../../Utils/Constants'
import { Link } from 'react-router-dom'
import EditProduct from './EditProduct'
import Rating from '@material-ui/lab/Rating'
import FormDialog from '../../components/atoms/Modal'
import RatingComponent from './BuyerMyAuction/Rating'
import PublishIcon from '@material-ui/icons/Publish'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 500,
    },
    tabPanel: {
        border: '1px solid #233e7c',
        backgroundColor: '#FFFFFF',
        color: 'var(--progressLine)',
        textTransform: 'capitalize',
    },
})

const SellerMyAuction = () => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const [auctionSeller, setAuctionSeller] = useState({ length: 0, page: 1, limit: 10, data: [] })
    const [soldSeller, setSoldSeller] = useState({ length: 0, page: 1, data: [], limit: 10 })
    const [pastSeller, setPastSeller] = useState({ length: 0, page: 1, limit: 10, data: [] })
    const [reviews, setReviews] = useState({ length: 0, page: 1, limit: 10, data: [] })
    const [trigger, setTrigger] = useState(false)
    const [currentDate] = useState(new Date())
    const [sellerImg, setSellerImg] = useState({
        selectedFile: null,
    })
    const fileimageupload = useRef(null)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const sellerImageUpload = async () => {
        fileimageupload.current.click()
        let contactData
        try {
            const res = await request('post', '/request_to_upload_image', contactData)
        } catch (err) {
            console.log(err, '==> this is err')
        }
    }

    const handleImageUpload = () => {
        setSellerImg({
            selectedFile: [...sellerImg.selectedFile, ...event.target.files[0]],
        })
    }

    const auctionTabs = async () => {
        try {
            let data
            if (value === 0) {
                data = {
                    limit: auctionSeller.limit,
                    page: auctionSeller.page,
                    status: 'open',
                }
            } else if (value === 1) {
                data = {
                    limit: soldSeller.limit,
                    page: soldSeller.page,
                    status: 'sold',
                }
            } else if (value === 2) {
                data = {
                    limit: pastSeller.limit,
                    page: pastSeller.page,
                    status: 'closed',
                }
            } else if (value === 3) {
                data = {
                    seller_id: JSON.parse(localStorage.getItem('coachUser')).id,
                    role: 1,
                }
            }

            const res = await request(
                'post',
                value === 3 ? '/getSellerFeedbacks' : apiEndPoint.getSellerProducts,
                data,
                true,
            )

            if (res.data.status == 'yes') {
                if (value === 0) {
                    setAuctionSeller({
                        ...auctionSeller,
                        length: res.data.data.length,
                        data: res.data.data.result,
                    })
                } else if (value === 1) {
                    setSoldSeller({
                        ...soldSeller,
                        length: res.data.data.length,
                        data: res.data.data.result,
                    })
                } else if (value === 2) {
                    setPastSeller({
                        ...pastSeller,
                        length: res.data.data.length,
                        data: res.data.data.result,
                    })
                } else if (value === 3) {
                    setReviews({
                        ...reviews,
                        data: res.data.data,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        auctionTabs()
    }, [value, trigger])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        if (params.has('index')) {
            if (!isNaN(params.get('index'))) {
                setValue(parseInt(params.get('index')))
            }
        }
    }, [])
    return (
        <DashboardLayout userType="seller">
            <Tabs
                className={`${classes.root} buyer-my-auction seller-my-auct`}
                value={value}
                onChange={handleChange}
                centered
                aria-label="simple tabs example"
            >
                <Tab label="Active Auction" {...a11yProps(0)} className={classes.tabPanel} />
                <Tab label="Sold Auction" {...a11yProps(1)} className={classes.tabPanel} />
                <Tab label="Unsold Auction" {...a11yProps(2)} className={classes.tabPanel} />
                <Tab label="Reviews" {...a11yProps(3)} className={classes.tabPanel} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Auction id</th>
                                <th scope="col">Auction Name</th>
                                <th scope="col">Time Left</th>
                                <th scope="col">Posted On</th>
                                <th scope="col">No.of.Bids</th>
                                <th scope="col">Reserve Price</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auctionSeller &&
                            typeof auctionSeller.data !== 'undefined' &&
                            auctionSeller.data.length
                                ? auctionSeller.data.map((ele) => (
                                      <tr key={ele.id}>
                                          <td>{ele.id}</td>
                                          <td>{ele.title}</td>
                                          <td>
                                              <Timer
                                                  date_added={ele.date_added}
                                                  date_closed={ele.date_closed}
                                              />
                                          </td>
                                          <td>
                                              {moment(ele.date_added).format(dateTimeYearFormat)}
                                          </td>
                                          <td>{ele.bidcount}</td>
                                          <td>
                                              {ele.rprice === '0'
                                                  ? '-'
                                                  : '$ ' + parseInt(ele.rprice).toLocaleString()}
                                          </td>
                                          <td className="auction-view ">
                                              <div>
                                                  <Link to={`/product/${ele.id}/${ele.title}`}>
                                                      View
                                                  </Link>
                                                  <br />
                                                  <Link
                                                      to={`/request_images/${ele.id}/${ele.title}`}
                                                  >
                                                      Add Images
                                                  </Link>
                                                  {moment(ele.date_closed).diff(
                                                      moment(new Date()),
                                                      'minutes',
                                                  ) < 180 &&
                                                      parseInt(ele.rprice) >
                                                          parseInt(ele.wprice) && (
                                                          <EditProduct
                                                              product_id={ele.id}
                                                              trigger={trigger}
                                                              setTrigger={setTrigger}
                                                          />
                                                      )}
                                                  {/* <div className="seller-photos-upload">
                                                      <input
                                                          type="file"
                                                          multiple
                                                          onChange={handleImageUpload}
                                                          style={{ display: 'none' }}
                                                          ref={fileimageupload}
                                                      />
                                                      <PublishIcon onClick={sellerImageUpload} />
                                                  </div> */}
                                              </div>
                                              {/* <EditProduct
                                                  product_id={ele.id}
                                                  trigger={trigger}
                                                  setTrigger={setTrigger}
                                              /> */}
                                          </td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                    {auctionSeller &&
                    typeof auctionSeller.data !== 'undefined' &&
                    auctionSeller.data.length === 0 ? (
                        <p className="text-center">No data found</p>
                    ) : null}
                </div>
                {Math.ceil(auctionSeller.length / auctionSeller.limit) > 1 ? (
                    <div className="float-right pb-4">
                        <Pagination
                            length={auctionSeller.length}
                            limit={auctionSeller.limit}
                            page={auctionSeller.page}
                            onChange={(e, v) => setAuctionSeller({ ...auctionSeller, page: v })}
                        />
                    </div>
                ) : null}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Auction id</th>
                                <th scope="col">Auction Name</th>
                                <th scope="col">Sold On</th>
                                <th scope="col">Sold Price</th>
                                <th scope="col">No.of.Bids</th>
                                <th scope="col">Status</th>
                                <th scope="col">Review</th>
                            </tr>
                        </thead>
                        <tbody>
                            {soldSeller &&
                            typeof soldSeller.data !== 'undefined' &&
                            soldSeller.data.length
                                ? soldSeller.data.map((ele) => (
                                      <tr key={ele.id}>
                                          <td>{ele.id}</td>
                                          <td>{ele.title}</td>

                                          <td>
                                              {moment(ele.date_added).format(dateTimeYearFormat)}
                                          </td>
                                          <td>$ {ele.wprice}</td>
                                          <td>{ele.bidcount}</td>

                                          {ele.buynowpaid ? (
                                              <td className="paid">Paid</td>
                                          ) : (
                                              <td className="unpaid">Unpaid</td>
                                          )}
                                          <td>
                                              {!ele.reviewer_rating ? (
                                                  <RatingComponent
                                                      seller_id={ele.buynowuser_id}
                                                      product_id={ele.id}
                                                      trigger={trigger}
                                                      setTrigger={setTrigger}
                                                      role={0}
                                                  />
                                              ) : (
                                                  <Rating
                                                      value={ele.reviewer_rating}
                                                      name="your_rating"
                                                      readOnly
                                                  />
                                              )}
                                          </td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>

                    {soldSeller &&
                    typeof soldSeller.data !== 'undefined' &&
                    soldSeller.data.length === 0 ? (
                        <p className="text-center">No data found</p>
                    ) : null}
                </div>
                {Math.ceil(soldSeller.length / soldSeller.limit) > 1 ? (
                    <div className="float-right pb-4">
                        <Pagination
                            length={soldSeller.length}
                            limit={soldSeller.limit}
                            page={soldSeller.page}
                            onChange={(e, v) => setSoldSeller({ ...soldSeller, page: v })}
                        />
                    </div>
                ) : null}
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Auction Id</th>
                                <th scope="col">Auction Name</th>
                                <th scope="col">Posted On</th>
                                <th scope="col">Bid Price</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pastSeller &&
                            typeof pastSeller.data !== 'undefined' &&
                            pastSeller.data.length
                                ? pastSeller.data.map((ele) => (
                                      <tr key={ele.id}>
                                          <td>{ele.id}</td>
                                          <td>{ele.title}</td>
                                          <td>
                                              {moment(ele.date_added).format(dateTimeYearFormat)}
                                          </td>
                                          <td>$ {ele.wprice}</td>
                                          <td className="auction-view">
                                              <Link to={`/product/${ele.id}/${ele.title}`}>
                                                  View
                                              </Link>
                                          </td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                    {pastSeller &&
                    typeof pastSeller.data !== 'undefined' &&
                    pastSeller.data.length === 0 ? (
                        <p className="text-center">No data found</p>
                    ) : null}
                </div>
                {Math.ceil(pastSeller.length / pastSeller.limit) > 1 ? (
                    <div className="float-right pb-4">
                        <Pagination
                            length={pastSeller.length}
                            limit={pastSeller.limit}
                            page={pastSeller.page}
                            onChange={(e, v) => setPastSeller({ ...pastSeller, page: v })}
                        />
                    </div>
                ) : null}
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Auction Id</th>
                                <th scope="col">Vehicle Name</th>
                                <th scope="col">Review Posted By</th>
                                <th scope="col">Review Posted On</th>
                                <th scope="col">Review</th>
                                <th scope="col">Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reviews && typeof reviews.data !== 'undefined' && reviews.data.length
                                ? reviews.data.map((ele) => (
                                      <tr key={ele.message}>
                                          <td>{ele.message}</td>
                                          <td>{ele.title ? ele.title : ''}</td>
                                          <td>{ele.first_name}</td>
                                          <td>
                                              {moment(ele.date_added).format(dateTimeYearFormat)}
                                          </td>
                                          <td>
                                              <Rating value={ele.rating} readOnly />{' '}
                                          </td>

                                          <td className="auction-view">
                                              {ele.subject ? (
                                                  <FormDialog
                                                      title="View comment"
                                                      message={ele.subject}
                                                      role={'seller'}
                                                  />
                                              ) : (
                                                  'No Comment'
                                              )}
                                          </td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                    {reviews && typeof reviews.data !== 'undefined' && reviews.data.length === 0 ? (
                        <p className="text-center">No data found</p>
                    ) : null}
                </div>
            </TabPanel>
        </DashboardLayout>
    )
}

export default SellerMyAuction
