import { combineReducers } from 'redux'

import Login from './Login/login'
import SellerDashboard from './SellerDashBoard/selleDashBoard'
import Timer from './Timer/Timer'
export default combineReducers({
    Login: Login,
    SellerDashboard: SellerDashboard,
    Timer,
})
