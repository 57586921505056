import React from 'react'
import PropTypes from 'prop-types'
import './staticpages.css'
import StaticPage from '../../Utils/StaticPage'

function WhatCG(props) {
    const {} = props

    return (
        <>
            <StaticPage page_id="what_is_c_g" />
        </>
    )
}

WhatCG.propTypes = {}

export default WhatCG
