import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import WelcomeHeader from '../../components/organisms/WelcomeHeader'
import './forgetPassword.css'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import { useFormik, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import forgotPasswordSchema from './forgotPasswordSchema'
import { ForgotchangePasswordSchema } from '../../Utils/Validatior'
const ChangeForgotPassword = () => {
    const history = useHistory()
    const [value, setValue] = useState({
        new_password: '',
        confirm_password: '',
    })

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const formik = useFormik({
        initialValues: value,
        enableReinitialize: true,
        validationSchema: ForgotchangePasswordSchema,
        onSubmit: async (values) => {
            try {
                const urlParams = new URLSearchParams(window.location.search)

                const data = {
                    password: values.new_password,
                    user_token: urlParams.get('id'),
                }
                const res = await request('post', '/change-password', data)

                if (res.data.success === 'yes') {
                    enqueueSnackbar(res.data.message, { variant: 'success' })
                    history.push('/login')
                } else if (res.data.success === 'no') {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            } catch (error) {
                console.log(error)
            }
        },
    })

    return (
        <WelcomeHeader>
            <div className="forgot-password">
                <h5 className="light-white my-3 welcome-heading">Welcome to Coach & Gavel !</h5>
                <div className="forgot-container-area">
                    <h4 className="light-white">Change Password</h4>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="forgot-password-content">
                            <CustomInput
                                label="New Password"
                                type="password"
                                inputClassName="input-login login-user-name"
                                labelClass="login-user-name-label light-white"
                                placeholder="New Password"
                                name="new_password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.new_password}
                                helperText={
                                    formik.touched.new_password && formik.errors.new_password
                                        ? formik.errors.new_password
                                        : null
                                }
                                error={formik.touched.new_password && formik.errors.new_password}
                            />
                            <CustomInput
                                label="Confirm Password"
                                type="password"
                                inputClassName="input-login login-user-name"
                                labelClass="login-user-name-label light-white"
                                placeholder="Confirm Password"
                                name="confirm_new_password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirm_new_password}
                                helperText={
                                    formik.touched.confirm_new_password &&
                                    formik.errors.confirm_new_password
                                        ? formik.errors.confirm_new_password
                                        : null
                                }
                                error={
                                    formik.touched.confirm_new_password &&
                                    formik.errors.confirm_new_password
                                }
                            />
                            <div className="forgot-password-button">
                                <PrimaryButton
                                    customClassName="login-confirm-btn"
                                    label="Change Password"
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </WelcomeHeader>
    )
}

export default ChangeForgotPassword
