import React, { useState } from 'react'

import './pastAuction.css'
import Past from '../Homepage/Past'

const PastAuction = () => {
    return (
        <div className="past-auction customContainer pt-5">
            <Past page={true} className="live-auction-productGrid productGrid" />
        </div>
    )
}

export default PastAuction
