import React, { useEffect, useState } from 'react'

export default function ListGrid({ viewValue, defaultView, activeIconColor }) {
    const [viewList, setViewList] = useState(0)
    const viewClick = (event) => {
        let viewVal = event.target.getAttribute('data-icon')
        setViewList(Number(viewVal))
        viewValue(Number(viewVal))
    }
    useEffect(() => {
        if (defaultView && typeof defaultView !== 'undefined') {
            console.log('default vlaue ', defaultView)
            setViewList(defaultView)
        }
    }, [defaultView])
    return (
        <>
            <span
                data-icon="0"
                onClick={viewClick}
                className={`material-icons view-type-icons ${
                    viewList === 0 ? activeIconColor : ''
                }`}
            >
                apps
            </span>
            <span
                data-icon="1"
                onClick={viewClick}
                className={`material-icons view-type-icons ${
                    viewList === 1 ? activeIconColor : ''
                }`}
            >
                view_headline
            </span>
        </>
    )
}
