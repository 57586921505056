import React, { useState, useEffect, Fragment } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'

import CustomInput from '../../components/atoms/Inputs/CustomInput'
// import Card from '../../components/molecules/Card'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import CheckBox from '../../components/atoms/CheckBox'
import './checkout.css'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import Popup from '../../components/molecules/Popup'
import { useParams, useHistory } from 'react-router-dom'
import { request } from '../../Utils/Request'
import { ViewArrayRounded } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import { cardSchema } from '../../Utils/Validatior'
import moment from 'moment'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
const Checkout = () => {
    const [saveAddress, setSaveAddress] = useState(false)
    const [savedCard, setSavedCard] = useState(false)
    const [allCards, setAllCards] = useState([])
    const [product, setProduct] = useState({})
    const [loader, setLoader] = useState(true)
    const [payLoader, setPayLoader] = useState(false)
    const { id, title } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const [customer_id] = useState(JSON.parse(localStorage.getItem('coachUser')).card_paymentid)
    const [userToken] = useState(localStorage.getItem('coachToken'))
    const [details, setDetails] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        postal_zipCode: '',
        country: '',
        card_name: '',
        card_number: '',
        exp_date: '',
        cvv: '',
    })

    const formik = useFormik({
        initialValues: details,
        validationSchema: cardSchema,
        onSubmit: (values) => {
            console.log(values)
            makePayment('', values)
        },
    })

    const handleSave = () => {
        setSaveAddress(!saveAddress)
    }

    const savedCardopen = () => {
        setSavedCard(true)
    }
    const handleClosePopup = () => {
        setSavedCard(false)
    }

    const getProduct = async () => {
        try {
            setLoader(true)
            const res = await request('post', '/view-invoice', { id }, true)
            if (res.data.success === 'yes') {
                res.data.results.arr_temp.SlibuyPayments[0].amount = parseFloat(
                    res.data.results.arr_temp.SlibuyPayments[0].amount,
                )
                var fee = parseFloat(
                    parseFloat(res.data.results.arr_temp.SlibuyPayments[0].amount) *
                        (parseFloat(res.data.results.arr_temp.configValues[0].value) / 100),
                )
                if (fee > 2500) {
                    fee = 2500
                } else if (fee < 125) {
                    fee = 125
                }
                res.data.results.arr_temp.SlibuyPayments[0].buyers_fee = fee
                res.data.results.arr_temp.SlibuyPayments[0].total =
                    res.data.results.arr_temp.SlibuyPayments[0].amount +
                    res.data.results.arr_temp.SlibuyPayments[0].buyers_fee
                setProduct(res.data.results.arr_temp.SlibuyPayments[0])
            }

            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    const viewAllCards = async () => {
        try {
            const res = await request('post', '/customer/source/list', { customer_id }, false, 2)
            if (res.data.status === 'success') {
                setAllCards(res.data.data.responseData.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    function cardNumberFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }
        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }
    function dateFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{2,4}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 2) {
            parts.push(match.substring(i, i + 2))
        }
        if (parts.length) {
            return parts.join('/')
        } else {
            return value
        }
    }
    const makePayment = async (cardId = '', values) => {
        try {
            setPayLoader(true)
            var amt = product.buyers_fee
            if (product.buyers_fee > 2500) {
                amt = 2500
            } else if (product.buyers_fee < 125) {
                amt = 125
            }
            var paymentData = {
                amount: amt.toFixed(2) * 100,
                currency: 'usd',
            }
            if (cardId !== '') {
                paymentData.customer = customer_id
                paymentData.source = cardId
            } else {
                paymentData.card_number = values.card_number.split(' ').join('')
                paymentData.card_cvc = values.cvv
                paymentData.card_exp_month = values.exp_date.split('/')[0]
                paymentData.card_exp_year = `20${values.exp_date.split('/')[1]}`
                paymentData.card_token = true
                paymentData.message = 'Amount paid!'
                paymentData.description = 'Amount paid!'
            }
            const res = await request('post', '/charge/create', paymentData, false, 2)
            if (res.data.status !== 'success') {
                setPayLoader(false)
                return enqueueSnackbar('Payment failed!', {
                    variant: 'error',
                })
            }
            const paidData = {
                buyerPremiumTotal: amt,
                mode: process.env.REACT_APP_MODE,
                inv: product.common_invoice,
                payamt: product.buyers_fee,
                totalInvoice: '0.00',
                trans_gateway: 'Card Payment',
                transaction_id: res.data.data.responseData.balance_transaction,
                trans_id: res.data.data.responseData.balance_transaction,
                date: moment().format('DD/MM/YYYY'),
                shipAmt: '0.00',
                shipCmb: '0.00',
                shipOrg: '0.00',
                persale: '0.00',
            }
            const resPaid = await request('post', '/successGroupInvoice', paidData, true)
            if (resPaid.data.success === 'yes') {
                enqueueSnackbar('Paid Successfully!', {
                    variant: 'success',
                })
                return history.push('/dashboard/buyer/myauction')
            }
            setPayLoader(false)
        } catch (error) {
            setPayLoader(false)
            console.log(error)
        }
    }

    const getCouponCodes = async () => {
        const res = await request('get', '/getusercoupons', {}, userToken)
    }
    useEffect(() => {
        getProduct()
        viewAllCards()
        getCouponCodes()
    }, [])
    // console.log(product)
    return (
        <Fragment>
            {loader ? (
                <div>
                    <img src="/assets/svg/loader.svg" className="img-fluid mx-auto d-block" />
                </div>
            ) : (
                <Fragment>
                    <div className="checkout">
                        <div className="prdt-dtls">
                            <div className="product-list">
                                <div className="prdt-info">
                                    <div className="prod-info-img">
                                        <img
                                            src={`${process.env.REACT_APP_PRODUCT_URL}/${product.file_name}`}
                                            className="prod-info-img"
                                            alt="bike"
                                        />
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-borderless">
                                            {/* <tr>
                                                    <td>
                                                        <div className="prod-info-img">
                                                            <img
                                                                src={`${process.env.REACT_APP_PRODUCT_URL}/${product.file_name}`}
                                                                className="prod-info-img"
                                                                alt="bike"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr> */}
                                            <tr>
                                                <td>Name</td>
                                                <td>
                                                    {product.title}, {title}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Bid Amount</td>
                                                <td>
                                                    $
                                                    {product.amount &&
                                                        product.amount.toLocaleString()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Buyer’s Fee</td>
                                                <td>
                                                    $
                                                    {product.buyers_fee &&
                                                        product.buyers_fee.toLocaleString()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>
                                                    ${' '}
                                                    {product.total &&
                                                        product.total.toLocaleString()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <hr />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Amount to be pay now</td>
                                                <td>
                                                    $
                                                    {product.buyers_fee &&
                                                        product.buyers_fee.toLocaleString()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Amount due to seller</td>
                                                <td>
                                                    $
                                                    {product.amount &&
                                                        product.amount.toLocaleString()}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <hr /> */}
                            {/* <div className="shpng-address">
                                <h5>Billing Address</h5>
                                <div className="row shpng-address-row">
                                    <div className="col-12 input-left">
                                        <CustomSelect
                                            label="Address Name"
                                            labelClass="mls-added-label"
                                        >
                                            {adrs.map((ele) => (
                                                <option value={ele.value} key={ele.id}>
                                                    {ele.addressName}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    <div className="col-6 input-left">
                                        <CustomInput
                                            placeholder="Street Address"
                                            label="Street Address"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-6 input-left">
                                        <CustomInput
                                            placeholder="Address Line 2"
                                            label="Address Line 2"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-6 input-left">
                                        <CustomInput
                                            placeholder="Mobile Number"
                                            label="Mobile Number"
                                            type="number"
                                        />
                                    </div>
                                    <div className="col-6 input-left">
                                        <CustomInput placeholder="City" label="City" type="text" />
                                    </div>
                                    <div className="col-6 input-left">
                                        <CustomSelect label="State" labelClass="mls-added-label">
                                            {adrs.map((ele) => (
                                                <option value={ele.value} key={ele.id}>
                                                    {ele.addressName}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    <div className="col-6 input-left">
                                        <CustomInput
                                            placeholder="Postal/Zip Code"
                                            label="Postal/Zip Code"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-6 input-left">
                                        <CustomSelect label="Country" labelClass="mls-added-label">
                                            {adrs.map((ele) => (
                                                <option value={ele.value} key={ele.id}>
                                                    {ele.addressName}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    <div className="col-6 input-left">
                                        <CustomInput
                                            placeholder="Address Name"
                                            label="Address Name"
                                            type="text"
                                            disabled={!saveAddress}
                                        />
                                    </div>
                                    <div className="col-12 save-address-confrm">
                                        <CheckBox
                                            checked={saveAddress}
                                            onchange={handleSave}
                                            label="Save this address for later use"
                                        />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="pymt-dtls">
                            <h5>Payment</h5>
                            <form className="row pymt-dtls-row" onSubmit={formik.handleSubmit}>
                                <div className="col-12 input-left mode-pymt">
                                    {/* <CustomSelect
                                        label="Mode of Payment"
                                        labelClass="mls-added-label"
                                    >
                                        {modePaymt.map((ele) => (
                                            <option value={ele.value} key={ele.id}>
                                                {ele.name}
                                            </option>
                                        ))}
                                    </CustomSelect> */}
                                    <p>
                                        Have a saved card?{' '}
                                        <span onClick={savedCardopen}>Use here</span>
                                    </p>
                                </div>
                                {/* <div className="col-12 input-left">
                                    <CustomInput
                                        placeholder="Name on Card"
                                        label="Name on Card"
                                        type="text"
                                        name="card_name"
                                        value={formik.values.card_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={
                                            formik.touched.card_name && formik.errors.card_name
                                                ? formik.errors.card_name
                                                : null
                                        }
                                        error={formik.touched.card_name && formik.errors.card_name}
                                    />
                                </div> */}
                                <div className="col-12 input-left">
                                    <CustomInput
                                        placeholder="Card Number"
                                        label="Card Number"
                                        type="text"
                                        name="card_number"
                                        value={formik.values.card_number}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'card_number',
                                                cardNumberFormat(e.target.value),
                                            )
                                        }
                                        onBlur={formik.handleBlur}
                                        helperText={
                                            formik.touched.card_number && formik.errors.card_number
                                                ? formik.errors.card_number
                                                : null
                                        }
                                        error={
                                            formik.touched.card_number && formik.errors.card_number
                                        }
                                    />
                                </div>
                                <div className="col-6 input-left">
                                    <CustomInput
                                        placeholder="Expiry"
                                        label="Expiry"
                                        type="text"
                                        name="exp_date"
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'exp_date',
                                                dateFormat(e.target.value),
                                            )
                                        }
                                        onBlur={formik.handleBlur}
                                        value={formik.values.exp_date}
                                        helperText={
                                            formik.touched.exp_date && formik.errors.exp_date
                                                ? formik.errors.exp_date
                                                : null
                                        }
                                        error={formik.touched.exp_date && formik.errors.exp_date}
                                    />
                                </div>
                                <div className="col-6 input-left">
                                    <CustomInput
                                        placeholder="Cvv"
                                        label="Cvv"
                                        type="text"
                                        name="cvv"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.cvv}
                                        helperText={
                                            formik.touched.cvv && formik.errors.cvv
                                                ? formik.errors.cvv
                                                : null
                                        }
                                        error={formik.touched.cvv && formik.errors.cvv}
                                    />
                                </div>
                                <div className="paymt-subt">
                                    <PrimaryButton label="Pay" disabled={payLoader} type="submit" />
                                </div>
                            </form>
                        </div>
                    </div>
                    <Popup
                        title="Saved Cards"
                        open={savedCard}
                        handleClose={handleClosePopup}
                        width={'lg'}
                        className="paymentCardPopup"
                        closeButtonClass="card-close"
                        fullWidthValue={true}
                    >
                        <div className="row">
                            {allCards.map((ele, key) => (
                                <div className="col-4">
                                    <Cards
                                        preview
                                        name={
                                            JSON.parse(localStorage.getItem('coachUser')).first_name
                                        }
                                        number={`************${ele.last4}`}
                                        expiry={`${
                                            ele.exp_month < 10
                                                ? '0' + ele.exp_month.toString()
                                                : ele.exp_month
                                        }/${ele.exp_year}`}
                                        issuer={ele.brand}
                                    />
                                    <PrimaryButton
                                        label="Pay Using This Card"
                                        onClick={() => makePayment(ele.id)}
                                        disabled={payLoader}
                                    />
                                </div>
                            ))}
                        </div>
                    </Popup>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Checkout
