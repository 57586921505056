import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from '../../components/templates/DashboardLayout/DashboardLayout'
import './dashboard.css'
import CheckBox from '../../components/atoms/CheckBox'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import { check } from 'prettier'
import { useSnackbar } from 'notistack'
import { isAuthenticated } from '../../Utils/Auth'
import JwtDecode from 'jwt-decode'
const BuyerNotification = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [id, setId] = useState('')

    const [notify, setNotify] = useState(
        JwtDecode(isAuthenticated()).role === 'seller'
            ? [
                  {
                      name: 'Product Sold.',
                      checkedOne: false,
                      checkedTwo: false,
                      id: 1,
                  },
                  //   {
                  //       name: 'Product UnSold.',
                  //       checkedOne: false,
                  //       checkedTwo: false,
                  //       id: 2,
                  //   },
              ]
            : [
                  {
                      name: 'Item Successfully Added to your Watchlist.',
                      checkedOne: false,
                      checkedTwo: false,
                      id: 1,
                  },
                  {
                      name: 'Bid Submitted.',
                      checkedOne: false,
                      checkedTwo: false,
                      id: 2,
                  },
                  {
                      name: 'You are outbid on Product.',
                      checkedOne: false,
                      checkedTwo: false,
                      id: 3,
                  },
                  {
                      name: 'Product Sold.',
                      checkedOne: false,
                      checkedTwo: false,
                      id: 4,
                  },
                  //   {
                  //       name: 'Please make the payment for the product you won.',
                  //       checkedOne: false,
                  //       checkedTwo: false,
                  //       id: 4,
                  //   },
                  //   {
                  //       name: 'Product won.',
                  //       checkedOne: false,
                  //       checkedTwo: false,
                  //       id: 4,
                  //   },
              ],
    )
    const handleNotifcationChangeEmail = (e) => {
        try {
            let temp = [...notify]
            temp[parseInt(e.target.name) - 1].checkedOne = !temp[parseInt(e.target.name) - 1]
                .checkedOne
            setNotify(temp)
        } catch (error) {
            console.log(error)
        }
    }
    const handleNotifcationChangeSms = (e) => {
        try {
            let temp = [...notify]
            temp[parseInt(e.target.name) - 1].checkedTwo = !temp[parseInt(e.target.name) - 1]
                .checkedTwo
            setNotify(temp)
        } catch (error) {
            console.log(error)
        }
    }

    const submitNotification = async () => {
        try {
            let email_settings = []
            let sms_settings = []
            notify.map((value) => {
                if (value.checkedOne) {
                    email_settings.push(
                        JwtDecode(isAuthenticated()).role === 'seller' ? '4' : value.id.toString(),
                    )
                }
                if (value.checkedTwo) {
                    sms_settings.push(
                        JwtDecode(isAuthenticated()).role === 'seller' ? '4' : value.id.toString(),
                    )
                }
            })
            let notificationVal = {
                email_settings: email_settings.join(','),
                notify_id: id,
                sms_settings: sms_settings.join(','),
            }
            const res = await request('post', apiEndPoint.updateNotification, notificationVal, true)

            if (res.data.success === 'yes') {
                enqueueSnackbar('Notification Preferences Updated Successfully', {
                    variant: 'success',
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getNotify = async () => {
        try {
            const res = await request('post', apiEndPoint.getNotification, {}, true)

            if (res.data.success === 'yes') {
                if (res.data.data[0].email !== '') {
                    let noti = [...notify]
                    noti.map((val) => {
                        res.data.data[0].email.split(',').map((value) => {
                            if (parseInt(value) === val.id) {
                                val.checkedOne = true
                            }
                        })
                        res.data.data[0].sms.split(',').map((value) => {
                            if (parseInt(value) === val.id) {
                                val.checkedTwo = true
                            }
                        })
                    })
                    setNotify(noti)
                    setId(res.data.data[0].id)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getNotify()
    }, [])
    return (
        <DashboardLayout userType={JwtDecode(isAuthenticated()).role}>
            <div className="notification-table">
                <table className="table custom-table buyer-notification">
                    <thead>
                        <tr>
                            <th scope="col">Sl.No</th>
                            <th scope="col" className="notification-heading">
                                Notification
                            </th>
                            <th scope="col">Email</th>
                            <th scope="col">SMS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notify.map((value, key) => (
                            <tr key={value.id}>
                                <td>{key + 1}</td>
                                <td className="text-left">{value.name}</td>
                                <td className="notification-name">
                                    <CheckBox
                                        checked={value.checkedOne}
                                        onChange={handleNotifcationChangeEmail}
                                        name={`${value.id}`}
                                        value={value.checkedOne}
                                    />
                                </td>
                                <td className="notification-name">
                                    <CheckBox
                                        checked={value.checkedTwo}
                                        onChange={handleNotifcationChangeSms}
                                        name={`${value.id}`}
                                        value={value.checkedTwo}
                                    />
                                </td>
                            </tr>
                        ))}
                        {/* notify.checkBoxes.map((ele) => )} */}
                    </tbody>
                </table>
                <div className="notification-submit">
                    <PrimaryButton label="Submit" onClick={submitNotification} />
                </div>
            </div>
        </DashboardLayout>
    )
}

export default BuyerNotification
