import React, { useState, useEffect, useContext } from 'react'
// import CommonContext from '../../product/context/common/commonContext'
import { useDropzone } from 'react-dropzone'
import Reorder from 'react-reorder'
import move from 'lodash-move'
import { Tooltip } from '@material-ui/core'

const Uploader = (props) => {
    const [reload, setReload] = useState(false)

    // const commonContext = useContext(CommonContext)
    // const { uploadImage, uploadedFiles, resetDropZone } = commonContext
    const formik = props.formik

    const auctionAvatar = useDropzone({
        accept: props.accept,
        multiple: props.multiple,
        onDrop: (acceptedFiles) => {
            let arr = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            )
            console.log('value', arr)
            // var formData = new FormData()
            // formData.append('folder', props.folder)
            // arr.forEach((value, key) => {
            //     formData.append('file_upload', value)
            // })
            // uploadImage(formData, props.name)

            if (props.multiple) {
                let uploadedImage = []
                arr &&
                    arr.map((uploaded) => {
                        uploadedImage.push(uploaded)
                    })
                console.log('uploadedImage', uploadedImage, props.name, formik.values[props.name])
                formik.setFieldValue(props.name, [...formik.values[props.name], ...uploadedImage])
            } else {
                arr && arr.map((uploaded) => formik.setFieldValue(props.name, [uploaded]))
            }
            setReload(!reload)
            // resetDropZone(props.name)
        },
    })

    // useEffect(() => {
    //     // console.log('uploadedFiles', props.name, uploadedFiles, formik)
    //     // console.log('props.name === uploadedFiles.from', props.name === uploadedFiles.from)
    //     if (props.name === uploadedFiles.from && uploadedFiles.files.length) {
    //         console.log('uploadedFiles', uploadedFiles)
    //         if (props.multiple) {
    //             let uploadedImage = []
    //             uploadedFiles.files &&
    //                 uploadedFiles.files.map((uploaded) => {
    //                     uploadedImage.push(uploaded.file_name)
    //                 })
    //             formik.setFieldValue(props.name, [...formik.values[props.name], ...uploadedImage])
    //         } else {
    //             uploadedFiles.files &&
    //                 uploadedFiles.files.map((uploaded) =>
    //                     formik.setFieldValue(props.name, [uploaded.file_name]),
    //                 )
    //         }
    //         setReload(!reload)
    //         resetDropZone(uploadedFiles.from)
    //     }
    // }, [uploadedFiles])

    const getFileExtension = (file) => {
        console.log('file', file)
        let fileExtent = /[.]/.exec(file) ? /[^.]+$/.exec(file) : undefined
        return fileExtent && fileExtent[0]
    }

    const removeFile = (file, from) => {
        if (props.name === from) {
            let allFiles = formik.values[props.name].filter((fileUploaded) => fileUploaded !== file)
            formik.setFieldValue(props.name, allFiles)
            setReload(!reload)
        }
    }
    const viewFile = (file, from) => {
        if (props.name === from) {
            const newWindow = window.open(
                file.preview ? file.preview : `${global.site_url}uploads/${props.folder}/${file}`,
                '_blank',
                'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
        }
    }

    const onReorder = (e, from, to) => {
        let changedArray = move(formik.values[props.name], from, to)
        formik.setFieldValue([props.name], changedArray)
    }

    return (
        <>
            <div>
                <section>
                    <div
                        {...auctionAvatar.getRootProps({
                            className: 'dropzone img-upload-wrapper',
                        })}
                    >
                        <input {...auctionAvatar.getInputProps()} />
                        <div className="text-left">
                            <span className="material-icons">{props.icon}</span>
                            <h4>{props.titleText}</h4>
                            <h6>{props.innerText}</h6>
                        </div>
                        <span className="browse-upload upldBrwsBtn">Browse</span>
                    </div>
                    <p className="text-danger text-left mt-2">
                        <small>
                            Please upload at least 50 clear images of the interior, exterior,
                            undercarriage, and engine compartment
                        </small>
                        <br />
                        <small>
                            Please add images in batches of 25 or less for optimum upload speed.
                            Once a batch is added, press Browse again to add more.
                        </small>
                    </p>
                    <p className="validationError text-left">
                        {formik.touched[props.name] &&
                            formik.errors[props.name] &&
                            formik.errors[props.name]}
                    </p>
                    <aside className="thumbsContainer">
                        <>
                            {formik.values[props.name] && formik.values[props.name].length ? (
                                <>
                                    {props.reorder ? (
                                        <Reorder
                                            reorderId={props.titleText}
                                            reorderGroup={props.titleText}
                                            component="div"
                                            className="reorderCnt"
                                            draggedClassName="dragged"
                                            touchHoldTime={250}
                                            mouseHoldTime={100}
                                            onReorder={onReorder}
                                            autoScroll={true}
                                            disabled={false}
                                            disableContextMenus={true}
                                        >
                                            {formik.values[props.name].map((file, index) => (
                                                <Tooltip title={file.name} key={index}>
                                                    <div className="thumb" key={index}>
                                                        <div className="thumbInner">
                                                            {file.type === 'image/jpeg' ||
                                                            file.type === 'image/png' ||
                                                            file.type === 'image/jpeg' ? (
                                                                <div className="thumbCnt">
                                                                    <img
                                                                        src={`${file.preview}`}
                                                                        className="img"
                                                                        id={'thumb_' + index}
                                                                    />
                                                                    <div className="fileActions">
                                                                        <span
                                                                            onClick={() =>
                                                                                removeFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="cancelBtn material-icons"
                                                                        >
                                                                            delete
                                                                        </span>
                                                                        <span
                                                                            onClick={() =>
                                                                                viewFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="viewBtn material-icons"
                                                                        >
                                                                            visibility
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="thumbCnt">
                                                                        <div className="defaultThumb">
                                                                            <span className="material-icons">
                                                                                {getFileExtension(
                                                                                    file,
                                                                                ) === 'pdf'
                                                                                    ? 'picture_as_pdf'
                                                                                    : getFileExtension(
                                                                                          file,
                                                                                      ) === 'doc' ||
                                                                                      getFileExtension(
                                                                                          file,
                                                                                      ) === 'docx'
                                                                                    ? 'description'
                                                                                    : getFileExtension(
                                                                                          file,
                                                                                      ) === 'mp4' ||
                                                                                      getFileExtension(
                                                                                          file,
                                                                                      ) === 'mpeg'
                                                                                    ? 'movie'
                                                                                    : getFileExtension(
                                                                                          file,
                                                                                      ) ===
                                                                                          'mpga' ||
                                                                                      getFileExtension(
                                                                                          file,
                                                                                      ) === 'mp3'
                                                                                    ? 'volume_up'
                                                                                    : 'description'}
                                                                            </span>
                                                                        </div>
                                                                        <div className="fileActions">
                                                                            <span
                                                                                onClick={() =>
                                                                                    removeFile(
                                                                                        file,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="cancelBtn material-icons"
                                                                            >
                                                                                delete
                                                                            </span>
                                                                            <span
                                                                                onClick={() =>
                                                                                    viewFile(
                                                                                        file,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="viewBtn material-icons"
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            ))}
                                        </Reorder>
                                    ) : (
                                        formik.values[props.name].map((file, index) => (
                                            <Tooltip title={file.name} key={index}>
                                                <div className="thumb" key={index}>
                                                    <div className="thumbInner">
                                                        {file.type === 'image/jpeg' ||
                                                        file.type === 'image/png' ||
                                                        file.type === 'image/jpeg' ? (
                                                            <div className="thumbCnt">
                                                                <img
                                                                    src={`${file.preview}`}
                                                                    className="img"
                                                                    id={'thumb_' + index}
                                                                />
                                                                <div className="fileActions">
                                                                    <span
                                                                        onClick={() =>
                                                                            removeFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="cancelBtn material-icons"
                                                                    >
                                                                        delete
                                                                    </span>
                                                                    <span
                                                                        onClick={() =>
                                                                            viewFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="viewBtn material-icons"
                                                                    >
                                                                        visibility
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="thumbCnt">
                                                                    <div className="defaultThumb">
                                                                        <span className="material-icons">
                                                                            {getFileExtension(
                                                                                file,
                                                                            ) === 'pdf'
                                                                                ? 'picture_as_pdf'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'doc' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'docx'
                                                                                ? 'description'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'mp4' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'mpeg'
                                                                                ? 'movie'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'mpga' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'mp3'
                                                                                ? 'volume_up'
                                                                                : 'description'}
                                                                        </span>
                                                                    </div>
                                                                    <div className="fileActions">
                                                                        <span
                                                                            onClick={() =>
                                                                                removeFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="cancelBtn material-icons"
                                                                        >
                                                                            delete
                                                                        </span>
                                                                        <span
                                                                            onClick={() =>
                                                                                viewFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="viewBtn material-icons"
                                                                        >
                                                                            visibility
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        ))
                                    )}
                                </>
                            ) : null}
                        </>
                    </aside>
                </section>
            </div>
        </>
    )
}

export default Uploader
