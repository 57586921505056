import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Link, useHistory } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import loadable from '@loadable/component'
import './homepage.css'
import jsonp from 'jsonp'
import Swal from 'sweetalert2'
import queryString from 'query-string'
import Banner from './Banner'
import Loader from '../../components/organisms/Loader'
import Popup from '../../components/molecules/Popup'
import { isAuthenticated } from '../../Utils/Auth'
const Upcoming = lazy(() => import('./Upcoming'))
const EndingSoon = lazy(() => import('./EndingSoon'))
const Past = lazy(() => import('./Past'))
const Homepage = () => {
    const [filterData, setFilterData] = useState({
        make: '',
        model: '',
        transmission: '',
        orderby: 6,
    })
    const [searchTrigger, setSearchTrigger] = useState(false)

    const handleFilterChange = (e) => {
        const { name, value } = e.target
        setFilterData({ ...filterData, [name]: value })
    }

    const clearSearchValue = () => {
        setFilterData({ ...filterData, model: '', make: '' })
        setSearchTrigger(!searchTrigger)
    }

    const [LandingPopup, setLandingPopup] = useState(true)

    const [inpData, setinpData] = useState('')

    useEffect(() => {
        setLandingPopup(true)
    }, [])

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return Swal.fire({
                    title: 'Please enter a valid email!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: true,
                    timer: 1500,
                })
            }

            const data = {
                EMAIL: inpData,
            }
            jsonp(
                `//coachandgavel.us6.list-manage.com/subscribe/post-json?u=ac688580f769829bc4155538a&id=88f833db23&${queryString.stringify(
                    data,
                )}`,
                { param: 'c' },
                (err, data) => {
                    console.log('display', data.result)
                    if (err) {
                        return console.log('display', err)
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return Swal.fire({
                            title: data.msg,
                            icon: 'success',
                            showCancelButton: false,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                    if (data.result === 'error') {
                        return Swal.fire({
                            title: data.msg.split('<')[0],
                            icon: 'warning',
                            showCancelButton: false,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                },
            )
        }
        setinpData('')
    }

    // const history = useHistory()

    return (
        <div className="head homepage">
            <Banner
                filterData={filterData}
                setFilterData={setFilterData}
                searchTrigger={searchTrigger}
                setSearchTrigger={setSearchTrigger}
                handleFilterChange={handleFilterChange}
                clearSearchValue={clearSearchValue}
            />
            <div className="product-listing customContainer">
                <div className="listing-ending-soon">
                    <div className="listing-ending-soon">
                        <LazyLoad height={200} once={true}>
                            <Suspense fallback={<div></div>}>
                                <Upcoming
                                    filterData={filterData}
                                    searchTrigger={searchTrigger}
                                    handleFilterChange={handleFilterChange}
                                    setSearchTrigger={setSearchTrigger}
                                />
                            </Suspense>
                        </LazyLoad>
                    </div>

                    <LazyLoad height={200} once={true}>
                        <Suspense fallback={<div></div>}>
                            <EndingSoon
                                filterData={filterData}
                                searchTrigger={searchTrigger}
                                handleFilterChange={handleFilterChange}
                                heading="Listings Ending Soon"
                                setSearchTrigger={setSearchTrigger}
                            />
                        </Suspense>
                    </LazyLoad>

                    <div className="listing-ending-soon">
                        <LazyLoad height={200} once={true}>
                            <Suspense fallback={<div></div>}>
                                <Past
                                    filterData={filterData}
                                    searchTrigger={searchTrigger}
                                    handleFilterChange={handleFilterChange}
                                    setSearchTrigger={setSearchTrigger}
                                />
                            </Suspense>
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <Popup
                open={LandingPopup}
                width="xl"
                title=""
                className="landingModal"
                handleClose={() => setLandingPopup(false)}
                closeButtonClass="card-close"
                fullWidthValue={false}
                scrolltype="body"
            >
                <div className="landmodLog">
                    <img src="/assets/svg/gavel-logo.svg" className="modalLogo" alt="gavelLogo" />
                </div>
                <div className="landbody">
                    <h3>Thank You for Visiting Coach & Gavel!</h3>
                    <p>
                        We Are Excited to Have Soft Launched Our New Online Auto Auction Website On
                        <br />
                        (February 11th, 2022).
                    </p>
                    <p>
                        We Are Now Accepting Seller Vehicle Submissions. Submit Your Vehicle by
                        &nbsp;&nbsp;
                        {isAuthenticated() ? (
                            <Link to="/product_posting" style={{ color: 'var(--primColor)' }}>
                                Clicking HERE.
                            </Link>
                        ) : (
                            <Link to="/login" style={{ color: 'var(--primColor)' }}>
                                Clicking HERE
                            </Link>
                        )}
                    </p>

                    <p>
                        We Are Just a Small Business with Aspirations to Slowly Grow and Make Online
                        Auto Auctions Cheaper, More Honest, and More Convenient.
                    </p>

                    <p>
                        We Encourage Sellers to Click &quot;Submit A Vehicle&quot; On Our Site, And
                        You Will Hear Back from Us Within 24 Hours.
                    </p>

                    <p>
                        We Encourage Buyers to Take a Look at the &quot;Example Listings Coming
                        Soon&quot; And Check Back for Live Auctions Daily. &nbsp;&nbsp;
                        <div className="searchSpace">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => {
                                    setinpData(e.target.value)
                                }}
                                placeholder="Enter your email"
                                aria-label="Search"
                            />
                            {/* {inpData == '' ? <p className="m-0">please Enter Valid email</p> : null} */}
                            <button className="btn primButton" onClick={subscribe}>
                                Subscribe
                            </button>
                        </div>
                        &nbsp;&nbsp; To Receive Our Daily E-mail of Listings
                    </p>

                    <p>We Have Created a Site That Truly Stands Out from the Rest, Including..</p>

                    <h1>100% Free Listings for Sellers!</h1>

                    <h3>2.5% Buyers Fee!</h3>
                    <strong>Fastest Listing Time!</strong>
                    <strong>Most Inclusive Acceptance!</strong>
                    <p>Set It and Forget Get It Proxy Bidding Capability!</p>
                    <p>5-Star Peer-To-Peer Rankings!</p>
                    <p>Only Those Who Have Bid Can Comment, So Less Noise!</p>

                    <h3>
                        <strong> Come Try a Better Way to Buy and Sell a Used Car! </strong>
                    </h3>
                </div>
            </Popup>
        </div>
    )
}

export default Homepage
