import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'

import PostListingOne from './PostListingPageOne'
import PostListingTwo from './PostListingPageTwo'
import './postListing.css'
import { request } from '../../Utils/Request'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

const PostListing = () => {
    const [screenChange, setScreenChange] = useState(0)

    return (
        <div className="post-listing">
            <div className="postlisting-page-one">
                <Paper variant="outlined" square>
                    <div className="progress-shown">
                        <h3 className="post-list-heading">Submit a Vehicle</h3>
                        <div className="md-stepper-horizontal orange">
                            <div className={`md-step `}>
                                <div
                                    className={`md-step-circle
                         ${screenChange === 0 ? 'active-line' : 'not-active-line'}`}
                                >
                                    <span>1</span>
                                </div>
                                <div className="md-step-title progress-title">Basic Info</div>
                                <div className="md-step-bar-left"></div>
                                <div className="md-step-bar-right"></div>
                            </div>
                            <div className="md-step">
                                <div
                                    className={`md-step-circle ${
                                        screenChange === 1 ? 'active-line' : 'not-active-line'
                                    }`}
                                >
                                    <span>2</span>
                                </div>
                                <div className="md-step-title progress-title">Detailed Info</div>
                                <div className="md-step-bar-left"></div>
                                <div className="md-step-bar-right"></div>
                            </div>
                        </div>
                    </div>
                    <p className="px-5 py-3 w-75 m-auto text-danger">
                        *If you do not see the make or model of your vehicle (i.e.Boats,
                        Motorcycles, Parts), select &quot; Other &quot; in BOTH the Make and Model
                        dropdown boxes. Then type in what you have in the text box that appears.
                    </p>
                    <div className="paper-content-area">
                        {screenChange === 0 ? (
                            <PostListingOne setScreenChange={setScreenChange} />
                        ) : (
                            <PostListingTwo setScreenChange={setScreenChange} />
                        )}
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default PostListing
