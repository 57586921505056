import React from 'react'
import { ListItem, List, SwipeableDrawer, Button, Divider } from '@material-ui/core'
import { Link, NavLink, useHistory } from 'react-router-dom'
import './DashboardLayout.css'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { useCustomMediaQuery } from '../../../common/components'
import GavelIcon from '@material-ui/icons/Gavel'
import StarsIcon from '@material-ui/icons/Stars'
import PaymentIcon from '@material-ui/icons/Payment'
import PropTypes from 'prop-types'
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded'
import ContactsRoundedIcon from '@material-ui/icons/ContactsRounded'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

function DashboardLayout(props) {
    const classes = useStyles()
    const [state, setState] = React.useState({
        left: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }
    const pathname = window.location.pathname
    const list = (anchor) => (
        <div
            className={clsx(
                classes.list,
                {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                },
                'commonDashNavSlider',
            )}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="SideLogo">
                <Link to="/">
                    <img src="/assets/svg/gavel-logo.svg" className="headerLogo" alt="gavelLogo" />
                </Link>
                {/* <Button onClick={toggleDrawer(anchor, false)}>
                    <HighlightOffRoundedIcon fontSize="large" color="secondary" object />
                </Button> */}
            </div>
            <Divider />

            <h4>Dashboard</h4>
            <List>
                <ListItem button>
                    <NavLink
                        className="dashboardNav"
                        to="/dashboard/buyer/myauction"
                        activeClassName="active"
                    >
                        <ListItemIcon>
                            <GavelIcon />
                            My Purchases
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink
                        className="dashboardNav"
                        to="/dashboard/seller/myauction"
                        activeClassName="active"
                    >
                        <ListItemIcon>My Auctions</ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink
                        to="/dashboard/buyer/watchlist"
                        className="dashboardNav"
                        activeClassName="active"
                    >
                        <ListItemIcon>
                            <StarsIcon />
                            My Watchlist
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink
                        to="/dashboard/buyer/payment"
                        className="dashboardNav"
                        activeClassName="active"
                    >
                        <ListItemIcon>
                            <PaymentIcon />
                            My Payments
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink
                        to="/dashboard/buyer/notification"
                        className="dashboardNav"
                        activeClassName="active"
                    >
                        <ListItemIcon>
                            <NotificationsActiveRoundedIcon />
                            My Notifications
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink
                        to="/dashboard/buyer/profile"
                        className="dashboardNav"
                        activeClassName="active"
                    >
                        <ListItemIcon>
                            <ContactsRoundedIcon />
                            My Profile
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
            </List>
        </div>
    )

    return (
        <div className="position-relative">
            <div
                // className="dashSwipeableDrawer"
                className={
                    useCustomMediaQuery('(max-width:900px)') ? 'dashSwipeableDrawer' : 'd-none'
                }
            >
                {['left'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button onClick={toggleDrawer(anchor, true)}>
                            <DashboardIcon />
                        </Button>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor)}
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </div>
            <div className="dashboardLayout">
                <div className="dashboardLt">
                    <h4>Dashboard</h4>
                    <ul>
                        <ListItem button>
                            <NavLink
                                to="/dashboard/buyer/myauction"
                                className="dashboardNav"
                                activeClassName="active"
                            >
                                My Purchases
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                to="/dashboard/seller/myauction"
                                className="dashboardNav"
                                activeClassName="active"
                            >
                                My Auctions
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                to="/dashboard/buyer/watchlist"
                                className="dashboardNav"
                                activeClassName="active"
                            >
                                My Watchlist
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                to="/dashboard/buyer/payment"
                                className="dashboardNav"
                                activeClassName="active"
                            >
                                My Payments
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                to="/dashboard/buyer/notification"
                                className="dashboardNav"
                                activeClassName="active"
                            >
                                My Notifications
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                to="/dashboard/buyer/profile"
                                className="dashboardNav"
                                activeClassName="active"
                            >
                                My Profile
                            </NavLink>
                        </ListItem>
                    </ul>
                </div>
                <div className="dashboardRt">{props.children}</div>
            </div>
        </div>
    )
}

export default DashboardLayout
