export const dummyProductItems = [
    {
        id: 1,
        title: '2020 Mercedes-Benz AMG GT C Coupe',
        protitle: '2020 Mercedes-Benz AMG GT C Coupe',
        src: './assets/images/upcoming/compressed/2020-Mercedes-Benz-AMG-GT-C-Coupe.webp',
        fallback: './assets/images/upcoming/2020-Mercedes-Benz-AMG-GT-C-Coupe.jpg',
    },
    {
        id: 2,
        title: '2020 Chevrolet Corvette Stingray Coupe 2LT',
        protitle: '2020 Chevrolet Corvette Stingray Coupe 2LT',
        src: './assets/images/upcoming/compressed/2020-Chevrolet-Corvette-Stingray-Coupe-2LT.webp',
        fallback: './assets/images/upcoming/2020-Chevrolet-Corvette-Stingray-Coupe-2LT.jpg',
    },
    {
        id: 3,
        title: '2019 Range Rover L Supercharged',
        protitle: '2019 Range Rover L Supercharged',
        src: './assets/images/upcoming/compressed/2019-Range-Rover-L-Supercharged.webp',
        fallback: './assets/images/upcoming/2019-Range-Rover-L-Supercharged.jpg',
    },
    {
        id: 4,
        title: '2019 Mercedes-Benz G63',
        protitle: '2019 Mercedes-Benz G63',
        src: './assets/images/upcoming/compressed/2019-Mercedes-Benz-G63.webp',
        fallback: './assets/images/upcoming/2019-Mercedes-Benz-G63.jpg',
    },
    {
        id: 5,
        title: '2019 Chevrolet Corvette Z06',
        protitle: '2019 Chevrolet Corvette Z06',
        src: './assets/images/upcoming/compressed/2019-Chevrolet-Corvette-Z06.webp',
        fallback: './assets/images/upcoming/2019-Chevrolet-Corvette-Z06.jpg',
    },
    {
        id: 6,
        title: '2018 Jeep Wrangler Unlimited',
        protitle: '2018 Jeep Wrangler Unlimited',
        src: './assets/images/upcoming/compressed/2018-Jeep-Wrangler-Unlimited.webp',
        fallback: './assets/images/upcoming/2018-Jeep-Wrangler-Unlimited.jpg',
    },
    {
        id: 7,
        title: '2016 Ford Shelby Mustang GT350',
        protitle: '2016 Ford Shelby Mustang GT350',
        src: './assets/images/upcoming/compressed/2016-Ford-Shelby-Mustang-GT350.webp',
        fallback: './assets/images/upcoming/2016-Ford-Shelby-Mustang-GT350.jpg',
    },
    {
        id: 8,
        title: '2016 BMW M3 Competition',
        protitle: '2016 BMW M3 Competition',
        src: './assets/images/upcoming/compressed/2016-BMW-M3-Competition.webp',
        fallback: './assets/images/upcoming/2016-BMW-M3-Competition.jpg',
    },
    {
        id: 9,
        title: '2014 Porsche 911 C2',
        protitle: '2014 Porsche 911 C2',
        src: './assets/images/upcoming/compressed/2014-Porsche-911-C2.webp',
        fallback: './assets/images/upcoming/2014-Porsche-911-C2.jpg',
    },
    {
        id: 10,
        title: '2014 BMW M5',
        protitle: '2014 BMW M5',
        src: './assets/images/upcoming/compressed/2014-BMW-M5.webp',
        fallback: './assets/images/upcoming/2014-BMW-M5.jpg',
    },
    {
        id: 11,
        title: '2013 Toyota Land Cruiser',
        protitle: '2013 Toyota Land Cruiser',
        src: './assets/images/upcoming/compressed/2013-Toyota-Land-Cruiser.webp',
        fallback: './assets/images/upcoming/2013-Toyota-Land-Cruiser.jpg',
    },
    {
        id: 12,
        title: '2013 Mercedes-Benz SLS GT Roadster',
        protitle: '2013 Mercedes-Benz SLS GT Roadster',
        src: './assets/images/upcoming/compressed/2013-Mercedes-Benz-SLS-GT-Roadster.webp',
        fallback: './assets/images/upcoming/2013-Mercedes-Benz-SLS-GT-Roadster.jpg',
    },
    {
        id: 13,
        title: '2013 Mercedes-Benz SL63',
        protitle: '2013 Mercedes-Benz SL63',
        src: './assets/images/upcoming/compressed/2013-Mercedes-Benz-SL63.webp',
        fallback: './assets/images/upcoming/2013-Mercedes-Benz-SL63.jpg',
    },
    {
        id: 14,
        title: '2013 Mercedes-Benz ML63',
        protitle: '2013 Mercedes-Benz ML63',
        src: './assets/images/upcoming/compressed/2013-Mercedes-Benz-ML63.webp',
        fallback: './assets/images/upcoming/2013-Mercedes-Benz-ML63.jpg',
    },
    {
        id: 15,
        title: '2013 Ford Mustang Boss 302',
        protitle: '2013 Ford Mustang Boss 302',
        src: './assets/images/upcoming/compressed/2013-Ford-Mustang-Boss-302.webp',
        fallback: './assets/images/upcoming/2013-Ford-Mustang-Boss-302.jpg',
    },
    {
        id: 16,
        title: '2013 Chevrolet Corvette ZR1',
        protitle: '2013 Chevrolet Corvette ZR1',
        src: './assets/images/upcoming/compressed/2013-Chevrolet-Corvette-ZR1.webp',
        fallback: './assets/images/upcoming/2013-Chevrolet-Corvette-ZR1.jpeg',
    },
    {
        id: 17,
        title: '2011 Ferrari California',
        protitle: '2011 Ferrari California',
        src: './assets/images/upcoming/compressed/2011-Ferrari-California.webp',
        fallback: './assets/images/upcoming/2011-Ferrari-California.jpg',
    },
    {
        id: 18,
        title: '2011 BMW M3',
        protitle: '2011 BMW M3',
        src: './assets/images/upcoming/compressed/2011-BMW-M3.webp',
        fallback: './assets/images/upcoming/2011-BMW-M3.jpeg',
    },
    {
        id: 19,
        title: '2011 Audi R8 V10 Spyder',
        protitle: '2011 Audi R8 V10 Spyder',
        src: './assets/images/upcoming/compressed/2011-Audi-R8-V10-Spyder.webp',
        fallback: './assets/images/upcoming/2011-Audi-R8-V10-Spyder.jpeg',
    },
    {
        id: 20,
        title: '2010 Range Rover HSE Luxury',
        protitle: '2010 Range Rover HSE Luxury',
        src: './assets/images/upcoming/compressed/2010-Range-Rover-HSE-Luxury.webp',
        fallback: './assets/images/upcoming/2010-Range-Rover-HSE-Luxury.jpg',
    },
    {
        id: 21,
        title: '2010 Bentley Continental GTC',
        protitle: '2010 Bentley Continental GTC',
        src: './assets/images/upcoming/compressed/2010-Bentley-Continental-GTC.webp',
        fallback: './assets/images/upcoming/2010-Bentley-Continental-GTC.jpg',
    },
    {
        id: 22,
        title: '2009 Porsche 911 C4S',
        protitle: '2009 Porsche 911 C4S',
        src: './assets/images/upcoming/compressed/2009-Porsche-911-C4S.webp',
        fallback: './assets/images/upcoming/2009-Porsche-911-C4S.jpg',
    },
    {
        id: 23,
        title: '2009 Aston Martin DBS',
        protitle: '2009 Aston Martin DBS',
        src: './assets/images/upcoming/compressed/2009-Aston-Martin-DBS.webp',
        fallback: './assets/images/upcoming/2009-Aston-Martin-DBS.jpg',
    },
    {
        id: 24,
        title: '2008 BMW M5',
        protitle: '2008 BMW M5',
        src: './assets/images/upcoming/compressed/2008-BMW-M5.webp',
        fallback: './assets/images/upcoming/2008-BMW-M5.jpg',
    },
    {
        id: 25,
        title: '2007 Ferrari F430 Spider',
        protitle: '2007 Ferrari F430 Spider',
        src: './assets/images/upcoming/compressed/2007-Ferrari-F430-Spider.webp',
        fallback: './assets/images/upcoming/2007-Ferrari-F430-Spider.jpg',
    },
    {
        id: 26,
        title: '2006 Toyota Land Cruiser',
        protitle: '2006 Toyota Land Cruiser',
        src: './assets/images/upcoming/compressed/2006-Toyota-Land-Cruiser.webp',
        fallback: './assets/images/upcoming/2006-Toyota-Land-Cruiser.jpg',
    },
    {
        id: 27,
        title: '2006 Lexus LX470',
        protitle: '2006 Lexus LX470',
        src: './assets/images/upcoming/compressed/2006-Lexus-LX470.webp',
        fallback: './assets/images/upcoming/2006-Lexus-LX470.jpg',
    },
    {
        id: 28,
        title: '2006 Hummer H1 Alpha Wagon',
        protitle: '2006 Hummer H1 Alpha Wagon',
        src: './assets/images/upcoming/compressed/2006-Hummer-H1-Alpha-Wagon.webp',
        fallback: './assets/images/upcoming/2006-Hummer-H1-Alpha-Wagon.jpg',
    },
    {
        id: 29,
        title: '2006 Hummer H1 Alpha Open Top',
        protitle: '2006 Hummer H1 Alpha Open Top',
        src: './assets/images/upcoming/compressed/2006-Hummer-H1-Alpha-Open-Top.webp',
        fallback: './assets/images/upcoming/2006-Hummer-H1-Alpha-Open-Top.jpg',
    },
    {
        id: 30,
        title: '2006 Ferrari 612 Scaglietti',
        protitle: '2006 Ferrari 612 Scaglietti',
        src: './assets/images/upcoming/compressed/2006-Ferrari-612-Scaglietti.webp',
        fallback: './assets/images/upcoming/2006-Ferrari-612-Scaglietti.jpg',
    },
    {
        id: 31,
        title: '2006 BMW M3 Convertible',
        protitle: '2006 BMW M3 Convertible',
        src: './assets/images/upcoming/compressed/2006-BMW-M3-Convertible.webp',
        fallback: './assets/images/upcoming/2006-BMW-M3-Convertible.jpg',
    },
    {
        id: 32,
        title: '2005 Mercedes-Benz S55',
        protitle: '2005 Mercedes-Benz S55',
        src: './assets/images/upcoming/compressed/2005-Mercedes-Benz-S55.webp',
        fallback: './assets/images/upcoming/2005-Mercedes-Benz-S55.jpg',
    },
    {
        id: 33,
        title: '2005 Lamborghini Gallardo',
        protitle: '2005 Lamborghini Gallardo',
        src: './assets/images/upcoming/compressed/2005-Lamborghini-Gallardo.webp',
        fallback: './assets/images/upcoming/2005-Lamborghini-Gallardo.jpeg',
    },
    {
        id: 34,
        title: '2005 Jeep Wrangler Rubicon',
        protitle: '2005 Jeep Wrangler Rubicon',
        src: './assets/images/upcoming/compressed/2005-Jeep-Wrangler-Rubicon.webp',
        fallback: './assets/images/upcoming/2005-Jeep-Wrangler-Rubicon.jpg',
    },
    {
        id: 35,
        title: '2005 Honda S2000',
        protitle: '2005 Honda S2000',
        src: './assets/images/upcoming/compressed/2005-Honda-S2000.webp',
        fallback: './assets/images/upcoming/2005-Honda-S2000.jpg',
    },
    {
        id: 36,
        title: '2004 Rolls Royce Phantom',
        protitle: '2004 Rolls Royce Phantom',
        src: './assets/images/upcoming/compressed/2004-Rolls-Royce-Phantom.webp',
        fallback: './assets/images/upcoming/2004-Rolls-Royce-Phantom.jpg',
    },
    {
        id: 37,
        title: '2004 Porsche 911 C2 Cab',
        protitle: '2004 Porsche 911 C2 Cab',
        src: './assets/images/upcoming/compressed/2004-Porsche-911-C2-Cab.webp',
        fallback: './assets/images/upcoming/2004-Porsche-911-C2-Cab.jpg',
    },
    {
        id: 38,
        title: '2004 Mercedes Benz E55',
        protitle: '2004 Mercedes Benz E55',
        src: './assets/images/upcoming/compressed/2004-Mercedes-Benz-E55.webp',
        fallback: './assets/images/upcoming/2004-Mercedes-Benz-E55.jpeg',
    },
    {
        id: 39,
        title: '2004 Land Rover Discovery HSE',
        protitle: '2004 Land Rover Discovery HSE',
        src: './assets/images/upcoming/compressed/2004-Land-Rover-Discovery-HSE.webp',
        fallback: './assets/images/upcoming/2004-Land-Rover-Discovery-HSE.jpg',
    },
    {
        id: 40,
        title: '2004 Chevrolet Corvette Z06',
        protitle: '2004 Chevrolet Corvette Z06',
        src: './assets/images/upcoming/compressed/2004-Chevrolet-Corvette-Z06.webp',
        fallback: './assets/images/upcoming/2004-Chevrolet-Corvette-Z06.jpg',
    },
    {
        id: 41,
        title: '2003 Ferrari 360 Modena',
        protitle: '2003 Ferrari 360 Modena',
        src: './assets/images/upcoming/compressed/2003-Ferrari-360-Modena.webp',
        fallback: './assets/images/upcoming/2003-Ferrari-360-Modena.jpg',
    },
    {
        id: 42,
        title: '2003 BMW Z8 Alpina',
        protitle: '2003 BMW Z8 Alpina',
        src: './assets/images/upcoming/compressed/2003-BMW-Z8-Alpina.webp',
        fallback: './assets/images/upcoming/2003-BMW-Z8-Alpina.jpg',
    },
    {
        id: 43,
        title: '2002 Ferrari 456M GTA',
        protitle: '2002 Ferrari 456M GTA',
        src: './assets/images/upcoming/compressed/2002-Ferrari-456M-GTA.webp',
        fallback: './assets/images/upcoming/2002-Ferrari-456M-GTA.jpg',
    },
    {
        id: 44,
        title: '2001 Range Rover 4.6 HSE',
        protitle: '2001 Range Rover 4.6 HSE',
        src: './assets/images/upcoming/compressed/2001-Range-Rover-4.6-HSE.webp',
        fallback: './assets/images/upcoming/2001-Range-Rover-4.6-HSE.jpg',
    },
    {
        id: 45,
        title: '2001 Mercedes-Benz SL500',
        protitle: '2001 Mercedes-Benz SL500',
        src: './assets/images/upcoming/compressed/2001-Mercedes-Benz-SL500.webp',
        fallback: './assets/images/upcoming/2001-Mercedes-Benz-SL500.jpg',
    },
    {
        id: 46,
        title: '2001 BMW M5',
        protitle: '2001 BMW M5',
        src: './assets/images/upcoming/compressed/2001-BMW-M5.webp',
        fallback: './assets/images/upcoming/2001-BMW-M5.jpg',
    },
    {
        id: 47,
        title: '2001 BMW M Roadster',
        protitle: '2001 BMW M Roadster',
        src: './assets/images/upcoming/compressed/2001-BMW-M-Roadster.webp',
        fallback: './assets/images/upcoming/2001-BMW-M-Roadster.jpg',
    },
    {
        id: 48,
        title: '2000 Mazda MX 5 Miata',
        protitle: '2000 Mazda MX 5 Miata',
        src: './assets/images/upcoming/compressed/2000-Mazda-MX-5-Miata.webp',
        fallback: './assets/images/upcoming/2000-Mazda-MX-5-Miata.jpg',
    },
    {
        id: 49,
        title: '1999 Mercedes-Benz S500',
        protitle: '1999 Mercedes-Benz S500',
        src: './assets/images/upcoming/compressed/1999-Mercedes-Benz-S500.webp',
        fallback: './assets/images/upcoming/1999-Mercedes-Benz-S500.jpg',
    },
    {
        id: 50,
        title: '1999 Acura-NSX T',
        protitle: '1999 Acura-NSX T',
        src: './assets/images/upcoming/compressed/1999-Acura-NSX-T.webp',
        fallback: './assets/images/upcoming/1999-Acura-NSX-T.jpg',
    },
    {
        id: 51,
        title: '1998 Porsche 911 C2S',
        protitle: '1998 Porsche 911 C2S',
        src: './assets/images/upcoming/compressed/1998-Porsche-911-C2S.webp',
        fallback: './assets/images/upcoming/1998-Porsche-911-C2S.jpg',
    },
    {
        id: 52,
        title: '1997 Toyota Land Cruiser',
        protitle: '1997 Toyota Land Cruiser',
        src: './assets/images/upcoming/compressed/1997-Toyota-Land-Cruiser.webp',
        fallback: './assets/images/upcoming/1997-Toyota-Land-Cruiser.jpg',
    },
    {
        id: 53,
        title: '1997 Lexus LX450',
        protitle: '1997 Lexus LX450',
        src: './assets/images/upcoming/compressed/1997-Lexus-LX450.webp',
        fallback: './assets/images/upcoming/1997-Lexus-LX450.jpg',
    },
    {
        id: 54,
        title: '1996 Land Rover Defender 110',
        protitle: '1996 Land Rover Defender 110',
        src: './assets/images/upcoming/compressed/1996-Land-Rover-Defender-110.webp',
        fallback: './assets/images/upcoming/1996-Land-Rover-Defender-110.jpg',
    },
    {
        id: 55,
        title: '1996 Ford Bronco Eddie Bauer',
        protitle: '1996 Ford Bronco Eddie Bauer',
        src: './assets/images/upcoming/compressed/1996-Ford-Bronco-Eddie-Bauer.webp',
        fallback: './assets/images/upcoming/1996-Ford-Bronco-Eddie-Bauer.jpg',
    },
    {
        id: 56,
        title: '1995 Toyota Supra Turbo',
        protitle: '1995 Toyota Supra Turbo',
        src: './assets/images/upcoming/compressed/1995-Toyota-Supra-Turbo.webp',
        fallback: './assets/images/upcoming/1995-Toyota-Supra-Turbo.jpg',
    },
    {
        id: 58,
        title: '1995 Range Rover County SWB',
        protitle: '1995 Range Rover County SWB',
        src: './assets/images/upcoming/compressed/1995-Range-Rover-County-SWB.webp',
        fallback: './assets/images/upcoming/1995-Range-Rover-County-SWB.jpg',
    },
    {
        id: 59,
        title: '1995 Mazda MX-5 Miata',
        protitle: '1995 Mazda MX-5 Miata',
        src: './assets/images/upcoming/compressed/1995-Mazda-MX-5-Miata.webp',
        fallback: './assets/images/upcoming/1995-Mazda-MX-5-Miata.jpg',
    },
    {
        id: 60,
        title: '1995 Land Rover Defender 90',
        protitle: '1995 Land Rover Defender 90',
        src: './assets/images/upcoming/compressed/1995-Land-Rover-Defender-90.webp',
        fallback: './assets/images/upcoming/1995-Land-Rover-Defender-90.jpg',
    },
    {
        id: 61,
        title: '1995 Lamborghini Diablo',
        protitle: '1995 Lamborghini Diablo',
        src: './assets/images/upcoming/compressed/1995-Lamborghini-Diablo.webp',
        fallback: './assets/images/upcoming/1995-Lamborghini-Diablo.jpg',
    },
    {
        id: 62,
        title: '1995 Dodge Viper',
        protitle: '1995 Dodge Viper',
        src: './assets/images/upcoming/compressed/1995-Dodge-Viper.webp',
        fallback: './assets/images/upcoming/1995-Dodge-Viper.jpg',
    },
    {
        id: 63,
        title: '1994-Porsche-928-GTS',
        protitle: '1994-Porsche-928-GTS',
        src: './assets/images/upcoming/compressed/1994-Porsche-928-GTS.webp',
        fallback: './assets/images/upcoming/1994-Porsche-928-GTS.jpeg',
    },
    {
        id: 64,
        title: '1994-Porsche-911-C2-Cab',
        protitle: '1994-Porsche-911-C2-Cab',
        src: './assets/images/upcoming/compressed/1994-Porsche-911-C2-Cab.webp',
        fallback: './assets/images/upcoming/1994-Porsche-911-C2-Cab.jpg',
    },
    {
        id: 65,
        title: '1994 BMW 850CSI',
        protitle: '1994 BMW 850CSI',
        src: './assets/images/upcoming/compressed/1994-BMW-850CSI.webp',
        fallback: './assets/images/upcoming/1994-BMW-850CSI.jpg',
    },
    {
        id: 66,
        title: '1994 Acura Legend Coupe',
        protitle: '1994 Acura Legend Coupe',
        src: './assets/images/upcoming/compressed/1994-Acura-Legend-Coupe.webp',
        fallback: './assets/images/upcoming/1994-Acura-Legend-Coupe.jpg',
    },
    {
        id: 67,
        title: '1993 Mazda RX-7',
        protitle: '1993 Mazda RX-7',
        src: './assets/images/upcoming/compressed/1993-Mazda-RX-7.webp',
        fallback: './assets/images/upcoming/1993-Mazda-RX-7.jpg',
    },
    {
        id: 68,
        title: '1992 BMW-M5 Touring',
        protitle: '1992 BMW-M5 Touring',
        src: './assets/images/upcoming/compressed/1992-BMW-M5-Touring.webp',
        fallback: './assets/images/upcoming/1992-BMW-M5-Touring.jpg',
    },
    {
        id: 69,
        title: '1992 BMW 325i Convertible',
        protitle: '1992 BMW 325i Convertible',
        src: './assets/images/upcoming/compressed/1992-BMW-325i-Convertible.webp',
        fallback: './assets/images/upcoming/1992-BMW-325i-Convertible.jpg',
    },
    {
        id: 70,
        title: '1991 Mercedes-Benz 560SEC',
        protitle: '1991 Mercedes-Benz 560SEC',
        src: './assets/images/upcoming/compressed/1991-Mercedes-Benz-560SEC.webp',
        fallback: './assets/images/upcoming/1991-Mercedes-Benz-560SEC.jpeg',
    },
    {
        id: 71,
        title: '1991 Jeep Grand Wagoneer',
        protitle: '1991 Jeep Grand Wagoneer',
        src: './assets/images/upcoming/compressed/1991-Jeep-Grand-Wagoneer.webp',
        fallback: './assets/images/upcoming/1991-Jeep-Grand-Wagoneer.jpg',
    },
    {
        id: 72,
        title: '1990 Nissan 300ZX',
        protitle: '1990 Nissan 300ZX',
        src: './assets/images/upcoming/compressed/1990-Nissan-300ZX.webp',
        fallback: './assets/images/upcoming/1990-Nissan-300ZX.jpg',
    },
    {
        id: 73,
        title: '1990 Ferrari 348 TS',
        protitle: '1990 Ferrari 348 TS',
        src: './assets/images/upcoming/compressed/1990-Ferrari-348-TS.webp',
        fallback: './assets/images/upcoming/1990-Ferrari-348-TS.jpg',
    },
    {
        id: 74,
        title: '1990 Chevrolet Corvette ZR1',
        protitle: '1990 Chevrolet Corvette ZR1',
        src: './assets/images/upcoming/compressed/1990-Chevrolet-Corvette-ZR1.webp',
        fallback: './assets/images/upcoming/1990-Chevrolet-Corvette-ZR1.jpg',
    },
    {
        id: 75,
        title: '1989 Toyota Land Cruiser FJ62',
        protitle: '1989 Toyota Land Cruiser FJ62',
        src: './assets/images/upcoming/compressed/1989-Toyota-Land-Cruiser-FJ62.webp',
        fallback: './assets/images/upcoming/1989-Toyota-Land-Cruiser-FJ62.jpg',
    },
    {
        id: 76,
        title: '1989 Porsche 911 C2 Cab',
        protitle: '1989 Porsche 911 C2 Cab',
        src: './assets/images/upcoming/compressed/1989-Porsche-911-C2-Cab.webp',
        fallback: './assets/images/upcoming/1989-Porsche-911-C2-Cab.jpg',
    },
    {
        id: 77,
        title: '1989 Mercedes-Benz 560SL',
        protitle: '1989 Mercedes-Benz 560SL',
        src: './assets/images/upcoming/compressed/1989-Mercedes-Benz-560SL.webp',
        fallback: './assets/images/upcoming/1989-Mercedes-Benz-560SL.jpg',
    },
    {
        id: 78,
        title: '1989 BMW M3 Coupe',
        protitle: '1989 BMW M3 Coupe',
        src: './assets/images/upcoming/compressed/1989-BMW-M3-Coupe.webp',
        fallback: './assets/images/upcoming/1989-BMW-M3-Coupe.jpg',
    },
    {
        id: 79,
        title: '1988 BMW M5',
        protitle: '1988 BMW M5',
        src: './assets/images/upcoming/compressed/1988-BMW-M5.webp',
        fallback: './assets/images/upcoming/1988-BMW-M5.jpg',
    },
    {
        id: 80,
        title: '1987 Ferrari 328 GTS',
        protitle: '1987 Ferrari 328 GTS',
        src: './assets/images/upcoming/compressed/1987-Ferrari-328-GTS.webp',
        fallback: './assets/images/upcoming/1987-Ferrari-328-GTS.jpg',
    },
    {
        id: 81,
        title: '1987 BMW M6',
        protitle: '1987 BMW M6',
        src: './assets/images/upcoming/compressed/1987-BMW-M6.webp',
        fallback: './assets/images/upcoming/1987-BMW-M6.webp',
    },
    {
        id: 82,
        title: '1986 Jeep CJ7',
        protitle: '1986 Jeep CJ7',
        src: './assets/images/upcoming/compressed/1986-Jeep-CJ7.webp',
        fallback: './assets/images/upcoming/1986-Jeep-CJ7.jpeg',
    },
    {
        id: 83,
        title: '1985 Jeep CJ5',
        protitle: '1985 Jeep CJ5',
        src: './assets/images/upcoming/compressed/1985-Jeep-CJ5.webp',
        fallback: './assets/images/upcoming/1985-Jeep-CJ5.jpg',
    },
    {
        id: 84,
        title: '1982 Toyota Land Cruiser FJ40',
        protitle: '1982 Toyota Land Cruiser FJ40',
        src: './assets/images/upcoming/compressed/1982-Toyota-Land-Cruiser-FJ40.webp',
        fallback: './assets/images/upcoming/1982-Toyota-Land-Cruiser-FJ40.jpg',
    },
    {
        id: 85,
        title: '1981 Delorean DMC-12',
        protitle: '1981 Delorean DMC-12',
        src: './assets/images/upcoming/compressed/1981-Delorean-DMC-12.webp',
        fallback: './assets/images/upcoming/1981-Delorean-DMC-12.jpg',
    },
    {
        id: 86,
        title: '1979 Mercedes-Benz Unimog 421',
        protitle: '1979 Mercedes-Benz Unimog 421',
        src: './assets/images/upcoming/compressed/1979-Mercedes-Benz-Unimog-421.webp',
        fallback: './assets/images/upcoming/1979-Mercedes-Benz-Unimog-421.jpg',
    },
    {
        id: 87,
        title: '1978 Ferrari 308 GT4',
        protitle: '1978 Ferrari 308 GT4',
        src: './assets/images/upcoming/compressed/1978-Ferrari-308-GT4.webp',
        fallback: './assets/images/upcoming/1978-Ferrari-308-GT4.jpg',
    },
    {
        id: 88,
        title: '1974 Ferrari Dino 246 GTS',
        protitle: '1974 Ferrari Dino 246 GTS',
        src: './assets/images/upcoming/compressed/1974-Ferrari-Dino-246-GTS.webp',
        fallback: './assets/images/upcoming/1974-Ferrari-Dino-246-GTS.jpg',
    },
    {
        id: 89,
        title: '1973 BMW 3.0CSI',
        protitle: '1973 BMW 3.0CSI',
        src: './assets/images/upcoming/compressed/1973-BMW-3.0CSI.webp',
        fallback: './assets/images/upcoming/1973-BMW-3.0CSI.jpg',
    },
    {
        id: 90,
        title: '1972 BMW 2002',
        protitle: '1972 BMW 2002',
        src: './assets/images/upcoming/compressed/1972-BMW-2002.webp',
        fallback: './assets/images/upcoming/1972-BMW-2002.jpg',
    },
    {
        id: 91,
        title: '1971 Porsche 911T',
        protitle: '1971 Porsche 911T',
        src: './assets/images/upcoming/compressed/1971-Porsche-911T.webp',
        fallback: './assets/images/upcoming/1971-Porsche-911T.jpg',
    },
    {
        id: 92,
        title: '1971 Mercedes-Benz 300SEL 6.3',
        protitle: '1971 Mercedes-Benz 300SEL 6.3',
        src: './assets/images/upcoming/compressed/1971-Mercedes-Benz-300SEL-6.3.webp',
        fallback: './assets/images/upcoming/1971-Mercedes-Benz-300SEL-6.3.jpg',
    },
    {
        id: 93,
        title: '1971 Mercedes-Benz 280SE 3.5 Coupe',
        protitle: '1971 Mercedes-Benz 280SE 3.5 Coupe',
        src: './assets/images/upcoming/compressed/1971-Mercedes-Benz-280SE-3.5-Coupe.webp',
        fallback: './assets/images/upcoming/1971-Mercedes-Benz-280SE-3.5-Coupe.jpg',
    },
    {
        id: 94,
        title: '1969 Pontiac GTO Judge',
        protitle: '1969 Pontiac GTO Judge',
        src: './assets/images/upcoming/compressed/1969-Pontiac-GTO-Judge.webp',
        fallback: './assets/images/upcoming/1969-Pontiac-GTO-Judge.jpg',
    },
    {
        id: 95,
        title: '1969 Chevrolet Corvette C3',
        protitle: '1969 Chevrolet Corvette C3',
        src: './assets/images/upcoming/compressed/1969-Chevrolet-Corvette-C3.webp',
        fallback: './assets/images/upcoming/1969-Chevrolet-Corvette-C3.jpg',
    },
    {
        id: 96,
        title: '1969 Chevrolet Camaro Z28',
        protitle: '1969 Chevrolet Camaro Z28',
        src: './assets/images/upcoming/compressed/1969-Chevrolet-Camaro-Z28.webp',
        fallback: './assets/images/upcoming/1969-Chevrolet-Camaro-Z28.jpg',
    },
    {
        id: 97,
        title: '1968 Mercedes-Benz 280SL',
        protitle: '1968 Mercedes-Benz 280SL',
        src: './assets/images/upcoming/compressed/1968-Mercedes-Benz-280SL.webp',
        fallback: './assets/images/upcoming/1968-Mercedes-Benz-280SL.jpg',
    },
    {
        id: 98,
        title: '1968 Ford Shelby Mustang GT500',
        protitle: '1968 Ford Shelby Mustang GT500',
        src: './assets/images/upcoming/compressed/1968-Ford-Shelby-Mustang-GT500.webp',
        fallback: './assets/images/upcoming/1968-Ford-Shelby-Mustang-GT500.jpg',
    },
    {
        id: 99,
        title: '1967 Lincoln Continental Convertible',
        protitle: '1967 Lincoln Continental Convertible',
        src: './assets/images/upcoming/compressed/1967-Lincoln-Continental-Convertible.webp',
        fallback: './assets/images/upcoming/1967-Lincoln-Continental-Convertible.jpg',
    },
    {
        id: 100,
        title: '1967 Austin Healey 3000 BJ8 Mk III',
        protitle: '1967 Austin Healey 3000 BJ8 Mk III',
        src: './assets/images/upcoming/compressed/1967-Austin-Healey-3000-BJ8-Mk-III.webp',
        fallback: './assets/images/upcoming/1967-Austin-Healey-3000-BJ8-Mk-III.jpg',
    },
    {
        id: 101,
        title: '1966 Ford Shelby Mustang GT350H',
        protitle: '1966 Ford Shelby Mustang GT350H',
        src: './assets/images/upcoming/compressed/1966-Ford-Shelby-Mustang-GT350H.webp',
        fallback: './assets/images/upcoming/1966-Ford-Shelby-Mustang-GT350H.jpg',
    },
    {
        id: 102,
        title: '1966 Ford Bronco',
        protitle: '1966 Ford Bronco',
        src: './assets/images/upcoming/compressed/1966-Ford-Bronco.webp',
        fallback: './assets/images/upcoming/1966-Ford-Bronco.jpg',
    },
    {
        id: 103,
        title: '1965 Shelby Cobra 427',
        protitle: '1965 Shelby Cobra 427',
        src: './assets/images/upcoming/compressed/1965-Shelby-Cobra-427.webp',
        fallback: './assets/images/upcoming/1965-Shelby-Cobra-427.jpg',
    },
    {
        id: 104,
        title: '1965 Porsche 356C Coupe',
        protitle: '1965 Porsche 356C Coupe',
        src: './assets/images/upcoming/compressed/1965-Porsche-356C-Coupe.webp',
        fallback: './assets/images/upcoming/1965-Porsche-356C-Coupe.jpeg',
    },
    {
        id: 105,
        title: '1965 Ferrari 330GT 2+2 Series II',
        protitle: '1965 Ferrari 330GT 2+2 Series II',
        src: './assets/images/upcoming/compressed/1965-Ferrari-330GT-2+2-Series-II.webp',
        fallback: './assets/images/upcoming/1965-Ferrari-330GT-2+2-Series-II.jpg',
    },
    {
        id: 106,
        title: '1965 Chevrolet Corvette C2',
        protitle: '1965 Chevrolet Corvette C2',
        src: './assets/images/upcoming/compressed/1965-Chevrolet-Corvette-C2.webp',
        fallback: './assets/images/upcoming/1965-Chevrolet-Corvette-C2.jpg',
    },
    {
        id: 107,
        title: '1964 Jaguar XKE Coupe',
        protitle: '1964 Jaguar XKE Coupe',
        src: './assets/images/upcoming/compressed/1964-Jaguar-XKE-Coupe.webp',
        fallback: './assets/images/upcoming/1964-Jaguar-XKE-Coupe.jpg',
    },
    {
        id: 108,
        title: '1964 Cadillac Deville Convertible',
        protitle: '1964 Cadillac Deville Convertible',
        src: './assets/images/upcoming/compressed/1964-Cadillac-Deville-Convertible.webp',
        fallback: './assets/images/upcoming/1964-Cadillac-Deville-Convertible.jpg',
    },
    {
        id: 109,
        title: '1963 Jaguar XKE Roadster',
        protitle: '1963 Jaguar XKE Roadster',
        src: './assets/images/upcoming/compressed/1963-Jaguar-XKE-Roadster.webp',
        fallback: './assets/images/upcoming/1963-Jaguar-XKE-Roadster.jpg',
    },
    {
        id: 110,
        title: '1960 Porsche 356B Cab',
        protitle: '1960 Porsche 356B Cab',
        src: './assets/images/upcoming/compressed/1960-Porsche-356B-Cab.webp',
        fallback: './assets/images/upcoming/1960-Porsche-356B-Cab.jpg',
    },
    {
        id: 111,
        title: '1960 Mercedes-Benz 190SL',
        protitle: '1960 Mercedes-Benz 190SL',
        src: './assets/images/upcoming/compressed/1960-Mercedes-Benz-190SL.webp',
        fallback: './assets/images/upcoming/1960-Mercedes-Benz-190SL.jpg',
    },
    {
        id: 112,
        title: '1960 BMW Isetta 300',
        protitle: '1960 BMW Isetta 300',
        src: './assets/images/upcoming/compressed/1960-BMW-Isetta-300.webp',
        fallback: './assets/images/upcoming/1960-BMW-Isetta-300.jpg',
    },
    {
        id: 113,
        title: '1959 MG MGA',
        protitle: '1959 MG MGA',
        src: './assets/images/upcoming/compressed/1959-MG-MGA.webp',
        fallback: './assets/images/upcoming/1959-MG-MGA.jpg',
    },
    {
        id: 114,
        title: '1958 Porsche 356A Cab',
        protitle: '1958 Porsche 356A Cab',
        src: './assets/images/upcoming/compressed/1958-Porsche-356A-Cab.webp',
        fallback: './assets/images/upcoming/1958-Porsche-356A-Cab.jpg',
    },
    {
        id: 115,
        title: '1957 Ford Thunderbird',
        protitle: '1957 Ford Thunderbird',
        src: './assets/images/upcoming/compressed/1957-Ford-Thunderbird.webp',
        fallback: './assets/images/upcoming/1957-Ford-Thunderbird.jpg',
    },
    {
        id: 116,
        title: '1956 Rolls-Royce Silver Cloud I',
        protitle: '1956 Rolls-Royce Silver Cloud I',
        src: './assets/images/upcoming/compressed/1956-Rolls-Royce-Silver-Cloud-I.webp',
        fallback: './assets/images/upcoming/1956-Rolls-Royce-Silver-Cloud-I.jpg',
    },
    {
        id: 117,
        title: '1956 Chevrolet Corvette C1',
        protitle: '1956 Chevrolet Corvette C1',
        src: './assets/images/upcoming/compressed/1956-Chevrolet-Corvette-C1.webp',
        fallback: './assets/images/upcoming/1956-Chevrolet-Corvette-C1.jpg',
    },
    {
        id: 118,
        title: '1956 Cadillac Eldorado Biaritz',
        protitle: '1956 Cadillac Eldorado Biaritz',
        src: './assets/images/upcoming/compressed/1956-Cadillac-Eldorado-Biaritz.webp',
        fallback: './assets/images/upcoming/1956-Cadillac-Eldorado-Biaritz.jpg',
    },
    {
        id: 119,
        title: '1955 Jaguar XK140 Roadster',
        protitle: '1955 Jaguar XK140 Roadster',
        src: './assets/images/upcoming/compressed/1955-Jaguar-XK140-Roadster.webp',
        fallback: './assets/images/upcoming/1955-Jaguar-XK140-Roadster.jpg',
    },
    {
        id: 120,
        title: '1951 Vincent Black Shadow',
        protitle: '1951 Vincent Black Shadow',
        src: './assets/images/upcoming/compressed/1951-Vincent-Black-Shadow.webp',
        fallback: './assets/images/upcoming/1951-Vincent-Black-Shadow.jpg',
    },
    {
        id: 121,
        title: '1940 Indian Four',
        protitle: '1940 Indian Four',
        src: './assets/images/upcoming/compressed/1940-Indian-Four.webp',
        fallback: './assets/images/upcoming/1940-Indian-Four.jpg',
    },
]
