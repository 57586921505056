import React from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

const App = () => {
    return (
        <div className="App">
            <SnackbarProvider
                maxSnack={1}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Router>
                    <Routes />
                </Router>
            </SnackbarProvider>
        </div>
    )
}

export default App
