export const baseUrl = 'https://forwardapi.auctionsoftware.com/mobileapi'

export const apiEndPoint = {
    login: '/login',
    mobilesearch: '/mobilesearch',
    forgotpassword: '/forgot_password',
    activate: '/activate',
    updateNotification: '/updatenotification',
    getNotification: '/getnotification',
    payAmountStripe: '/payAmountStripe',
    viewAllCard: '/viewAllCard',
    getSellerProducts: '/getSellerProducts',
    buyerDashboard: '/buyerDashboard',
}
