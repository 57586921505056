import React, { useState, useEffect } from 'react'
import ProductCard from '../../components/molecules/ProductCard'

import DashboardLayout from '../../components/templates/DashboardLayout/DashboardLayout'
import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Pagination from '../../components/atoms/Pagination/Pagination'
const BuyerWatchlist = (props) => {
    const [buyerWatch, setBuyerWatch] = useState({
        lengthVal: 0,
        data: [],
        page: 1,
        limit: 10,
    })
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const handleWatchList = async () => {
        try {
            let data = {
                status: 'watchlist',
            }
            const res = await request('post', apiEndPoint.buyerDashboard, data, true)
            if (res.data.success == 'yes') {
                if (res.data.results instanceof Array) {
                    let arrayChck = res.data.results.map((ele) => {
                        let newObj = ele
                        newObj['watch'] = true
                        return newObj
                    })
                    setBuyerWatch({
                        ...buyerWatch,
                        data: arrayChck,
                        lengthVal: res.data.total_pagecnt,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onHandleCheckedWatchList = async (checked, propsData) => {
        try {
            const data = {
                product_id: propsData,
                // watchlistemail: 1
            }
            const res = await request(
                'post',
                !checked ? '/add_watchlist' : '/remove_watchlist',
                data,
                true,
            )
            if (res.data.status === 'yes') {
                enqueueSnackbar(res.data.message, {
                    variant: 'success',
                })
                handleWatchList()
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setBuyerWatch([])
        handleWatchList()
    }, [])
    return (
        <DashboardLayout userType="buyer">
            <div
                className={`productGrid ${
                    buyerWatch &&
                    typeof buyerWatch.data !== 'undefined' &&
                    buyerWatch.data.length === 0
                        ? ''
                        : 'buyerwathlist-grid'
                }`}
            >
                {buyerWatch &&
                typeof buyerWatch.data !== 'undefined' &&
                buyerWatch.data.length !== 0
                    ? buyerWatch.data.map((ele) => (
                          <ProductCard
                              data={ele}
                              key={ele.id}
                              onClick={() => history.push(`/product/${ele.id}/${ele.title}`)}
                              handleChecked={onHandleCheckedWatchList}
                          />
                      ))
                    : null}

                {buyerWatch &&
                typeof buyerWatch.data !== 'undefined' &&
                buyerWatch.data.length === 0 ? (
                    <p className="watchlist-nodata">No Result Found</p>
                ) : null}
            </div>

            {buyerWatch &&
            typeof buyerWatch.data !== 'undefined' &&
            buyerWatch.data.length !== 0 ? (
                <div className="float-right pb-4">
                    <Pagination
                        length={buyerWatch.lengthVal}
                        limit={buyerWatch.limit}
                        page={buyerWatch.page}
                        onChange={(e, v) => setBuyerWatch({ ...buyerWatch, page: v })}
                    />
                </div>
            ) : null}
        </DashboardLayout>
    )
}

export default BuyerWatchlist
