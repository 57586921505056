import React, { useState, useEffect } from 'react'

import './postListingPageTwo.css'
import RadioBox from '../../../components/atoms/RadioBox'
import CustomInput from '../../../components/atoms/Inputs/CustomInput'
import CustomSelect from '../../../components/atoms/Inputs/CustomSelect'
import CheckBox from '../../../components/atoms/CheckBox'
import PrimaryButton from '../../../components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import { product2Schema } from '../../../Utils/Validatior'
import csc from 'country-state-city'
import { useHistory, useLocation, Prompt } from 'react-router-dom'
import { mapData } from '../../../common/components'
import { useSnackbar } from 'notistack'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { dateTimeFormat, transmissionType, objectFilter } from '../../../Utils/Constants'
import { isAuthenticated } from '../../../Utils/Auth'
import { request } from '../../../Utils/Request'
import jwtDecode from 'jwt-decode'
import CustomTextArea from '../../../components/atoms/Inputs/CustomTextArea'
import { getMakeById, getModelById } from '../../../Utils/makemodel'
import { callNumber } from '../../../Utils/common'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import imageCompression from 'browser-image-compression'

const postListingTwo = (props) => {
    const { setScreenChange } = props
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const location = useLocation()
    // console.log(jwtDecode(isAuthenticated()))
    const [trigger, setTrigger] = useState(true)
    const [isBlocking, setIsBlocking] = useState(true)
    const [details, setDetails] = useState({
        racecar: 2,
        vin: '',
        ownership: '',
        accurate: 2,
        current_miles: '',
        yourself_miles: '',
        original: 1,
        engine_size: '',
        titled: 1,
        titled_name: '',
        issues: '',
        records: 1,
        repaint: '',
        reserve: 1,
        rust: '',
        rprice: '',
        sprice: 1000,
        service_done_by: '',
        hear_about_us: '',
        additional_details: '',
        transmission: '',
        description: '',
        // state: '',
        start_date: '',
        end_date: '',
        terms: false,
        final_bid: false,
        userDetails: jwtDecode(isAuthenticated()),
        exterior: '',
        interior: '',
        body_style: '',
        drive_train: '',
        title_status: '',
    })
    const [step1Data, setStep1Data] = useState({})
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        if (location.state) {
            if (location.state.step1Data) {
                setStep1Data(location.state.step1Data)
            }
            if (location.state.step2Data) {
                setDetails(location.state.step2Data)
            }
        }
        window.scrollTo(0, 0)
        window.onbeforeunload = () => true
    }, [])
    const formik = useFormik({
        initialValues: details,
        enableReinitialize: trigger,
        validationSchema: product2Schema,
        onSubmit: (values) => {
            postProduct(values)
        },
    })
    const radioValue = [
        { id: 1, description: 'Yes' },
        { id: 2, description: 'No' },
    ]
    const body_style = ['Convertible', 'Coupe', 'Sedan', 'SUV', 'Other']
    const transmission = ['Automatic', 'Manual', 'Other']
    const drive_train = [
        'All Wheel Drive',
        'Four Wheel Drive',
        'Front Wheel Drive',
        'Rear Wheel Drive',
        'Other',
    ]
    const title_status = ['Clean', 'Salvage', 'Rebuild']
    const termsAndCondition = () => {
        return (
            <p className="m-0 agree-condition">
                I agree to the <span className="terms">Terms & Conditions</span>
            </p>
        )
    }
    const finalBid = () => {
        return (
            <p className="m-0 agree-condition">
                I understand that if the final bid for my vehicle is below the reserve, the auction
                may choose (at its sole discretion) to make up the difference. In this case the
                vehicle will appear as sold at the below-reserve price and the auction will pay me
                the difference between the high bid and the reserve once the transaction is
                complete.
            </p>
        )
    }

    const homePageTop = () => {
        window.scrollTo({ top: 0, right: 0, behavior: 'smooth' })
    }
    const handleChange = (e) => {
        setTrigger(false)
        formik.setFieldValue(e.target.name, e.target.value)
    }
    const handleChangeRadio = (e) => {
        setTrigger(false)
        formik.setFieldValue(e.target.name, parseInt(e.target.value))
        setDetails({
            ...details,
            [e.target.name]: parseInt(e.target.value),
        })
    }
    const prev = () => {
        location.state = {
            step1Data: location.state.step1Data,
            step2Data: formik.values,
        }
        setScreenChange(0)
    }
    const compressionOptions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: false,
    }
    const compressImage = async (value) => {
        var img = await imageCompression(value, compressionOptions)
        return img
    }
    const postProduct = async (val) => {
        try {
            if (!details.terms || !details.final_bid) {
                return enqueueSnackbar('Kindly accept terms & conditions!', {
                    variant: 'warning',
                })
            }

            setLoader(true)
            const noteworthy = objectFilter(step1Data, ([key]) => key.includes('noteworthy'))
            const modification = objectFilter(step1Data, ([key]) => key.includes('modification'))
            const issue = objectFilter(step1Data, ([key]) => key.includes('issue'))
            const service = objectFilter(step1Data, ([key]) => key.includes('service'))
            const additional = objectFilter(step1Data, ([key]) => key.includes('additional'))
            const url = objectFilter(step1Data, ([key]) => key.includes('url'))
            const extraFields = [noteworthy, modification, issue, service, additional, url]
            const newFields = {
                exterior: val.exterior,
                interior: val.interior,
                body_style: val.body_style,
                drive_train: val.drive_train,
                title_status: val.title_status,
            }
            const data = {
                ...step1Data,
                ...val,
                extraFields: JSON.stringify(extraFields),
                newFields: JSON.stringify(newFields),
            }
            const formData = new FormData()
            formData.set('auction', 1)
            formData.set('auctiontype', 'auction')
            formData.set('rprice', data.reserve === 1 ? data.rprice : 0)
            formData.set('sprice', data.sprice)
            formData.set('startdate', data.start_date)
            formData.set('enddate', new Date(data.end_date).toString())
            formData.set(
                'protitle',
                data.make === 'Other' ? data.other : getMakeById(data.make).value,
            )
            formData.set(
                'category_id',
                data.model === 'Other' ? '-' : getModelById(data.model).value,
            )
            formData.set('radcondition', data.rust)
            formData.set('location', csc.getCountryById(data.country).name)
            formData.set('state', csc.getStateById(data.state).name)
            formData.set('city', data.city)
            formData.set('customfield1', data.listed)
            formData.set('customfield2', data.denied)
            formData.set('customfield3', data.dealer === 1 ? data.dealership : 0)
            formData.set('customfield4', data.racecar)
            formData.set('customfield5', data.original)
            formData.set('customfield6', data.engine_size)
            formData.set('customfield7', data.repaint)
            formData.set('customfield8', data.transmission)
            formData.set('customfield9', data.service_done_by)
            formData.set('contenthead1', data.denied === 2 ? 0 : data.reconsidering)
            formData.set('contenthead2', data.extraFields)
            formData.set('contenthead3', data.current_miles)
            formData.set('contenthead4', data.yourself_miles)
            formData.set(
                'contenthead5',
                data.titled === 2 ? data.titled_name : details.userDetails.first_name,
            )
            formData.set('contenthead6', data.hear_about_us)
            formData.set('norefund', data.newFields)
            formData.set('manufacturer', data.ownership)
            formData.set('modelnumber', data.vin)
            formData.set('subcategory', data.additional_details)
            formData.set('description', data.description)
            formData.set('tags', data.make === 'Other' ? '-' : data.year)
            formData.set('auctionnotes', data.issues)
            formData.set('sell_location', data.records)
            formData.set('qcedby', data.sold_by)
            formData.set('market_status', 'draft')
            formData.set('upccode', '1')

            // var asyncData = data.images.map((value) => compressImage(value))
            // var resultData = await Promise.all(asyncData)
            data.product_image.map((value) => formData.append('product_image', value))

            data.document.map((value) => formData.append('work_loc', value))
            const res = await request('post', '/sellerpostprojectlive', formData, true)
            if (res.data.status) {
                setIsBlocking(false)
                window.onbeforeunload = undefined
                setLoader(false)
                enqueueSnackbar('Your Vehicle Has Been Submitted For Consideration!', {
                    variant: 'success',
                })
                return history.push('/dashboard/seller/myauction')
            }
            setLoader(false)
            enqueueSnackbar('Try again later!', {
                variant: 'error',
            })
            homePageTop()
        } catch (error) {
            setLoader(false)
            enqueueSnackbar('Try again later!', {
                variant: 'error',
            })
            homePageTop()
            console.log(error)
        }
    }
    // console.log(location)
    return (
        <form className="product-list-page-two" onSubmit={formik.handleSubmit}>
            <Prompt
                when={isBlocking}
                message="Your work is not saved! Are you sure you want to leave ?"
            />
            <div className="row product-list-page-two-row">
                {/* <div className="product-list-col-one"> */}
                <div className="col-6 input-boxes">
                    <p>Is this vehicle a racecar ?</p>
                    <RadioBox
                        items={radioValue}
                        name="racecar"
                        value={details.racecar}
                        onChange={handleChangeRadio}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomSelect
                        label="Length of Ownership"
                        labelClass="mls-added-label"
                        name="ownership"
                        id="ownership"
                        value={formik.values.ownership}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.ownership && formik.errors.ownership
                                ? formik.errors.ownership
                                : null
                        }
                        error={formik.touched.ownership && formik.errors.ownership}
                    >
                        <option value="">Select a year</option>
                        <option value="Less Than 1 Year">Less Than 1 Year</option>
                        {new Array(99).fill(null).map((_, index) => (
                            <option
                                value={`${index + 1} ${index + 1 === 1 ? 'Year' : 'Years'}`}
                                key={index}
                            >
                                {`${index + 1} ${index + 1 === 1 ? 'Year' : 'Years'}`}
                            </option>
                        ))}
                    </CustomSelect>
                </div>
                <div className=" col-6 input-boxes">
                    <CustomInput
                        label="What is the Vehicle Identification Number (VIN#) / Registration Number for Boat, Airplane, RV"
                        placeholder="What is the Vehicle Identification Number (VIN#) / Registration Number for Boat, Airplane, RV"
                        labelClass="vch-id-label"
                        name="vin"
                        id="vin"
                        value={formik.values.vin}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.vin && formik.errors.vin ? formik.errors.vin : null
                        }
                        error={formik.touched.vin && formik.errors.vin}
                    />
                </div>
                <div className=" col-6 input-boxes">
                    <CustomInput
                        label="Exterior Color"
                        placeholder="Exterior Color"
                        labelClass="ext-id-label"
                        name="exterior"
                        id="exterior"
                        value={formik.values.exterior}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.exterior && formik.errors.exterior
                                ? formik.errors.exterior
                                : null
                        }
                        error={formik.touched.exterior && formik.errors.exterior}
                    />
                </div>
                <div className=" col-6 input-boxes">
                    <CustomInput
                        label="Interior Color"
                        placeholder="Interior Color"
                        labelClass="ext-id-label"
                        name="interior"
                        id="interior"
                        value={formik.values.interior}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.interior && formik.errors.interior
                                ? formik.errors.interior
                                : null
                        }
                        error={formik.touched.interior && formik.errors.interior}
                    />
                </div>
                {/* <div className="col-6 input-boxes">
                    <p>Is this number accurate ?</p>
                    <RadioBox
                        items={radioValue}
                        name="accurate"
                        value={details.accurate}
                        onChange={handleChangeRadio}
                    />
                </div> */}

                <div className="col-6 input-boxes">
                    <CustomInput
                        label="Current miles/hours on vehicle"
                        placeholder="Current miles/hours on vehicle"
                        labelClass="curnt-mile-label"
                        name="current_miles"
                        id="current_miles"
                        value={
                            callNumber(formik.values.current_miles.split(',').join('')) == 'NaN'
                                ? 0
                                : callNumber(formik.values.current_miles.split(',').join(''))
                        }
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.current_miles && formik.errors.current_miles
                                ? formik.errors.current_miles
                                : null
                        }
                        error={formik.touched.current_miles && formik.errors.current_miles}
                    />
                </div>

                <div className="col-6 input-boxes">
                    <CustomInput
                        label="How many miles/hours have you added yourself ?"
                        placeholder="How many miles/hours have you added yourself ?"
                        labelClass="mls-added-label"
                        name="yourself_miles"
                        id="yourself_miles"
                        value={
                            callNumber(formik.values.yourself_miles.split(',').join('')) == 'NaN'
                                ? 0
                                : callNumber(formik.values.yourself_miles.split(',').join(''))
                        }
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.yourself_miles && formik.errors.yourself_miles
                                ? formik.errors.yourself_miles
                                : null
                        }
                        error={formik.touched.yourself_miles && formik.errors.yourself_miles}
                    />
                </div>

                <div className="col-6 input-boxes">
                    <p>Is Engine original ?</p>
                    <RadioBox
                        items={radioValue}
                        name="original"
                        value={details.original}
                        onChange={handleChangeRadio}
                    />
                </div>

                <div className="col-6 input-boxes">
                    <CustomSelect
                        label="Transmission Type"
                        labelClass="mls-added-label"
                        name="transmission"
                        id="transmission"
                        value={formik.values.transmission}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.transmission && formik.errors.transmission
                                ? formik.errors.transmission
                                : null
                        }
                        error={formik.touched.transmission && formik.errors.transmission}
                    >
                        <option value="">Select a transmission type</option>
                        {transmission.map((ele) => (
                            <option value={ele} key={ele}>
                                {ele}
                            </option>
                        ))}
                    </CustomSelect>
                </div>
                <div className="col-6 input-boxes">
                    <CustomSelect
                        label="Body Style"
                        labelClass="mls-added-label"
                        name="body_style"
                        id="body_style"
                        value={formik.values.body_style}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.body_style && formik.errors.body_style
                                ? formik.errors.body_style
                                : null
                        }
                        error={formik.touched.body_style && formik.errors.body_style}
                    >
                        <option value="">Select a Body Style</option>
                        {body_style.map((ele) => (
                            <option value={ele} key={ele}>
                                {ele}
                            </option>
                        ))}
                    </CustomSelect>
                </div>
                <div className="col-6 input-boxes">
                    <CustomSelect
                        label="Title Status"
                        labelClass="mls-added-label"
                        name="title_status"
                        id="title_status"
                        value={formik.values.title_status}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.title_status && formik.errors.title_status
                                ? formik.errors.title_status
                                : null
                        }
                        error={formik.touched.title_status && formik.errors.title_status}
                    >
                        <option value="">Select a Title Status</option>
                        {title_status.map((ele) => (
                            <option value={ele} key={ele}>
                                {ele}
                            </option>
                        ))}
                    </CustomSelect>
                </div>
                <div className="col-6 input-boxes">
                    <CustomSelect
                        label="Drivetrain"
                        labelClass="mls-added-label"
                        name="drive_train"
                        id="drive_train"
                        value={formik.values.drive_train}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.drive_train && formik.errors.drive_train
                                ? formik.errors.drive_train
                                : null
                        }
                        error={formik.touched.drive_train && formik.errors.drive_train}
                    >
                        <option value="">Select a Drivetrain</option>
                        {drive_train.map((ele) => (
                            <option value={ele} key={ele}>
                                {ele}
                            </option>
                        ))}
                    </CustomSelect>
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="What size engine ?"
                        placeholder="What size engine ?"
                        labelClass="size-eng-label"
                        name="engine_size"
                        id="engine_size"
                        value={formik.values.engine_size}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.engine_size && formik.errors.engine_size
                                ? formik.errors.engine_size
                                : null
                        }
                        error={formik.touched.engine_size && formik.errors.engine_size}
                    />
                </div>

                {/* <div className="col-6 input-boxes">
                    <CustomSelect
                        label="What State, Provence, or Territory Is Vehicle Located In ?"
                        labelClass="mls-added-label"
                        name="state"
                        id="state"
                        value={formik.values.state}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.state && formik.errors.state ? formik.errors.state : null
                        }
                        error={formik.touched.state && formik.errors.state}
                    >
                        <option value="">Select a state</option>
                        {csc.getStatesOfCountry(step1Data.country).map((ele) => (
                            <option value={ele.id} key={ele.id}>
                                {ele.name}
                            </option>
                        ))}
                    </CustomSelect>
                </div> */}
                <div className="col-6 input-boxes"></div>
                <div className="col-6 input-boxes">
                    <p>Is the Vehicle titled in your name ?</p>
                    <RadioBox
                        items={radioValue}
                        name="titled"
                        value={details.titled}
                        onChange={handleChangeRadio}
                    />
                </div>
                {/* <div className="col-6 input-boxes">
                    <CustomInput
                        label="What modifications/improvements have been made to vehicle?"
                        placeholder="What modifications/improvements have been made to vehicle?"
                        labelClass="mls-added-label"
                    />
                </div> */}
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="What Is The Name On The Title/Registration ?"
                        placeholder="What Is The Name On The Title/Registration ?"
                        labelClass="name-titled-label"
                        name="titled_name"
                        id="titled_name"
                        value={formik.values.titled_name}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.titled_name && formik.errors.titled_name
                                ? formik.errors.titled_name
                                : null
                        }
                        error={formik.touched.titled_name && formik.errors.titled_name}
                        disabled={details.titled === 1}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="What Issues should we know about the vehicle?"
                        placeholder="What Issues should we know about the vehicle?"
                        labelClass="mls-added-label"
                        name="issues"
                        id="issues"
                        value={formik.values.issues}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.issues && formik.errors.issues
                                ? formik.errors.issues
                                : null
                        }
                        error={formik.touched.issues && formik.errors.issues}
                    />
                </div>
                {/* <div className="col-6 input-boxes">
                    <CustomInput
                        label="Has vehicle has history of modifications?"
                        placeholder="Has vehicle has history of modifications?"
                        labelClass=""
                    />
                </div> */}
                <div className="col-6 input-boxes">
                    <p>Do you have Service Records History?</p>
                    <RadioBox
                        items={radioValue}
                        name="records"
                        value={details.records}
                        onChange={handleChangeRadio}
                    />
                </div>
                <div className="col-3 input-boxes">
                    <p>Would you like a reserve price?</p>
                    <RadioBox
                        items={radioValue}
                        name="reserve"
                        value={details.reserve}
                        onChange={handleChangeRadio}
                    />
                </div>
                <div className="col-3 input-boxes">
                    <CustomInput
                        label="What price?"
                        placeholder="What price?"
                        labelClass="mls-added-label"
                        name="rprice"
                        id="rprice"
                        value={formik.values.rprice}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.rprice && formik.errors.rprice
                                ? formik.errors.rprice
                                : null
                        }
                        error={formik.touched.rprice && formik.errors.rprice}
                        disabled={details.reserve === 2}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="Start Price"
                        placeholder="Start Price"
                        labelClass="mls-added-label"
                        name="sprice"
                        id="sprice"
                        value={formik.values.sprice}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.sprice && formik.errors.sprice
                                ? formik.errors.sprice
                                : null
                        }
                        error={formik.touched.sprice && formik.errors.sprice}
                        disabled={true}
                    />
                </div>
                <div className="col-6 input-boxes">
                    Requested Start Date
                    <br />
                    <DatePicker
                        name="start_date"
                        id="start_date"
                        showTimeSelect
                        showYearDropdown
                        showMonthDropdown
                        dateFormat={'MMMM d, yyyy h:mm a'}
                        selected={formik.values.start_date ? formik.values.start_date : new Date()}
                        onChange={(date) => {
                            formik.setFieldValue('start_date', date)
                            var start = new Date(date)
                            formik.setFieldValue('end_date', start.setDate(start.getDate() + 7))
                            // console.log(new Date(start.setDate(start.getDate() + 7)).toString())
                        }}
                        minDate={new Date()}
                        autoComplete="off"
                        value={formik.values.start_date}
                        timeIntervals={1}
                    />
                    <br />
                    <small style={{ color: 'red' }}>
                        {formik.errors.start_date ? formik.errors.start_date : null}
                    </small>
                </div>
                <div className="col-6 input-boxes">
                    Requested End Date
                    <br />
                    <DatePicker
                        name="end_date"
                        id="end_date"
                        showTimeSelect
                        showYearDropdown
                        showMonthDropdown
                        dateFormat={'MMMM d, yyyy h:mm a'}
                        selected={formik.values.end_date ? formik.values.end_date : new Date()}
                        onChange={(date) => formik.setFieldValue('end_date', date)}
                        minDate={formik.values.start_date}
                        autoComplete="off"
                        value={formik.values.end_date}
                        timeIntervals={1}
                        disabled
                    />
                    <br />
                    <small style={{ color: 'red' }}>
                        {formik.errors.end_date ? formik.errors.end_date : null}
                    </small>
                </div>
                <div className="col-6 input-boxes mt-2">
                    <CustomInput
                        label="Is Rust present anywhere in/on vehicle? If so, where?"
                        placeholder="Is Rust present anywhere in/on vehicle? If so, where?"
                        labelClass="rust-prst-label"
                        name="rust"
                        id="rust"
                        value={formik.values.rust}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.rust && formik.errors.rust ? formik.errors.rust : null
                        }
                        error={formik.touched.rust && formik.errors.rust}
                    />
                </div>
                <div className="col-6 input-boxes mt-2">
                    <CustomInput
                        label="Has the vehicle been repainted? When?"
                        placeholder="Has the vehicle been repainted? When?"
                        labelClass="repaint-label"
                        name="repaint"
                        id="repaint"
                        value={formik.values.repaint}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.repaint && formik.errors.repaint
                                ? formik.errors.repaint
                                : null
                        }
                        error={formik.touched.repaint && formik.errors.repaint}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="When was the service done and by whom?"
                        placeholder="When was the service done and by whom?"
                        labelClass="srvc-done-label"
                        name="service_done_by"
                        id="service_done_by"
                        value={formik.values.service_done_by}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.service_done_by && formik.errors.service_done_by
                                ? formik.errors.service_done_by
                                : null
                        }
                        error={formik.touched.service_done_by && formik.errors.service_done_by}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="Where did you hear about us?"
                        placeholder="Where did you hear about us?"
                        labelClass="mls-added-label"
                        name="hear_about_us"
                        id="hear_about_us"
                        value={formik.values.hear_about_us}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.hear_about_us && formik.errors.hear_about_us
                                ? formik.errors.hear_about_us
                                : null
                        }
                        error={formik.touched.hear_about_us && formik.errors.hear_about_us}
                    />
                </div>
                <div className="col-6 input-boxes">
                    <CustomInput
                        label="Any Additional Details you would like us to know ?"
                        placeholder="Any Additional Details you would like us to know ?"
                        labelClass="add-dtls-label"
                        name="additional_details"
                        id="additional_details"
                        value={formik.values.additional_details}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.additional_details && formik.errors.additional_details
                                ? formik.errors.additional_details
                                : null
                        }
                        error={
                            formik.touched.additional_details && formik.errors.additional_details
                        }
                    />
                </div>
                <div className="col-12 input-boxes">
                    <span>Please provide a description below that tells the story of your car</span>
                    <ReactQuill
                        value={formik.values.description}
                        onChange={(val) => formik.setFieldValue('description', val)}
                    />
                    {/* <CustomTextArea
                        id="description"
                        value={formik.values.description}
                        name="description"
                        shrink={formik.values.description && true}
                        onBlur={formik.handleBlur}
                        onChange={handleChange}
                        label="Description"
                        rowsMax={8}
                        row={8}
                        placeholder="Description"
                        type="text-area"
                        error={formik.touched.description && formik.errors.description}
                        helperText={
                            formik.errors.description &&
                            formik.touched.description &&
                            formik.errors.description
                        }
                        inputClassName="desc-postlist"
                    /> */}
                    <small style={{ color: 'red' }}>
                        {formik.errors.description ? formik.errors.description : null}
                    </small>
                </div>
                <div className="col-12">
                    <CheckBox
                        label={termsAndCondition()}
                        value={details.terms}
                        checked={details.terms}
                        onChange={() =>
                            setDetails({
                                ...details,
                                terms: !details.terms,
                            })
                        }
                    />
                </div>
                <div className="col-12">
                    <CheckBox
                        label={finalBid()}
                        value={details.final_bid}
                        checked={details.final_bid}
                        onChange={() =>
                            setDetails({
                                ...details,
                                final_bid: !details.final_bid,
                            })
                        }
                    />
                </div>
            </div>
            <div className="registration-submit row">
                <div className="col-6">
                    <PrimaryButton
                        type="button"
                        onClick={prev}
                        label={'Prev'}
                        customClassName="product-post-next"
                        disabled={loader}
                    />
                </div>
                <div className="col-6">
                    <PrimaryButton
                        type="submit"
                        label={loader ? 'Loading...' : 'Submit'}
                        disabled={loader}
                    />
                </div>
            </div>
        </form>
    )
}

export default postListingTwo
