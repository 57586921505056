import React, { useState, useEffect } from 'react'

import './FavoriteCheckbox.css'

const FavoriteCheckbox = (props) => {
    const [check, setCheck] = useState(props.checked)

    const onChecked = () => {
        setCheck(!check)
        props.onChecked(check, props.productId)
    }

    // useEffect(() => {
    //     props.onChecked(check, props.productId)
    // }, [check])

    return (
        <>
            <div className="favoriteCheck d-flex justify-content-center align-items-center">
                <input
                    id={props.productId}
                    type="checkbox"
                    onChange={() => onChecked(props.productId, check)}
                    checked={check}
                />
                <label htmlFor={props.productId}>
                    <span className="material-icons">favorite</span>
                </label>
            </div>
        </>
    )
}
export default FavoriteCheckbox
