import React, { useState } from 'react'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import './footer.css'
import { Button, Drawer, Tooltip } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import InstagramIcon from '@material-ui/icons/Instagram'
import Swal from 'sweetalert2'
import jsonp from 'jsonp'
import queryString from 'query-string'
const Footer = () => {
    let getLoginUser = JSON.parse(localStorage.getItem('coachUser'))
    const location = useLocation()
    const homePageTop = () => {
        window.scrollTo({ top: 0, right: 0, behavior: 'smooth' })
    }
    const [state, setState] = useState({
        bottom: false,
    })
    const [inpData, setinpData] = useState('')
    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return Swal.fire({
                    title: 'Please enter a valid email!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1500,
                })
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//coachandgavel.us6.list-manage.com/subscribe/post-json?u=ac688580f769829bc4155538a&id=88f833db23&${queryString.stringify(
                    data,
                )}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return console.log('display', err)
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return Swal.fire({
                            title: data.msg,
                            icon: 'success',
                            showCancelButton: false,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                    if (data.result === 'error') {
                        return Swal.fire({
                            title: data.msg.split('<')[0],
                            icon: 'warning',
                            showCancelButton: false,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                },
            )
        }
        setinpData('')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    return (
        <div className="footer">
            <div className="customContainer">
                {location.pathname.includes('/') && (
                    <>
                        {/* <Tooltip title="Need A Shipping Quote ?"> */}
                        <Button className="getEstimateBtn" onClick={toggleDrawer('bottom', true)}>
                            {/* <span className="material-icons">request_quote</span> */}
                            <small>
                                Need a <br /> shipping quote?
                            </small>
                            <img src="/assets/images/uship2.PNG" />
                        </Button>
                        {/* </Tooltip> */}
                        <Drawer
                            anchor={'bottom'}
                            open={state['bottom']}
                            onClose={toggleDrawer('bottom', false)}
                            className="getEstimateDrawer"
                        >
                            {/* <div className="estCnt">
                    <h3>Get Delivery Estimates.</h3>
                    <h6>Enter your start and end locations and we will get an estimate for you.</h6>
                    <CustomInput
                        label="From"
                        type="text"
                        inputClassName="login-password"
                        labelClass=""
                        placeholder="From location"
                    />
                    <CustomInput
                        label="To"
                        type="text"
                        inputClassName="login-password"
                        labelClass=""
                        placeholder="To location"
                    />
                    <PrimaryButton label="Submit" />
                </div> */}
                            <div style={{ fontFamily: 'arial', textAlign: 'center' }}>
                                <iframe
                                    frameBorder="0"
                                    target="_self"
                                    src="https://www.uship.com/widget/widget_quote.aspx?widgetstyle=vertical&amp;color=yellow&amp;category=4&amp;subcategory=79&amp;z1=&amp;z2=&amp;country2=US&amp;country1=US&amp;referid=8641787&amp;embedurl=file%3A///D%3A/widget.html&amp;widgettype=quote_widget_1_0&amp;siteid=1&amp;showemailaddress=false&amp;defaultweight=false"
                                    height="410"
                                    width="225"
                                    scrolling="no"
                                ></iframe>
                                {/* <ScriptTag
                        isHydrating={true}
                        type="text/javascript"
                        src="https://www.uship.com/widget/getquotewidget.js?widgetstyle=vertical&color=yellow&category=4&subcategory=79&z1=&z2=&country2=US&country1=US&referid=8641787&widgettype=quote_widget_1_0&siteid=1&showemailaddress=false"
                    /> */}
                                {/* <a style="text-decoration:none; color:#BAA0A0; font-size:11px;">
                        uShip Auto Transport
                    </a> */}
                            </div>
                        </Drawer>
                    </>
                )}
                <div className="footer-content">
                    <div className="footer-logo">
                        <img
                            src="/assets/svg/gavel-logo.svg"
                            alt="footerLogo"
                            className="footer-logo-img"
                        />
                    </div>
                    <div className="footSub">
                        <div className="footerRgt">
                            <div className="quick-links light-white">
                                <a
                                    style={{ color: 'white' }}
                                    href={window.location.href + '#about'}
                                >
                                    About C&G
                                </a>
                                <ul className="pl-0">
                                    {/* <li>Title goes here</li>
                            <li>Title goes here</li>
                            <li>Title goes here</li> */}
                                </ul>
                            </div>
                            <div className="social-media light-white">
                                <p>Follow Us</p>
                                <ul className="pl-0">
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={'https://www.instagram.com/coachandgavel/'}
                                        style={{ color: 'white' }}
                                    >
                                        <InstagramIcon />
                                    </a>
                                    {/* <li>Title goes here</li>
                            <li>Title goes here</li> */}
                                </ul>
                            </div>
                            <div className="contact-us light-white">
                                <p>Contact Us</p>
                                <ul className="pl-0">
                                    <a
                                        target="_blank"
                                        style={{ color: 'white' }}
                                        rel="noreferrer"
                                        href="mailto:admin@coachandgavel.com"
                                    >
                                        admin@coachandgavel.com
                                    </a>
                                </ul>
                            </div>
                        </div>
                        <div className="searchSpace">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => {
                                    setinpData(e.target.value)
                                }}
                                placeholder="enter your email"
                                aria-label="Search"
                            />
                            <button className="btn primButton" onClick={subscribe} type="submit">
                                Subscribe
                            </button>
                        </div>
                    </div>
                    <div className="up-arrow" onClick={homePageTop}>
                        <ArrowUpwardIcon className="up-arrow-icon" />
                    </div>
                </div>
                <hr className="footer-line" />
                <div className="copy-right">
                    <p className="light-white">© Copyright 2020 Coach & Gavel, LLC</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
