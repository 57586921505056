import io from 'socket.io-client'

const socket = io(`${process.env.REACT_APP_BASE_URL}/`, { transports: ['websocket'] })

socket.on('connect', function () {
    console.log('Socket Connected')
})

const bidAddtime = (cb) => {
    socket.on('bidAddtime', (data) => {
        cb(data)
        console.log('socketdata', data)
    })
}

const realClosedUpdates = (cb) => {
    socket.on('realclosedupdates', (data) => {
        cb(data)
    })
}
const chatmessages = (cb) => {
    socket.on('chatmessages', (data) => {
        cb(data)
    })
}
const refreshbuyer = (cb) => {
    socket.on('refreshbuyer', (data) => {
        cb(data)
    })
}
const refreshbuyerOff = () => {
    socket.off('refreshbuyer')
}
const bid_extended = (cb) => {
    socket.on('bid_extended', (data) => {
        cb(data)
    })
}
const bid_extendedOff = () => {
    socket.off('bid_extended')
}
socket.on('disconnect', function () {
    console.log('Socket Disconnected')
})
export {
    bidAddtime,
    realClosedUpdates,
    refreshbuyer,
    socket,
    refreshbuyerOff,
    bid_extended,
    bid_extendedOff,
    chatmessages,
}
