import React, { useState, useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'

import './dashboard.css'
import DashboardLayout from '../../components/templates/DashboardLayout/DashboardLayout'
import Cards from '../../components/molecules/Card'
import Popup from '../../components/molecules/Popup'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import { sellerPaymentCardValidation } from '../../Utils/Validatior'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 500,
    },
    tabPanel: {
        border: '1px solid #233e7c',
        backgroundColor: '#FFFFFF',
        color: 'var(--progressLine)',
        textTransform: 'capitalize',
    },
})

const SellerPayment = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [cardInitial, setCardInitial] = useState({
        card_number: '',
        card_name: '',
        cvv: '',
        expiry_date: '',
    })
    const classes = useStyles()
    const dispatch = useDispatch()
    const cardsInfo = useSelector((state) => state.SellerDashboard.sellerCards)
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const [cardAdd, setCardAdd] = useState(false)
    const formik = useFormik({
        initialValues: cardInitial,
        enableReinitialize: true,
        validationSchema: sellerPaymentCardValidation,
        onSubmit: async (values) => {
            try {
                const data = {
                    cardNumber: values.card_number.split(' ').join(''),
                    card_name: values.card_name,
                    cvv: values.cvv,
                    expiryMonth: values.expiry_date.split('/')[0],
                    expiryYear: `20${values.expiry_date.split('/')[1]}`,
                    mode: process.env.REACT_APP_MODE,
                    amount: '0.50',
                    isSave: true,
                    onlySaveCard: true,
                }

                const res = await request('post', apiEndPoint.payAmountStripe, data, true, 2)

                if (res.data.success) {
                    enqueueSnackbar(res.data.message, { variant: 'success' })
                    viewAllCard()
                    setCardAdd(false)
                    setCardInitial({
                        card_number: '',
                        card_name: '',
                        cvv: '',
                        expiry_date: '',
                    })
                }
            } catch (error) {
                console.log(error)
            }
        },
    })

    const modePaymt = [
        {
            id: 1,
            value: 'creditCard',
            name: 'Credit Cart',
        },
        {
            id: 2,
            value: 'netBanking',
            name: 'Net Banking',
        },
        {
            id: 3,
            value: 'debitCard',
            name: 'Debit Cart',
        },
    ]

    const transaction = [
        {
            id: 1,
            auctionName: 'Ford Dss300 Mustang',
            transId: 'MEDS-4523422456ds',
            transOn: '21/01/2021',
            amt: '4858',
        },
        {
            id: 2,
            auctionName: 'Ford Dss300 Mustang',
            transId: 'MEDS-4523422456ds',
            transOn: '21/01/2021',
            amt: '4858',
        },
        {
            id: 3,
            auctionName: 'Ford Dss300 Mustang',
            transId: 'MEDS-4523422456ds',
            transOn: '21/01/2021',
            amt: '4858',
        },
        {
            id: 4,
            auctionName: 'Ford Dss300 Mustang',
            transId: 'MEDS-4523422456ds',
            transOn: '21/01/2021',
            amt: '4858',
        },

        {
            id: 5,
            auctionName: 'Ford Dss300 Mustang',
            transId: 'MEDS-4523422456ds',
            transOn: '21/01/2021',
            amt: '4858',
        },
        {
            id: 6,
            auctionName: 'Ford Dss300 Mustang',
            transId: 'MEDS-4523422456ds',
            transOn: '21/01/2021',
            amt: '4858',
        },
        {
            id: 7,
            auctionName: 'Ford Dss300 Mustang',
            transId: 'MEDS-4523422456ds',
            transOn: '21/01/2021',
            amt: '4858',
        },
    ]

    const handleAddCard = () => {
        setCardAdd(true)
    }

    const handleClosePopup = () => {
        setCardAdd(false)
    }

    function cardNumberFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }
        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }

    function dateFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{2,4}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 2) {
            parts.push(match.substring(i, i + 2))
        }
        if (parts.length) {
            return parts.join('/')
        } else {
            return value
        }
    }

    const viewAllCard = async () => {
        try {
            const data = {
                mode: 'sandbox',
            }
            const res = await request('post', apiEndPoint.viewAllCard, data, true, 2)

            if (res.data.success) {
                dispatch({
                    type: 'SELLER_CARD',
                    payload: res.data.data,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        viewAllCard()
    }, [])
    return (
        <DashboardLayout userType="seller">
            <Tabs
                className={`${classes.root} buyer-payment`}
                value={value}
                onChange={handleChange}
                centered
                aria-label="simple tabs example"
            >
                <Tab label="Transactions" {...a11yProps(0)} className={classes.tabPanel} />
                <Tab label="Saved Cards" {...a11yProps(1)} className={classes.tabPanel} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Sl.No</th>
                                <th scope="col">Auction Name</th>
                                <th scope="col">Transaction Id</th>
                                <th scope="col">Transacted On</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transaction.map((ele) => (
                                <tr key={ele.id}>
                                    <td>{ele.id}</td>
                                    <td>{ele.auctionName}</td>
                                    <td>{ele.transId}</td>
                                    <td>{ele.transOn}</td>
                                    <td>$ {ele.amt}</td>
                                    <td className="auction-view">View Invoice</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className="saved-cards">
                    <div className="new-card" onClick={handleAddCard}>
                        <img src={'/assets/svg/card-icon.svg'} alt="new card" />
                        <p> ADD A NEW CARD</p>
                        <img src={'/assets/svg/card-types.svg'} alt="new card" />
                    </div>
                    {cardsInfo && typeof cardsInfo !== 'undefined' && cardsInfo.length
                        ? cardsInfo.map((ele) => (
                              <>
                                  <Cards
                                      name="john doe"
                                      cardNum={`xxxx - xxxx - xxxx - ${ele.last4}`}
                                      month={ele.exp_month}
                                      year={ele.exp_year}
                                  />
                              </>
                          ))
                        : null}
                </div>
            </TabPanel>

            <Popup
                open={cardAdd}
                title="Add Card"
                className="paymentCardPopup"
                width="sm"
                handleClose={handleClosePopup}
                closeButtonClass="card-close"
                fullWidthValue={true}
            >
                <div className="saved-card-dtls">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row pymt-dtls-row">
                            <div className="col-12 input-left">
                                <CustomInput
                                    placeholder="Name on Card"
                                    label="Name on Card"
                                    type="text"
                                    value={formik.values.card_name}
                                    name="card_name"
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.card_name ? (
                                    <p className="err">{formik.errors.card_name}</p>
                                ) : null}
                            </div>
                            <div className="col-12 input-left">
                                <CustomInput
                                    placeholder="Card Number"
                                    label="Card Number"
                                    type="text"
                                    name="card_number"
                                    value={formik.values.card_number}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'card_number',
                                            cardNumberFormat(e.target.value),
                                        )
                                    }
                                />
                                {formik.errors.card_number ? (
                                    <p className="err">{formik.errors.card_number}</p>
                                ) : null}
                            </div>
                            <div className="col-6 input-left">
                                <CustomInput
                                    placeholder="Expiry"
                                    label="Expiry"
                                    type="text"
                                    name="expiry_date"
                                    value={formik.values.expiry_date}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'expiry_date',
                                            dateFormat(e.target.value),
                                        )
                                    }
                                />
                                {formik.errors.expiry_date ? (
                                    <p className="err">{formik.errors.expiry_date}</p>
                                ) : null}
                            </div>
                            <div className="col-6 input-left">
                                <CustomInput
                                    placeholder="Cvv"
                                    value={formik.cvv}
                                    label="Cvv"
                                    type="text"
                                    name="cvv"
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.cvv ? (
                                    <p className="err">{formik.errors.cvv}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="paymt-save">
                            <PrimaryButton label="Save" type="submit" />
                        </div>
                    </form>
                </div>
            </Popup>
        </DashboardLayout>
    )
}

export default SellerPayment
