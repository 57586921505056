import React from 'react'
import PropTypes from 'prop-types'
import './staticpages.css'

function SubmitVehicle(props) {
    const {} = props

    return <>Submitvehicle</>
}

SubmitVehicle.propTypes = {}

export default SubmitVehicle
