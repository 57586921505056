import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './simpleBreadcrumbs.css'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
// import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

function handleClick(event) {
    event.preventDefault()
    console.info('You clicked a breadcrumb.')
}

export default function SimpleBreadcrumbs() {
    // const {} = props

    return (
        <Breadcrumbs
            separator={<span className="material-icons">navigate_before</span>}
            aria-label="breadcrumb"
        >
            <Typography color="textPrimary"></Typography>
            <Link color="inherit" to="/">
                Back to home page
            </Link>
        </Breadcrumbs>
    )
}
