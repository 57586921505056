import React, { useEffect, useState, useRef } from 'react'
import JwtDecode from 'jwt-decode'
import { useSnackbar } from 'notistack'
import csc from 'country-state-city'
import { useFormik } from 'formik'

import DashboardLayout from '../../components/templates/DashboardLayout/DashboardLayout'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { request } from '../../Utils/Request'
import { profileSchema } from '../../Utils/Validatior'
import ChangePassword from './ChangePassword'
import { isAuthenticated } from '../../Utils/Auth'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

const BuyerProfile = () => {
    const [userDetails, setUserDetails] = useState({
        first_name: '',
        email: '',
        phone: '',
        username: '',
        address1: '',
        address2: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        avatar: '',
    })
    const [bankButton, setBankButton] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const referlink = `${window.location.origin}/registration_step_1?ref=${btoa(userDetails.email)}`
    const linkurl = useRef(null)
    const formik = useFormik({
        initialValues: userDetails,
        enableReinitialize: true,
        validationSchema: profileSchema,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })

    const getUserProfile = async () => {
        try {
            const res = await request('post', '/getUserProfileDetails', {}, true)
            if (res.data.success === 'yes') {
                var user = res.data.data
                console.log(user)
                var country = csc.getAllCountries().find((obj) => obj.name === user.country)
                var state = csc
                    .getStatesOfCountry(country.id)
                    .find((obj) => obj.name === user.state)
                setUserDetails({
                    first_name: user.first_name,
                    username: user.username,
                    email: user.email,
                    phone: user.phone.split(' ')[1],
                    country: country.id,
                    state: state.id,
                    city: user.city,
                    address1: user.address1,
                    address2: user.address2,
                    zip: user.zip,
                    avatar: user.avatar,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const updateProfile = async (val) => {
        try {
            var country = csc.getCountryById(val.country).name
            var state = csc.getStateById(val.state).name
            val.phone = '+' + csc.getCountryById(val.country).phonecode + ' ' + val.phone
            val.country = country
            val.state = state
            val.user_profile_image = userDetails.avatar
            const res = await request(
                'post',
                '/updateUserProfile',
                { ...val, role: JwtDecode(isAuthenticated()).role },
                true,
            )
            if (res.data.status === 'yes') {
                return enqueueSnackbar('Profile updated successfully!', {
                    variant: 'success',
                })
            }
            enqueueSnackbar('Error in update profile!', {
                variant: 'error',
            })
        } catch (error) {
            console.log(error)
        }
    }
    const onBoard = async () => {
        try {
            const res_get_account_id = await request('get', '/get_account_id', {}, true)
            if (res_get_account_id.data.success) {
                if (res_get_account_id.data.account_id) {
                    const res_retrive_account = await request(
                        'post',
                        '/admin/retriveAccountDetails',
                        { account_id: res_get_account_id.data.account_id },
                        false,
                        4,
                    )
                    if (res_retrive_account.data.status === 'success') {
                        if (res_retrive_account.data.data.responseType === 1) {
                            if (
                                !res_retrive_account.data.data.responseData.charges_enabled ||
                                !res_retrive_account.data.data.responseData.payouts_enabled
                            ) {
                                enqueueSnackbar('Invalid details!', {
                                    variant: 'warning',
                                })
                                return setBankButton(true)
                            }
                        }
                    }
                } else {
                    setBankButton(true)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const copyLinkUrl = (e) => {
        var copyText = document.getElementById('input')
        copyText.select()
        document.execCommand('copy')
        enqueueSnackbar('Link Copied Successfully', {
            variant: 'success',
        })
    }

    useEffect(() => {
        getUserProfile()
        onBoard()
    }, [])

    return (
        <DashboardLayout userType={JwtDecode(isAuthenticated()).role}>
            <div className="buyer-profile">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h5 className="mb-0">Basic Info</h5>
                    {bankButton && (
                        <div>
                            <Link Button className="btn bnkBtn" to="/bank_details">
                                <AccountBalanceIcon className="mr-2" /> Add Debit Card Details
                            </Link>
                        </div>
                    )}
                </div>
                <div className="dashboard-content-area">
                    <CopyToClipboard text={referlink}>
                        <div className="copy-link-area">
                            <input
                                ref={linkurl}
                                id="input"
                                value={`${window.location.origin}/registration_step_1?ref=${btoa(
                                    userDetails.email,
                                )}`}
                                className="copylinkemail"
                            />
                            <p className="link-cpy-compo pr-3">
                                <span className="link-refer-text"> Your Referral link is </span>
                                <br />
                                <span className="link-refer-url">
                                    {`${window.location.origin}/registration_step_1?ref=${btoa(
                                        userDetails.email,
                                    )}`}
                                </span>
                                &nbsp;
                                <span
                                    className="material-icons link-refer-cpy"
                                    onClick={copyLinkUrl}
                                >
                                    content_copy
                                </span>
                            </p>
                        </div>
                    </CopyToClipboard>
                    {JwtDecode(isAuthenticated()).role === '2' ? (
                        <div className="cardDetails mx-auto d-flex">
                            {userDetails.avatar && typeof userDetails.avatar !== 'undefined' ? (
                                userDetails.avatar.includes(',') ? (
                                    <>
                                        <img
                                            // style={{ marginRight: '10px' }}
                                            // style={{ marginLeft: '-48px' }}
                                            src={`${process.env.REACT_APP_AVATAR_URL}/${
                                                userDetails.avatar.split(',')[0]
                                            }`}
                                        />
                                        <img
                                            style={{ marginLeft: '10px' }}
                                            // style={{ marginRight: '-65px' }}
                                            src={`${process.env.REACT_APP_AVATAR_URL}/${
                                                userDetails.avatar.split(',')[1]
                                            }`}
                                        />
                                    </>
                                ) : (
                                    <img
                                        src={`${process.env.REACT_APP_AVATAR_URL}/${
                                            userDetails.avatar.split(',')[0]
                                        }`}
                                    />
                                )
                            ) : (
                                <img src="/assets/images/no_img.png" />
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <form className="row basic-info-row" onSubmit={formik.handleSubmit}>
                    <div className="col-6 input-left">
                        <CustomInput
                            label="Full Legal Name"
                            placeholder="Full Legal Name"
                            id="first_name"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.first_name && formik.errors.first_name
                                    ? formik.errors.first_name
                                    : null
                            }
                            error={formik.touched.first_name && formik.errors.first_name}
                        />
                    </div>
                    <div className="col-6 input-left">
                        <CustomInput
                            label="Address Line 2"
                            placeholder="Address Line 2"
                            id="address2"
                            name="address2"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.address2 && formik.errors.address2
                                    ? formik.errors.address2
                                    : null
                            }
                            error={formik.touched.address2 && formik.errors.address2}
                        />
                    </div>
                    <div className="col-6 input-left">
                        <CustomInput
                            label="Email"
                            placeholder="Email"
                            id="first_name"
                            disabled={true}
                            value={formik.values.email}
                        />
                    </div>
                    <div className="col-6 input-left">
                        <CustomInput
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.phone && formik.errors.phone
                                    ? formik.errors.phone
                                    : null
                            }
                            error={formik.touched.phone && formik.errors.phone}
                        />
                    </div>
                    <div className="col-6 input-left">
                        <CustomInput
                            label="User Name"
                            placeholder="User Name"
                            id="username"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.username && formik.errors.username
                                    ? formik.errors.username
                                    : null
                            }
                            error={formik.touched.username && formik.errors.username}
                        />
                    </div>
                    <div className="col-3 input-left">
                        <CustomSelect
                            label="Country"
                            value={formik.values.country}
                            id="country"
                            name="country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.country && formik.errors.country
                                    ? formik.errors.country
                                    : null
                            }
                            error={formik.touched.country && formik.errors.country}
                        >
                            {csc.getAllCountries().map((ele) => (
                                <option value={ele.id} key={ele.id}>
                                    {ele.name}
                                </option>
                            ))}
                        </CustomSelect>
                    </div>

                    <div className="col-3 input-left">
                        <CustomSelect
                            label="State"
                            id="state"
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.state && formik.errors.state
                                    ? formik.errors.state
                                    : null
                            }
                            error={formik.touched.state && formik.errors.state}
                        >
                            {csc.getStatesOfCountry(formik.values.country).map((ele) => (
                                <option value={ele.id} key={ele.id}>
                                    {ele.name}
                                </option>
                            ))}
                        </CustomSelect>
                    </div>
                    <div className="col-3 input-left">
                        <CustomInput
                            label="City"
                            placeholder="City"
                            id="city"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.city && formik.errors.city
                                    ? formik.errors.city
                                    : null
                            }
                            error={formik.touched.city && formik.errors.city}
                        />
                    </div>
                    <div className="col-3 input-left">
                        <CustomInput
                            label="Postal/Zip Code"
                            placeholder="Postal/Zip Code"
                            id="zip"
                            name="zip"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.zip && formik.errors.zip ? formik.errors.zip : null
                            }
                            error={formik.touched.zip && formik.errors.zip}
                        />
                    </div>

                    <div className="col-6 input-left">
                        <CustomInput
                            label="Street Address"
                            placeholder="Street Address"
                            id="address1"
                            name="address1"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.address1 && formik.errors.address1
                                    ? formik.errors.address1
                                    : null
                            }
                            error={formik.touched.address1 && formik.errors.address1}
                        />
                    </div>
                    <div className="col-12 save-button-buyer-profile">
                        <PrimaryButton label="SAVE" className="save-basic-info" type="submit" />
                        {/* <PrimaryButton label="Cancel" className="cancel-basic-info" /> */}
                    </div>
                </form>

                <h5>Change Password</h5>

                <ChangePassword />
            </div>
        </DashboardLayout>
    )
}

export default BuyerProfile
