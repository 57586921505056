import React from 'react'
import { TextField } from '@material-ui/core/'
import './style.css'
import Skeleton from '@material-ui/lab/Skeleton'
import LazyImage from '../LazyImage'
const FileUpload = (props) => {
    return (
        <div className={`custom-upload`}>
            <label htmlFor={props.id} className={` ${props.uploadClass}`}>
                <input
                    type={'file'}
                    className={`${props.uploadClassName} `}
                    name={props.name}
                    onClick={props.onClick}
                    id={props.id}
                    hidden
                    multiple={props.multiple ? true : false}
                    onChange={props.onChange ? (e) => props.onChange(e) : null}
                    accept={props.accept ? `${props.accept}/*` : '*'}
                />
                <p>{props.label}</p>
                <span className={props.buttonClass}>{props.test}</span>
            </label>
            <span className="text-danger">{props.error}</span>
            <div className="row pt-5">
                {props.preview_image &&
                props.preview_image instanceof Array &&
                props.preview_image.length > 0
                    ? props.preview_image.map((value, key) => (
                          <div className="col-md-3 mt-2">
                              {typeof value === 'string' ? (
                                  <div className="">
                                      <Skeleton
                                          variant="rect"
                                          width={150}
                                          height={150}
                                          animation="wave"
                                          className="pro-img rounded"
                                      />
                                  </div>
                              ) : (
                                  <>
                                      <div className="pp-uimg">
                                          <span
                                              className="img-del "
                                              onClick={() => props.removeImage(value)}
                                              name={key}
                                          >
                                              {' '}
                                              X{' '}
                                          </span>
                                      </div>
                                      {typeof value === 'boolean' ? (
                                          <div className="compression-box">compressing...</div>
                                      ) : (
                                          <LazyImage
                                              key={key}
                                              src={
                                                  typeof value === 'string'
                                                      ? value
                                                      : URL.createObjectURL(value)
                                              }
                                              height="150"
                                              width="150"
                                              className="pro-img rounded img-fluid"
                                          />
                                      )}
                                  </>
                              )}
                          </div>
                      ))
                    : null}
            </div>
        </div>
    )
}

export default FileUpload
