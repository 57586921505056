import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import './registrationStep2.css'
import { mapData } from '../../common/components'
import RadioBox from '../../components/atoms/RadioBox'
import CheckBox from '../../components/atoms/CheckBox'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import WelcomeHeader from '../../components/organisms/WelcomeHeader'
import FileUpload from '../../components/atoms/Inputs/FileUpload'
import { register2Schema } from '../../Utils/Validatior'
import csc from 'country-state-city'
import { useLocation, useHistory } from 'react-router-dom'
import { request } from '../../Utils/Request'
import { array } from 'yup'

const RegistrationStep2 = (props) => {
    const location = useLocation()
    const history = useHistory()
    const [step1Data, setStep1Data] = useState({})
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        if (!location.state) {
            // history.push('/registration_step_1')
            console.log(location.state)
        }
        setStep1Data(location.state)
    }, [])
    const { enqueueSnackbar } = useSnackbar()
    const [details, setDetails] = useState({
        street_address: '',
        address_line_2: '',
        mobile_number: '',
        city: '',
        state: '',
        postal_zipCode: '',
        country: '',
        account_number: '',
        routing_number: '',
        card_holder_name: '',
        card_number: '',
        exp_date: '',
        cvv: '',
        billing_card: 'credit_card',
        billing_address: 'signup_address',
        renew: false,
        img_upload: null,
        preview_image: [],
        dealer: '1',
    })
    const billingRadio = [
        { id: 'credit_card', description: 'Pay by Credit Card' },
        // { id: 'ach', description: 'Pay Using ACH Account Information' },
    ]
    const checkBoxLabel = [
        { id: '1', description: 'Private Party' },
        { id: '2', description: 'Dealer' },
    ]
    const billingAddress = [
        { id: 'signup_address', description: 'Same as Sign Up Address' },
        { id: 'new_address', description: 'Use New Billing Address' },
    ]
    const formik = useFormik({
        initialValues: details,
        validationSchema: register2Schema,
        onSubmit: (values) => {
            Signup(values)
        },
    })

    const addAddress = [
        {
            label: 'Street Address',
            placeholder: 'Street Address',
            class: 'col-12',
            type: 'text',
            name: 'street_address',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },

        {
            label: 'Address Line 2',
            placeholder: 'Address Line 2',
            class: 'col-12',
            type: 'text',
            name: 'address_line_2',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Mobile Number',
            placeholder: 'Mobile Number',
            class: 'col-12',
            type: 'number',
            name: 'mobile_number',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Country',
            placeholder: 'Country',
            class: 'col-lg-6 col-12 country-signup',
            type: 'select',
            options: csc.getAllCountries(),
            name: 'country',
            formik: formik,
            selectClassName: 'input-login',
            labelClass: 'light-white',
            onChange: (e) => {
                formik.setFieldValue('country', e.target.value)
                setDetails({ ...details, country: e.target.value })
            },
        },
        {
            label: 'State',
            placeholder: 'State',
            class: 'col-lg-6 col-12 signup-state',
            type: 'select',
            options: csc.getStatesOfCountry(details.country),
            name: 'state',
            formik: formik,
            selectClassName: 'input-login',
            labelClass: 'light-white',
        },
        {
            label: 'City',
            placeholder: 'City',
            class: 'col-lg-6 col-12',
            type: 'text',
            name: 'city',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Postal / Zip Code',
            placeholder: 'Postal / Zip Code',
            class: 'col-lg-6 col-12',
            type: 'text',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            name: 'postal_zipCode',
            formik: formik,
        },
    ]

    const addAddress2 = [
        {
            label: 'Street Address',
            placeholder: 'Street Address',
            class: 'col-12',
            type: 'text',
            name: 'street_address_2',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Address Line 2',
            placeholder: 'Address Line 2',
            class: 'col-12',
            type: 'text',
            name: 'address_line_2_2',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Mobile Number',
            placeholder: 'Mobile Number',
            class: 'col-12',
            type: 'number',
            name: 'mobile_number_2',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Country',
            placeholder: 'Country',
            class: 'col-lg-6 col-12 country-signup',
            type: 'select',
            options: csc.getAllCountries(),
            name: 'country_2',
            formik: formik,
            selectClassName: 'input-login',
            labelClass: 'light-white',
            onChange: (e) => {
                formik.setFieldValue('country', e.target.value)
                setDetails({ ...details, country: e.target.value })
            },
        },
        {
            label: 'State',
            placeholder: 'State',
            class: 'col-lg-6 col-12 signup-state',
            type: 'select',
            options: csc.getStatesOfCountry(details.country),
            name: 'state_2',
            formik: formik,
            selectClassName: 'input-login',
            labelClass: 'light-white',
        },
        {
            label: 'City',
            placeholder: 'City',
            class: 'col-lg-6 col-12',
            type: 'text',
            name: 'city_2',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Postal / Zip Code',
            placeholder: 'Postal / Zip Code',
            class: 'col-lg-6 col-12',
            type: 'text',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            name: 'postal_zipCode_2',
            formik: formik,
        },
    ]

    const billingInfo = [
        {
            label: 'Account Number',
            placeholder: 'Account Number',
            type: 'text',
            class: 'col-12',
            name: 'account_number',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Routing Number',
            placeholder: 'Routing Number',
            type: 'text',
            class: 'col-12',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            name: 'routing_number',
            formik: formik,
        },
    ]
    const billingCreditCard = [
        {
            label: 'Card Number',
            placeholder: 'Card Number',
            type: 'text',
            class: 'col-12',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            name: 'card_number',
            formik: formik,
            onChange: (e) => formik.setFieldValue('card_number', cardNumberFormat(e.target.value)),
        },
        {
            label: 'Card Holder Name',
            placeholder: 'Card Holder Name',
            type: 'text',
            class: 'col-12',
            name: 'card_holder_name',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            formik: formik,
        },
        {
            label: 'Expiration Date',
            placeholder: 'MM/YY',
            type: 'text',
            class: 'col-lg-6 col-12',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            name: 'exp_date',
            formik: formik,
            onChange: (e) => formik.setFieldValue('exp_date', dateFormat(e.target.value)),
        },
        {
            label: 'CVV Code',
            placeholder: 'CVV Code',
            type: 'number',
            class: 'col-lg-6 col-12',
            labelClass: 'light-white',
            inputClassName: 'input-login',
            name: 'cvv',
            formik: formik,
        },
    ]
    const handleChangeRadio = (e) => {
        formik.setFieldValue(e.target.name, e.target.value)
        setDetails({
            ...details,
            [e.target.name]: e.target.value,
        })
    }
    const handleChangeFile = (e) => {
        if (details.dealer === '1') {
            if (e.target.files.length === 2) {
                // var arr = []
                // for (const [key, value] of Object.entries(e.target.files)) {
                //     arr.push({ id: key, file: value })
                // }
                // let file = e.target.files[0].name + ',' + e.target.files[1].name
                formik.setFieldValue('img_upload', e.target.files[0])
                details.preview_image.push(e.target.files[0])
                details.preview_image.push(e.target.files[1])
                setDetails({ ...details })
            } else {
                formik.setFieldValue('img_upload', e.target.files[0])
                // var arr = []
                // for (const [key, value] of Object.entries(e.target.files)) {
                //     arr.push({ id: key, file: value })
                // }
                // formik.setFieldValue('img_upload', [...formik.values.img_upload, ...arr])
                details.preview_image.push(e.target.files[0])
                setDetails({ ...details })
            }
        } else {
            formik.setFieldValue('img_upload', e.target.files[0])
            setDetails({ ...details, preview_image: [e.target.files[0]] })
        }

        e.target.value = ''
    }

    const removeUploadImg = (val) => {
        var arr = []
        details.preview_image.map((ind, key) => {
            if (val.name !== ind.name) {
                arr.push(ind)
            }
        })

        formik.setFieldValue('img_upload', arr.length == 0 ? null : arr[0])
        setDetails({ ...details, preview_image: arr })
    }

    function cardNumberFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }
        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }
    function dateFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{2,4}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 2) {
            parts.push(match.substring(i, i + 2))
        }
        if (parts.length) {
            return parts.join('/')
        } else {
            return value
        }
    }
    const Signup = async (val) => {
        try {
            setLoader(true)
            // if (!details.renew) {
            //     setLoader(false)
            //     return enqueueSnackbar('Please accept auto renew membership!', {
            //         variant: 'warning',
            //     })
            // }

            // Create Customer
            // if (details.dealer === '1' && details.preview_image.length !== 2) {
            //     setLoader(false)
            //     return enqueueSnackbar(
            //         "Please upload front and back of your valid Driver's License.",
            //         {
            //             variant: 'warning',
            //         },
            //     )
            // }
            const customer_details = {
                account_id: process.env.REACT_APP_AUCTION_PAY_ID,
                email: step1Data.email,
                name: step1Data.first_name,
                address: {
                    line1: val.street_address,
                    line2: val.address_line_2,
                    city: val.city,
                    postal_code: val.postal_zipCode,
                    state: csc.getStateById(val.state).name,
                    country: csc.getCountryById(details.country).name,
                },
            }

            const resCustomer = await request(
                'post',
                '/customer/create',
                customer_details,
                false,
                2,
            )
            if (resCustomer.data.status !== 'success') {
                setLoader(false)
                return enqueueSnackbar('Problem in creating stripe account!', {
                    variant: 'error',
                })
            }

            // Add card details
            const data_card = {
                customer_id: resCustomer.data.data.responseData.id,
                card_number: val.card_number.split(' ').join(''),
                card_exp_month: val.exp_date.split('/')[0],
                card_exp_year: `20${val.exp_date.split('/')[1]}`,
                card_cvc: val.cvv,
                card_name: val.card_holder_name,
                card_address_line1: val.street_address,
                card_address_city: val.city,
                card_address_state: csc.getStateById(val.state).name,
                card_address_zip: val.postal_zipCode,
                card_address_country: csc.getCountryById(details.country).name,
                card_token: true,
            }
            const resCard = await request('post', '/customer/source/create', data_card, false, 2)
            if (resCard.data.status !== 'success') {
                setLoader(false)
                return enqueueSnackbar('Card Verification failed!', {
                    variant: 'error',
                })
            }

            const data = {
                ...step1Data,
                address1: val.street_address,
                address2: val.address_line_2,
                country: csc.getCountryById(details.country).name,
                state: csc.getStateById(val.state).name,
                city: val.city,
                zip: val.postal_zipCode,
                phone:
                    '+' + csc.getCountryById(details.country).phonecode + ' ' + val.mobile_number,
                card_paymentid: resCustomer.data.data.responseData.id,
                status: 'unverified',
                redirectURL: `${window.location.origin}/verifyuser`,
                // user_profile_image: val.img_upload,
                last_name: ' ',
                role: details.dealer,
            }
            const fromData = new FormData()
            for (const [key, value] of Object.entries(data)) {
                fromData.set(key, value)
            }

            for (let i = 0; i < details.preview_image.length; i++) {
                fromData.append(`user_profile_image`, details.preview_image[i])
            }

            const res = await request('post', '/register', fromData)
            if (res.data.status !== 'yes') {
                setLoader(false)
                return enqueueSnackbar(res.data.message, {
                    variant: 'error',
                })
            }

            let searchParams = new URLSearchParams(step1Data.searchUrl)
            if (step1Data.searchUrl) {
                const referData = {
                    discount1: '10%',
                    discount2: '10%',
                    email1: data.email,
                    email2: atob(searchParams.getAll('ref')),
                    message1: 'You have received coupon code for 10%',
                    message2: `User ${data.first_name} sda registered using your link. You have received coupon code for 10%`,
                    ref: 1,
                    subject1: 'Registration offer!',
                    subject2: 'Referral offer!',
                }
                const referal = await request('post', '/createcouponcode', referData)
                console.log(referal, 'this is referak value')
            }
            enqueueSnackbar('Account created successfully!', {
                variant: 'success',
            })
            history.push('/login')
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    return (
        <WelcomeHeader>
            <div className="sign-up-welcome-note welcome-heading">
                <h5 className="light-white my-3">Welcome to Coach & Gavel!</h5>
            </div>
            <div className="sign-up-credential">
                <h4 className="light-white pb-4">Finish Sign Up to Continue</h4>
                <form onSubmit={formik.handleSubmit}>
                    <div className="sign-in-heading">
                        <div className="first-half  text-left">
                            <h5 className="light-white m-0">Add Address</h5>
                            <p className="light-white add-address-license">
                                (As Stated on Drivers License)
                            </p>
                            <div className="first-half-fields my-3 row">
                                {Object.values(mapData(addAddress))}
                                <div className="col-12">
                                    <p className="light-white">
                                        Please Continue to payment Section
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="second-half  text-left">
                            <h5 className="light-white m-0">Billing Information</h5>
                            <div className="first-half-fields row">
                                <div className="col-12">
                                    <RadioBox
                                        items={billingRadio}
                                        labelClassName={'light-white login-radio-btn'}
                                        parentRadioClassName={'bill-radio-btn'}
                                        name="billing_card"
                                        value={details.billing_card}
                                        onChange={handleChangeRadio}
                                    />
                                </div>
                                {details.billing_card === 'ach'
                                    ? Object.values(mapData(billingInfo))
                                    : Object.values(mapData(billingCreditCard))}
                                {/* <div className="col-12">
                                    <h5 className="light-white ">Billing Address</h5>
                                    <RadioBox
                                        items={billingAddress}
                                        labelClassName={'light-white login-radio-btn'}
                                        parentRadioClassName={'bill-radio-btn'}
                                        name="billing_address"
                                        value={details.billing_address}
                                        onChange={handleChangeRadio}
                                    />
                                </div>
                                {details.billing_address === 'new_address' && (
                                    <div className="first-half-fields my-3 row">
                                        {Object.values(mapData(addAddress2))}
                                    </div>
                                )} */}
                                <div className="col-12">
                                    <RadioBox
                                        items={checkBoxLabel}
                                        labelClassName={'light-white login-radio-btn'}
                                        onChange={handleChangeRadio}
                                        name="dealer"
                                        value={details.dealer}
                                    />
                                </div>
                                {console.log('++++++++++++', details.dealer)}
                                {parseInt(details.dealer) == 2 ? (
                                    <div className="col-12">
                                        <FileUpload
                                            uploadClassName={'fileupload-signup'}
                                            label={
                                                details.dealer === '1'
                                                    ? `Please upload clear, color images of the front and back of your valid
                                            Driver's License`
                                                    : `Please upload a clear, color image of your valid Dealer License`
                                            }
                                            test={'Browse'}
                                            id={'img_upload'}
                                            uploadClass="upldWrapper"
                                            buttonClass="browse-upload"
                                            name="img_upload"
                                            multiple={details.dealer === '1' ? true : false}
                                            onChange={handleChangeFile}
                                            error={
                                                formik.touched.img_upload &&
                                                formik.errors.img_upload
                                                    ? formik.errors.img_upload
                                                    : null
                                            }
                                            accept="image"
                                            preview_image={details.preview_image}
                                            removeImage={removeUploadImg}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                                {/* <div className="col-12">
                                <div className="note-content my-3">
                                    <p className="light-white">
                                        * Please note that you will be immediately change a
                                        non-refundable membership fee of $49.95 - which entitles you
                                        to selling and bidding for one year from this date.
                                    </p>
                                </div>
                            </div> */}
                                {/* <div className="col-12">
                                    <div className="sign-up-confirmation my-1">
                                        <CheckBox
                                            label={
                                                'Yes,please automatically charge my form of payment on file on year from today to renew my membership'
                                            }
                                            value={details.renew}
                                            checked={details.renew}
                                            onclick={() =>
                                                setDetails({
                                                    ...details,
                                                    renew: !details.renew,
                                                })
                                            }
                                        />
                                    </div>
                                </div> */}
                                <div className="col-12">
                                    <div className="sign-up-confirm-btn my-4">
                                        <PrimaryButton
                                            label={'Save Information'}
                                            customClassName={'confirm-btn'}
                                            disabled={loader}
                                            type="submit"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </WelcomeHeader>
    )
}

export default RegistrationStep2
