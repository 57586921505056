import { Button, Menu, MenuItem } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { ListItem, List, SwipeableDrawer, Divider } from '@material-ui/core'
import { Link, NavLink, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import GavelIcon from '@material-ui/icons/Gavel'
import MenuIcon from '@material-ui/icons/Menu'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import DashboardIcon from '@material-ui/icons/Dashboard'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import CloseIcon from '@material-ui/icons/Close'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import InfoIcon from '@material-ui/icons/Info'
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo'
// import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'

import './header.css'
import { useCustomMediaQuery } from '../../../common/components'
import { Dashboard } from '@material-ui/icons'
import { isAuthenticated } from '../../../Utils/Auth'
import { useSnackbar } from 'notistack'
const Header = (props) => {
    const history = useHistory()

    const aboutbutton = useRef()

    const useStyles = makeStyles({
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
    })

    const [headerClassName, setHeaderClassName] = useState('header')

    const [anchorEl, setAnchorEl] = React.useState({ anchorEl: undefined, open: false })

    const [about, setAbout] = React.useState({ anchorEl: undefined, open: false })

    const [login, setLogin] = React.useState({ anchorEl: undefined, open: false })

    const { enqueueSnackbar } = useSnackbar()
    const [user, setUser] = React.useState(
        localStorage.getItem('coachUser') ? JSON.parse(localStorage.getItem('coachUser')) : {},
    )
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent)

        return () => window.removeEventListener('scroll', listenScrollEvent)
    }, [])

    useEffect(() => {
        if (window.location.href.includes('#about')) {
            if (window.innerWidth > 600) {
                window.scrollTo(0, 0)
                // aboutbutton.current.click()
                {
                    console.log('aboutbuttonRef', aboutbutton.current)
                }
                setAbout({
                    open: true,
                    anchorEl: aboutbutton.current,
                })
            } else {
                toggleDrawerauto()
            }
        }
    }, [window.location.href])

    const listenScrollEvent = (event) => {
        if (window.scrollY > 0) {
            return setHeaderClassName('changeColor')
        } else if (window.scrollY < 70) {
            return setHeaderClassName('header')
        }
    }

    const logout = () => {
        localStorage.removeItem('coachToken')
        localStorage.removeItem('coachUser')
        enqueueSnackbar('Logged out successfully!', {
            variant: 'success',
        })
        window.location.href = '/'
    }
    const handleAuctionClick = (event) => {
        // setAnchorEl(event.currentTarget)
        setAnchorEl({ open: true, anchorEl: event.currentTarget })
    }

    const handleAuctionClose = () => {
        setAnchorEl({ ...anchorEl, open: false })
    }

    const handleAboutCgClick = (event) => {
        console.log('test', event.currentTarget)
        setAbout({ open: true, anchorEl: event.currentTarget })
    }

    const handleAboutCgClose = () => {
        setAbout({ ...about, open: false })
    }

    const handleLoginClick = (event) => {
        setLogin({ open: true, anchorEl: event.currentTarget })
    }

    const handleLoginClose = () => {
        setLogin({ ...login, open: false })
    }

    const handleLiveAuctions = () => {
        history.push('/liveauction')
        handleAuctionClose()
    }

    const handlePastAuctions = () => {
        history.push('/pastauction')
        handleAuctionClose()
    }
    // Swipeable drawer
    const classes = useStyles()
    const [state, setState] = React.useState({
        right: false,
    })

    const toggleDrawerauto = () => {
        setState({ ...state, ['right']: true })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            console.log('valuesss--->anchor', anchor, open)
            return
        }
        console.log('valuesss--->anchor', anchor, open)
        setState({ ...state, [anchor]: open })
    }
    const white = {
        color: 'white',
    }
    const [value, setvalue] = useState(false)

    const list = (anchor) => (
        <div
            className={clsx(
                classes.list,
                {
                    [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                },
                'commonDashNavSlider ',
            )}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="SideLogo">
                <Link to="/">
                    <img src="/assets/svg/gavel-logo.svg" className="headerLogo" alt="gavelLogo" />
                </Link>
                <Button onClick={toggleDrawer(anchor, false)}>
                    <CloseIcon fontSize="small" style={white} />
                </Button>
            </div>
            <List>
                {isAuthenticated() && (
                    <ListItem button>
                        <NavLink
                            className="dashboardNav"
                            to={
                                user.role === 'buyer'
                                    ? '/dashboard/buyer/profile'
                                    : '/dashboard/seller/profile'
                            }
                            activeClassName="active"
                        >
                            <ListItemIcon>
                                <AccountCircleIcon />
                                Hi, <span className="usreNameelli">{user.first_name}</span>
                            </ListItemIcon>
                        </NavLink>
                    </ListItem>
                )}
                <ListItem button>
                    <NavLink className="dashboardNav" to="/liveauction" activeClassName="active">
                        <ListItemIcon>
                            <GavelIcon />
                            Live Auctions
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink className="dashboardNav" to="/pastauction" activeClassName="active">
                        <ListItemIcon>
                            <MoneyOffIcon />
                            Past Auctions
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                {isAuthenticated() && (
                    <ListItem button>
                        <NavLink
                            className="dashboardNav"
                            to={
                                user.role === 'buyer'
                                    ? '/dashboard/buyer/myauction'
                                    : '/dashboard/seller/myauction'
                            }
                            activeClassName="active"
                        >
                            <ListItemIcon>
                                <DashboardIcon />
                                Dashboard
                            </ListItemIcon>
                        </NavLink>
                    </ListItem>
                )}

                <ListItem button>
                    <NavLink
                        className="dashboardNav"
                        to={!isAuthenticated() ? '/login' : '/product_posting'}
                        activeClassName="active"
                    >
                        <ListItemIcon>
                            <MotorcycleIcon />
                            Submit a Vehicle
                        </ListItemIcon>
                    </NavLink>
                </ListItem>

                <ListItem button>
                    <NavLink className="dashboardNav" to="/whatcoachgavel" activeClassName="active">
                        <ListItemIcon>
                            <HelpOutlineIcon />
                            What Is Coach & Gavel?
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink className="dashboardNav" to="/whycoachgavel" activeClassName="active">
                        <ListItemIcon>
                            <HelpOutlineIcon />
                            Why Coach & Gavel?
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink className="dashboardNav" to="/howitworks" activeClassName="active">
                        <ListItemIcon>
                            <InfoIcon />
                            How Does It Work?
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink className="dashboardNav" to="/joinemaillist" activeClassName="active">
                        <ListItemIcon>
                            <AllInboxIcon />
                            Join The E-Mail List
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                {/* <ListItem button>
                    <NavLink className="dashboardNav" to="/gearstore" activeClassName="active">
                        <ListItemIcon>
                            <SettingsInputSvideoIcon />
                            Gear Store
                        </ListItemIcon>
                    </NavLink>
                </ListItem> */}
                <ListItem button>
                    <NavLink className="dashboardNav" to="/privacy_policy" activeClassName="active">
                        <ListItemIcon>
                            <ChromeReaderModeIcon />
                            Privacy Policy
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink className="dashboardNav" to="/terms" activeClassName="active">
                        <ListItemIcon>
                            <ChromeReaderModeIcon />
                            Terms & Conditions
                        </ListItemIcon>
                    </NavLink>
                </ListItem>
                <Divider />
                {!isAuthenticated() && (
                    <ListItem button>
                        <Button
                            variant="contained"
                            className="sideNavBtn"
                            color="primary"
                            href="/login"
                        >
                            Login
                        </Button>
                    </ListItem>
                )}
                {isAuthenticated() && (
                    <ListItem button>
                        <Button
                            variant="contained"
                            className="sideNavBtn"
                            color="primary"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </ListItem>
                )}
            </List>
        </div>
    )
    // Swipeable drawer end
    // const isTabletMid = useCustomMediaQuery('(max-width: 700px)')
    // const isTabletMid = useMediaQuery({ query: '(max-width: 700px)' })
    // const dNone = { style: { display: 'none' } }
    const unLogged = () => (
        <React.Fragment>
            {/* <Button
                className="light-white header-menu"
                aria-controls="simple-menu"
                aria-haspopup="true"
                display="none"
            ></Button> */}
            <Button
                onClick={handleAuctionClick}
                className="light-white header-menu"
                aria-controls="simple-menu"
                aria-haspopup="true"
            >
                Auctions <ExpandMoreIcon />
            </Button>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
            >
                <NavLink className="headerLink" to="/login" activeClasName="active">
                    Submit a Vehicle
                </NavLink>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={anchorEl.open}
                onClose={handleAuctionClose}
            >
                <MenuItem onClick={handleLiveAuctions}>Live Now</MenuItem>
                <MenuItem onClick={handlePastAuctions}>Past Auctions</MenuItem>
            </Menu>
            <Link to="/">
                <img src="/assets/svg/gavel-logo.svg" className="headerLogo" alt="gavelLogo" />
            </Link>
            <Button
                id="aboutCGBtn"
                ref={aboutbutton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
                onClick={handleAboutCgClick}
            >
                About C&G <ExpandMoreIcon />
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={about.anchorEl}
                getContentAnchorEl={null}
                className="staTick"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={about.open}
                onClose={handleAboutCgClose}
                // className={props.classes.menu}
            >
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/whatcoachgavel"> What Is Coach & Gavel?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/whycoachgavel"> Why Coach & Gavel?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/howitworks">How Does It Work?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/joinemaillist">Join The E-Mail List</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/privacy_policy">Privacy Policy</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/terms">Terms & Conditions</NavLink>
                </MenuItem>
            </Menu>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
            >
                <NavLink className="headerLink" to="/login" activeClasName="active">
                    Login
                </NavLink>
                {/* /
                <NavLink className="headerLink" to="/registration_step_1" activeClasName="active">
                    Register
                </NavLink> */}
            </Button>
            {/* <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
            >
                <NavLink className="headerLink" to="/registration_step_1" activeClasName="active">
                    Register
                </NavLink>
            </Button> */}
        </React.Fragment>
    )
    const buyer = () => (
        <React.Fragment>
            <Button
                onClick={handleAuctionClick}
                className="light-white header-menu"
                aria-controls="simple-menu"
                aria-haspopup="true"
            >
                Auctions <ExpandMoreIcon />
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={anchorEl.open}
                onClose={handleAuctionClose}
            >
                <MenuItem onClick={handleLiveAuctions}>Live Now</MenuItem>
                <MenuItem onClick={handlePastAuctions}>Past Auctions</MenuItem>
            </Menu>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
            >
                <NavLink className="headerLink" to="/product_posting" activeClasName="active">
                    Submit a Vehicle
                </NavLink>
            </Button>
            <Link to="/">
                <img src="/assets/svg/gavel-logo.svg" className="headerLogo" alt="gavelLogo" />
            </Link>

            <Button
                ref={aboutbutton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
                onClick={handleAboutCgClick}
            >
                About C&G <ExpandMoreIcon />
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={about.anchorEl}
                style={{ top: '58px', left: '570px' }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={about.open}
                className="staTick"
                onClose={handleAboutCgClose}
                // className={props.classes.menu}
            >
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/whatcoachgavel"> What Is Coach & Gavel?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/whycoachgavel"> Why Coach & Gavel?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/howitworks">How Does It Work?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/joinemaillist">Join The E-Mail List</NavLink>
                </MenuItem>
                {/* <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/gearstore">Gear Store</NavLink>
                </MenuItem> */}
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/privacy_policy">Privacy Policy</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/terms">Terms & Conditions</NavLink>
                </MenuItem>
            </Menu>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
                onClick={handleLoginClick}
            >
                Hi, <span className="usreNameelli">{user.first_name}</span> <ExpandMoreIcon />
                {/* Hi, {user.first_name} | {user.role.toUpperCase()} <ExpandMoreIcon /> */}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={login.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={login.open}
                onClose={handleLoginClose}
                // className={props.classes.menu}
            >
                <MenuItem onClick={handleLoginClose}>
                    <NavLink className="headerLink" to="/dashboard/buyer/profile">
                        My Account
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={handleLoginClose}>
                    <NavLink className="headerLink" to="/dashboard/buyer/myauction">
                        My Dashboard
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </React.Fragment>
    )
    const seller = () => (
        <React.Fragment>
            <Button
                onClick={handleAuctionClick}
                className="light-white header-menu"
                aria-controls="simple-menu"
                aria-haspopup="true"
            >
                Auctions <ExpandMoreIcon />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={anchorEl.open}
                onClose={handleAuctionClose}
            >
                <MenuItem onClick={handleLiveAuctions}>Live Now</MenuItem>
                <MenuItem onClick={handlePastAuctions}>Past Auctions</MenuItem>
            </Menu>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
            >
                <NavLink className="headerLink" to="/product_posting" activeClasName="active">
                    Submit a Vehicle
                </NavLink>
            </Button>
            <Link to="/">
                <img src="/assets/svg/gavel-logo.svg" className="headerLogo" alt="gavelLogo" />
            </Link>

            <Button
                ref={aboutbutton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
                onClick={handleAboutCgClick}
            >
                About C&G <ExpandMoreIcon />
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={about.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={about.open}
                className="staTick"
                onClose={handleAboutCgClose}
                // className={props.classes.menu}
            >
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/whatcoachgavel"> What Is Coach & Gavel?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/whycoachgavel"> Why Coach & Gavel?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/howitworks">How Does It Work?</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/joinemaillist">Join The E-Mail List</NavLink>
                </MenuItem>
                {/* <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/gearstore">Gear Store</NavLink>
                </MenuItem> */}
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/privacy_policy">Privacy Policy</NavLink>
                </MenuItem>
                <MenuItem onClick={handleAboutCgClose}>
                    <NavLink to="/terms">Terms & Conditions</NavLink>
                </MenuItem>
            </Menu>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="light-white header-menu"
                onClick={handleLoginClick}
            >
                Hi, <span className="usreNameelli"> {user.first_name} </span>
                <ExpandMoreIcon />
                {/* Hi, {user.first_name} | {user.role.toUpperCase()} <ExpandMoreIcon /> */}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={login.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={login.open}
                onClose={handleLoginClose}
                // className={props.classes.menu}
            >
                <MenuItem onClick={handleLoginClose}>
                    <NavLink className="headerLink" to="/dashboard/buyer/profile">
                        My Account
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={handleLoginClose}>
                    <NavLink className="headerLink" to="/dashboard/seller/myauction">
                        My Dashboard
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </React.Fragment>
    )
    return (
        <div
            className={`header-common header ${props.hdrCls} ${
                window.scrollY >= '5' ? 'white' : 'old'
            }`}
        >
            {/* {console.log(isTabletMid, 'isTabletMid')} */}
            <div className={`${useCustomMediaQuery('(max-width: 700px)') ? '' : 'd-none'} `}>
                <div
                    maxScroll={200}
                    className={`responsableHeader ${window.scrollY >= '5' ? 'white' : 'old'}`}
                >
                    <Link to="/">
                        <img
                            src="/assets/svg/gavel-logo.svg"
                            className="headerLogo"
                            alt="gavelLogo"
                        />
                    </Link>
                    {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Button onClick={toggleDrawer(anchor, true)}>
                                <MenuIcon />
                            </Button>
                            <SwipeableDrawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                                onOpen={toggleDrawer(anchor, true)}
                            >
                                {list(anchor)}
                            </SwipeableDrawer>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="customContainer d-flex justify-content-center align-items-center">
                {isAuthenticated() ? (user.role === 'buyer' ? buyer() : seller()) : unLogged()}
            </div>
        </div>
    )
    const MobileHeader = () => {
        ;<div className={`${useCustomMediaQuery('(max-width: 700px)') ? '' : 'd-none'}`}>
            <div
                maxScroll={200}
                className={`responsableHeader ${window.scrollY >= '5' ? 'white' : 'old'}`}
            >
                <Link to="/">
                    <img src="/assets/svg/gavel-logo.svg" className="headerLogo" alt="gavelLogo" />
                </Link>
                {['right'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button onClick={toggleDrawer(anchor, true)}>
                            <MenuIcon />
                        </Button>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor)}
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </div>
        </div>
    }
}

export default Header
