import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import crypto from 'crypto-js'

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}
const CarFax = ({ vin }) => {
    const [value, setValue] = useState('')
    const [partnerCode] = useState(process.env.REACT_APP_CARFAX_PARTNER_CODE)
    const [partnerKey] = useState(process.env.REACT_APP_CARFAX_PARTNER_KEY)
    const getDetails = async (vinNum) => {
        try {
            if (!vin) {
                return
            }
            const resLoad = await loadScript('https://snapshot.carfax.com/latest/snapshot.js')
            if (!resLoad) {
                return console.log('Load failed!')
            }
            console.log('successssssssss!')
            var str = 'vin=' + vinNum + '&partner=' + partnerCode
            console.log(str)
            const hash = crypto.HmacMD5(str, partnerKey).toString(crypto.enc.Base64)
            setValue(hash)
            // const res = await Axios.get(
            //     `http://snapshot.carfax.com/partnerReport?partner=${partnerCode}&key=${hash}`,
            // )
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getDetails(vin)
    }, [vin])
    console.log(value)
    return (
        <div
            className="carfax-snapshot-embedded"
            data-vin={vin}
            data-partner={partnerCode}
            data-key={value}
        ></div>
    )
}

export default CarFax
