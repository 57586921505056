import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import WelcomeHeader from '../../components/organisms/WelcomeHeader'
import './forgetPassword.css'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import { useFormik, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import forgotPasswordSchema from './forgotPasswordSchema'
const forget_password = () => {
    const history = useHistory()

    const [emailValue, setEmailValue] = useState({
        email: '',
    })

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const formik = useFormik({
        initialValues: emailValue,
        enableReinitialize: true,
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values, actions) => {
            try {
                const data = {
                    email: values.email,
                }
                const res = await request('post', apiEndPoint.forgotpassword, data)

                if (res.data.success === 'yes') {
                    enqueueSnackbar(res.data.message, { variant: 'success' })
                    setEmailValue({ email: '' })
                    actions.setSubmitting(false)
                    actions.resetForm({
                        values: {
                            email: '',
                        },
                    })
                } else if (res.data.success === 'no') {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            } catch (error) {
                console.log(error)
            }
        },
    })

    const handleBackLogin = () => {
        history.push('/login')
    }
    return (
        <WelcomeHeader>
            <div className="forgot-password">
                <h5 className="light-white my-3 welcome-heading">Welcome to Coach & Gavel !</h5>
                <div className="forgot-container-area">
                    <h4 className="light-white">Forgot Password</h4>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="forgot-password-content">
                            <CustomInput
                                label="Email"
                                type="text"
                                inputClassName="input-login login-user-name"
                                labelClass="login-user-name-label light-white"
                                placeholder="Email"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            {formik.errors.email ? (
                                <p className="err">{formik.errors.email}</p>
                            ) : null}
                            <div className="forgot-password-button">
                                <Link>
                                    <div className="back-to-login">
                                        <span className="light-white" onClick={handleBackLogin}>
                                            Back to Login{' '}
                                        </span>
                                    </div>
                                </Link>
                                <PrimaryButton
                                    customClassName="login-confirm-btn"
                                    label="Send Reset Password Link"
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </WelcomeHeader>
    )
}

export default forget_password
