const initialState = {
    timer: localStorage.getItem('prodtimer') || '',
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'ADD_TIMER':
            return {
                ...state,
                return: action.payload,
            }
        default:
            return state
    }
}
