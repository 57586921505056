import React, { useState, useEffect, Fragment } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'

import './dashboard.css'
import DashboardLayout from '../../components/templates/DashboardLayout/DashboardLayout'
// import Cards from '../../components/molecules/Card'
import Popup from '../../components/molecules/Popup'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import { sellerPaymentCardValidation, cardSchema } from '../../Utils/Validatior'
import { isAuthenticated } from '../../Utils/Auth'
import JwtDecode from 'jwt-decode'
import moment from 'moment'
import Pagination from '../../components/atoms/Pagination/Pagination'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 500,
    },
    tabPanel: {
        border: '1px solid #233e7c',
        backgroundColor: '#FFFFFF',
        color: 'var(--progressLine)',
        textTransform: 'capitalize',
    },
})

const BuyerPayment = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [cardInitial, setCardInitial] = useState({
        card_number: '',
        card_name: '',
        cvv: '',
        exp_date: '',
    })
    const [customer_id] = useState(JSON.parse(localStorage.getItem('coachUser')).card_paymentid)
    const classes = useStyles()
    const dispatch = useDispatch()
    const cardsInfo = useSelector((state) => state.SellerDashboard.sellerCards)
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const [cardAdd, setCardAdd] = useState(false)
    const [selectedCard, setSelectedCard] = useState('')
    const [selectedCardId, setSelectedCardId] = useState('')
    const [transactionData, setTransactionData] = useState({
        page: 1,
        sh_limit: 10,
        data: [],
        length: 0,
    })
    const [userType, setUserType] = useState('')
    const [loader, setLoader] = useState(true)
    const formik = useFormik({
        initialValues: cardInitial,
        enableReinitialize: true,
        validationSchema: cardSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const user = JwtDecode(isAuthenticated())
                const data = {
                    customer_id,
                    card_number: values.card_number.split(' ').join(''),
                    card_exp_month: values.exp_date.split('/')[0],
                    card_exp_year: `20${values.exp_date.split('/')[1]}`,
                    card_cvc: values.cvv,
                    card_token: true,
                    card_name: values.card_name,
                    card_address_line1: user.address1,
                    card_address_city: user.city,
                    card_address_state: user.state,
                    card_address_zip: user.zip,
                    card_address_country: user.country,
                }

                const res = await request('post', '/customer/source/create', data, true, 2)

                if (res.data.status === 'success') {
                    enqueueSnackbar('Card added successfully!', { variant: 'success' })
                    viewAllCard()
                    setCardAdd(false)
                    resetForm()
                } else {
                    enqueueSnackbar(res.data.data.message, {
                        variant: 'error',
                    })
                }
            } catch (error) {
                console.log(error)
            }
        },
    })

    const handleAddCard = () => {
        setCardAdd(true)
    }

    const handleClosePopup = () => {
        setCardAdd(false)
        formik.resetForm()
    }
    function cardNumberFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }
        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }

    function dateFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{2,4}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 2) {
            parts.push(match.substring(i, i + 2))
        }
        if (parts.length) {
            return parts.join('/')
        } else {
            return value
        }
    }

    const viewAllCard = async () => {
        try {
            const data = {
                customer_id,
            }
            const res = await request('post', '/customer/source/list', data, false, 2)

            if (res.data.status === 'success') {
                if (res.data.selectedCard) {
                    setSelectedCard(res.data.selectedCard.token_id)
                    setSelectedCardId(res.data.selectedCard.id)
                }

                dispatch({
                    type: 'SELLER_CARD',
                    payload: res.data.data.responseData.data,
                })
            } else {
                enqueueSnackbar(res.data.data.message, {
                    variant: 'error',
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const deleteCard = async (cardId) => {
        try {
            const data = {
                customer_id,
                source_id: cardId,
            }
            const res = await request('post', '/customer/source/delete', data, true, 2)
            if (res.data.status === 'success') {
                enqueueSnackbar('Card deleted successfully!', { variant: 'success' })
                viewAllCard()
            }
        } catch (error) {
            console.log(error)
        }
    }
    // const updateCard = async (cardId, month, year, card, cardType) => {
    //     try {
    //         const data = {
    //             activate: 'activate',
    //             cardId,
    //             month,
    //             card,
    //             cardType,
    //             year,
    //             mode: process.env.REACT_APP_MODE,
    //             id: selectedCardId,
    //         }
    //         const res = await request('post', '/updateCard', data, true)
    //         if (res.data.success) {
    //             enqueueSnackbar(res.data.data, { variant: 'success' })
    //             viewAllCard()
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    const getTransactions = async () => {
        try {
            setLoader(true)
            const res = await request(
                'post',
                '/buyerTransaction',
                { page: transactionData.page, sh_limit: transactionData.sh_limit },
                true,
            )
            if (res.data.success === 'yes') {
                setTransactionData({
                    ...transactionData,
                    data: res.data.results,
                    length: res.data.total_pagecnt,
                })
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    useEffect(() => {
        if (!value) {
            getTransactions()
        } else {
            viewAllCard()
        }
    }, [value, transactionData.page])
    useEffect(() => {
        setUserType(JwtDecode(isAuthenticated()).role)
    }, [])
    useEffect(() => {
        if (userType == 'seller') {
            setValue(1)
        }
    }, [userType])
    console.log(JwtDecode(isAuthenticated()).role, userType, 'this is user type')
    return (
        <DashboardLayout userType={JwtDecode(isAuthenticated()).role}>
            <Tabs
                className={`${classes.root} buyer-payment`}
                value={value}
                onChange={userType == 'seller' ? null : handleChange}
                centered
                aria-label="simple tabs example"
            >
                {userType == 'seller' ? null : (
                    <Tab label="Transactions" {...a11yProps(0)} className={classes.tabPanel} />
                )}
                <Tab label="Saved Cards" {...a11yProps(1)} className={classes.tabPanel} />
            </Tabs>
            {userType == 'seller' ? null : (
                <TabPanel value={value} index={0}>
                    {loader ? (
                        <div>
                            <img
                                src="./assets/svg/loader.svg"
                                className="img-fluid mx-auto d-block"
                            />
                        </div>
                    ) : (
                        <Fragment>
                            <div className="cusTable">
                                {transactionData.data.length > 0 ? (
                                    <table className="table custom-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sl.No</th>
                                                <th scope="col">Auction Name</th>
                                                <th scope="col">Transaction Id</th>
                                                <th scope="col">Transacted On</th>
                                                <th scope="col">Amount</th>
                                                {/* <th scope="col">Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionData.data.map((ele, key) => (
                                                <tr key={ele.id}>
                                                    <td>{key + 1}</td>
                                                    <td>{ele.title}</td>
                                                    <td>{ele.transid}</td>
                                                    <td>
                                                        {moment(ele.pay_date).format(
                                                            'MMMM Do, YYYY',
                                                        )}
                                                    </td>
                                                    <td>
                                                        ${' '}
                                                        {ele.amount && ele.amount.toLocaleString()}
                                                    </td>
                                                    {/* <td className="auction-view">View Invoice</td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div>No Transactions Found!</div>
                                )}
                            </div>
                            <div className="float-right pb-4">
                                <Pagination
                                    length={transactionData.length}
                                    limit={transactionData.sh_limit}
                                    page={transactionData.page}
                                    onChange={(e, v) =>
                                        setTransactionData({ ...transactionData, page: v })
                                    }
                                />
                            </div>
                        </Fragment>
                    )}
                </TabPanel>
            )}
            <TabPanel value={value} index={1}>
                <div className="saved-cards">
                    <div className="new-card" onClick={handleAddCard}>
                        <img src={'/assets/svg/card-icon.svg'} alt="new card" />
                        <p> ADD A NEW CARD</p>
                        <img src={'/assets/svg/card-types.svg'} alt="new card" />
                    </div>
                    {cardsInfo && typeof cardsInfo !== 'undefined' && cardsInfo.length
                        ? cardsInfo.map((ele) => (
                              <div className="card-wrapper">
                                  <div className="row">
                                      <div className="col-md-12">
                                          {/* <Cards
                                              name={ele.name}
                                              cardNum={`xxxx - xxxx - xxxx - ${ele.last4}`}
                                              month={ele.exp_month}
                                              year={ele.exp_year}
                                              cardType={ele.brand}
                                          /> */}
                                          <Cards
                                              preview
                                              name={
                                                  JSON.parse(localStorage.getItem('coachUser'))
                                                      .first_name
                                              }
                                              number={`************${ele.last4}`}
                                              expiry={`${
                                                  ele.exp_month < 10
                                                      ? '0' + ele.exp_month.toString()
                                                      : ele.exp_month
                                              }/${ele.exp_year}`}
                                              issuer={ele.brand}
                                          />
                                      </div>
                                  </div>
                                  {selectedCard !== ele.id && (
                                      <div className="row pt-3">
                                          <div className="col-12">
                                              {cardsInfo.length > 1 && (
                                                  <div className="paymt-save">
                                                      <PrimaryButton
                                                          label="Delete"
                                                          type="button"
                                                          onClick={() => deleteCard(ele.id)}
                                                          disabled={selectedCard === ele.id}
                                                      />
                                                  </div>
                                              )}
                                          </div>
                                          {/* <div className="col-6">
                                              <div className="paymt-save">
                                                  <PrimaryButton
                                                      label={
                                                          selectedCard === ele.id
                                                              ? 'Primary Card'
                                                              : 'Make Primary'
                                                      }
                                                      type="button"
                                                      onClick={() =>
                                                          updateCard(
                                                              ele.id,
                                                              ele.exp_month,
                                                              ele.exp_year,
                                                              'xxxx-xxxx-xxxx-' + ele.last4,
                                                              ele.brand,
                                                          )
                                                      }
                                                      disabled={selectedCard === ele.id}
                                                  />
                                              </div>
                                          </div> */}
                                      </div>
                                  )}
                                  {selectedCard === ele.id && (
                                      <div className="row pt-3">
                                          <div className="col-12">
                                              {cardsInfo.length > 1 && (
                                                  <label className="primary-card">
                                                      Primary Card
                                                  </label>
                                              )}
                                          </div>
                                      </div>
                                  )}
                              </div>
                          ))
                        : null}
                </div>
            </TabPanel>

            <Popup
                open={cardAdd}
                title="Add Card"
                className="paymentCardPopup"
                width="sm"
                handleClose={handleClosePopup}
                closeButtonClass="card-close"
                fullWidthValue={true}
            >
                <div className="saved-card-dtls">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row pymt-dtls-row">
                            <div className="col-12 input-left">
                                <CustomInput
                                    placeholder="Name on Card"
                                    label="Name on Card"
                                    type="text"
                                    value={formik.values.card_name}
                                    name="card_name"
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.card_name && formik.touched.card_name ? (
                                    <p className="err">{formik.errors.card_name}</p>
                                ) : null}
                            </div>
                            <div className="col-12 input-left">
                                <CustomInput
                                    placeholder="Card Number"
                                    label="Card Number"
                                    type="text"
                                    name="card_number"
                                    value={formik.values.card_number}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'card_number',
                                            cardNumberFormat(e.target.value),
                                        )
                                    }
                                />
                                {formik.errors.card_number && formik.touched.card_number ? (
                                    <p className="err">{formik.errors.card_number}</p>
                                ) : null}
                            </div>
                            <div className="col-6 input-left">
                                <CustomInput
                                    placeholder="Expiry"
                                    label="Expiry"
                                    type="text"
                                    name="exp_date"
                                    value={formik.values.exp_date}
                                    onChange={(e) =>
                                        formik.setFieldValue('exp_date', dateFormat(e.target.value))
                                    }
                                />
                                {formik.errors.exp_date && formik.touched.exp_date ? (
                                    <p className="err">{formik.errors.exp_date}</p>
                                ) : null}
                            </div>
                            <div className="col-6 input-left">
                                <CustomInput
                                    placeholder="Cvv"
                                    value={formik.cvv}
                                    label="Cvv"
                                    type="text"
                                    name="cvv"
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.cvv && formik.touched.cvv ? (
                                    <p className="err">{formik.errors.cvv}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="paymt-save">
                            <PrimaryButton label="Save" type="submit" />
                        </div>
                    </form>
                </div>
            </Popup>
        </DashboardLayout>
    )
}
export default BuyerPayment
