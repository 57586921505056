import React from 'react'
import './socialIcon.css'
import Link from '@material-ui/core/Link'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSnackbar } from 'notistack'

function Index({ copyLink }) {
    const { enqueueSnackbar } = useSnackbar()
    return (
        <div className="socialIconGrp">
            <Link to="">
                <object
                    aria-label="facebook"
                    type="image/svg+xml"
                    data="/assets/svg/facebooklico.svg"
                ></object>
            </Link>
            <Link to="">
                <object
                    aria-label="reddit"
                    type="image/svg+xml"
                    data="/assets/svg/reddit.svg"
                ></object>
            </Link>
            <Link to="">
                <object
                    aria-label="twitter"
                    type="image/svg+xml"
                    data="/assets/svg/twitter.svg"
                ></object>
            </Link>
            <Link to="">
                <object
                    aria-label="envelope"
                    type="image/svg+xml"
                    data="/assets/svg/envelope.svg"
                ></object>
            </Link>
            <Link to="">
                <CopyToClipboard
                    text={copyLink}
                    onCopy={() => enqueueSnackbar('Link copied!', { variant: 'success' })}
                >
                    <span className="material-icons link-refer-cpy">content_copy</span>
                </CopyToClipboard>
            </Link>
        </div>
    )
}

export default Index
