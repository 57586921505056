import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'

import WelcomeHeader from '../../components/organisms/WelcomeHeader'
import './login.css'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import CheckBox from '../../components/atoms/CheckBox'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import loginSchema from './loginSchema'
import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import JwtDecode from 'jwt-decode'
const Login = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const history = useHistory()
    const { search } = useLocation()
    const [userInformation, setUserInfomation] = useState({
        userName: '',
        password: '',
        remberMe: false,
    })

    const userToken = JSON.parse(localStorage.getItem('userToken'))

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const formik = useFormik({
        initialValues: userInformation,
        enableReinitialize: true,
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            try {
                const data = {
                    username: values.userName,
                    password: values.password,
                }
                const res = await request('post', apiEndPoint.login, data)
                if (formik.values.remberMe) {
                    localStorage.setItem('userInfo', JSON.stringify(data))
                } else {
                    localStorage.removeItem('userInfo')
                }
                if (res.data.status === 'yes') {
                    localStorage.setItem('coachToken', res.data.data)
                    localStorage.setItem('coachUser', JSON.stringify(JwtDecode(res.data.data)))
                    enqueueSnackbar(res.data.message, { variant: 'success' })
                    if (search) {
                        let params = new URLSearchParams(search)
                        return history.push(params.get('return'))
                    }
                    return history.push('/')
                }
                enqueueSnackbar(res.data.message, { variant: 'error' })
            } catch (error) {
                console.log(error)
            }
        },
    })

    useEffect(() => {
        if (userInfo && typeof userInfo !== 'undefined') {
            setUserInfomation({
                userName: userInfo.username,
                password: userInfo.password,
                remberMe: true,
            })
        }
    }, [])

    return (
        <WelcomeHeader>
            <div className="login">
                <h5 className="light-white my-3 welcome-heading">Welcome to Coach & Gavel !</h5>
                <div className="login-container-area">
                    <h4 className="light-white">Log In</h4>
                    <div className="login-content">
                        <form onSubmit={formik.handleSubmit}>
                            <CustomInput
                                label="Email"
                                type="text"
                                inputClassName="input-login login-user-name"
                                labelClass="login-user-name-label light-white"
                                placeholder="Email"
                                onChange={formik.handleChange}
                                name="userName"
                                value={formik.values.userName}
                                onBlur={formik.handleBlur}
                                helperText={
                                    formik.touched.userName && formik.errors.userName
                                        ? formik.errors.userName
                                        : null
                                }
                                error={formik.touched.userName && formik.errors.userName}
                            />
                            <CustomInput
                                label="Password"
                                type="password"
                                inputClassName="input-login login-password"
                                labelClass="login-password-label light-white"
                                placeholder="Password"
                                onChange={formik.handleChange}
                                name="password"
                                value={formik.values.password}
                                helperText={
                                    formik.touched.password && formik.errors.password
                                        ? formik.errors.password
                                        : null
                                }
                                error={formik.touched.password && formik.errors.password}
                            />
                            <div className="login-confirm-area">
                                <div className="login-remember-me">
                                    <CheckBox
                                        label="Remember Me"
                                        checked={formik.values.remberMe}
                                        name="remberMe"
                                        onclick={formik.handleChange}
                                    />
                                </div>
                                <div className="login-button">
                                    <PrimaryButton
                                        customClassName="login-confirm-btn "
                                        label="Log In"
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="login-forgetpswrd">
                        <h6 className="haveAnAcc">
                            Not A C&G Member Yet ? <Link to="/registration_step_1">Join here</Link>{' '}
                        </h6>
                        <h6 className="frgtpswrd">
                            {' '}
                            <Link to="/forgot_password">Forgot Password?</Link>
                        </h6>
                    </div>
                </div>
            </div>
        </WelcomeHeader>
    )
}

export default Login
