import React, { useState, useEffect, Fragment, useRef } from 'react'
import BreadCrumbs from '../../components/atoms/BreadCrumbs'
import './ProductView.css'
import { callNumber } from '../../Utils/common'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import SocialIcon from '../../components/atoms/SocialIcon'
import CustomTextArea from '../../components/atoms/Inputs/CustomTextArea'
import BidHistory from '../../components/atoms/BidHistory'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { request } from '../../Utils/Request'
import { useHistory } from 'react-router-dom'
import { dateTimeFormat } from '../../Utils/Constants'
import Timer from '../../Utils/Timer'
import moment from 'moment'
import { isAuthenticated } from '../../Utils/Auth'
import { bidAddtime, realClosedUpdates, chatmessages } from '../../Utils/Socket'
import JwtDecode from 'jwt-decode'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import { useSnackbar } from 'notistack'
import { Star } from '@material-ui/icons'
import { connect } from 'react-redux'
import { addTimer } from '../../Utils/Redux/Store/Actions/timer'
import Popup from '../../components/molecules/Popup'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import ReactHtmlParser from 'react-html-parser'
import ReactPlayer from 'react-player'
import publicIp from 'public-ip'
import CarFax from './CarFax'
import Loader from '../../components/organisms/Loader'

const mapStateToProps = (state) => {
    return {
        timer: state.Timer.timer || localStorage.getItem('prodtimer'),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        changeTimer: (value) => dispatch(addTimer(value)),
    }
}
var check_bid = 0
const ProductViewComponent = (props) => {
    var bidArrayCount = 0
    var comment_length = 0
    var bid_history = []
    const fileimageupload = useRef(null)
    const [images, setImages] = useState([])
    const [comment_images, setCommentImages] = useState([])
    const [photoGallery, setPhotoGallery] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const [id] = useState(props.match.params.id)
    const [checked, setChecked] = useState(0)
    const [next_bid, setNextBid] = useState('')
    const [Comments_length, setCommentLength] = useState(0)
    const [Comments_socket_length, setSocketCommentLength] = useState(0)
    const [user_id] = useState(isAuthenticated() ? JwtDecode(isAuthenticated()).id : 0)
    const [email] = useState(isAuthenticated() ? JwtDecode(isAuthenticated()).email : '')
    let first_name = ''
    const [userDetails, setUserDetails] = useState({})
    const [userNameVal, setUserNameVal] = useState('')
    const [isSeller, setIsSeller] = useState(false)
    const [commentLoader, setCommentLoader] = useState(false)
    const [bidAmount, setBidAmount] = useState(null)
    const [bid_or_not, setbid_or_not] = useState(0)
    const [bid_popup_open, setOpenBidPopup] = useState(false)
    const [bid_type, setBidType] = useState(0)
    const [bidApi, setBidApi] = useState({ data: [], bid_count: '' })
    const [state, setState] = useState({
        product_details: {},
        bid_details: {},
        top_message: '',
        extra_fields: [],
        new_fields: {},
    })
    const [headings] = useState([
        { title: 'Noteworthy Equipment', sub: 'Noteworthy Equipment' },
        { title: 'Modifications', sub: 'Modification' },
        { title: 'Issues & Flaws', sub: 'Issue/Flaw' },
        { title: 'Service History', sub: 'Service History Note' },
        { title: 'Addtitonal Items Included', sub: 'Additional Item Included' },
    ])
    const [bidLoader, setBidLoader] = useState(false)
    const [bidCount, setBidCount] = useState(0)
    const [highBidder, setHighBidder] = useState('')
    const [dateClosed, setDateClosed] = useState(null)
    const [openSeller, setOpenSeller] = useState(false)
    const [isMessageError, setIsMessageError] = useState(false)
    const [imageAttach, setImageAttach] = useState({ selectedFile: [] })
    const [comments, setComments] = useState({
        from: 0,
        data: [],
        to: 10,
        limit: 10,
        length: 0,
    })
    const [totalComments, setTotalComments] = useState([])
    const [message, setMessage] = useState('')
    const [sellerMessage, setSellerMessage] = useState({
        messages: '',
        subject: '',
    })
    const [productSlide, setProductSlide] = useState(false)
    const [individualImg, setIndividualImg] = useState(false)
    const [loading, setLoading] = useState(true)
    const [options, setOptions] = useState({
        id: '',
        escKey: false,
    })
    var b = 0,
        rprice,
        bid_socket_count = 0

    const history = useHistory()

    const handlechangeFile = (e) => {
        const { files } = e.target
        {
            files.length &&
                files.map((val) => {
                    comment_images.push(val)
                })
        }
        setCommentImages((prevState) => [...prevState, ...comment_images])
    }

    const moveonnext = (e) => {
        if (isAuthenticated()) {
            // window.scrollTo(4400, 4400)
            window.location.href = window.location.href + '#bidding'
        } else {
            props.history.push('/registration_step_1')
        }
    }

    const sellerMessageChange = (e) => {
        const { value, name } = e.target
        setSellerMessage({
            ...sellerMessage,
            [name]: value,
        })
    }

    const viewIndividualImage = (item, showThumbnail) => {
        setIndividualImg(true)
        let findImage = images.findIndex((ele) => ele.src === item)
        setOptions({
            ...options,
            id: findImage,
        })
        if (showThumbnail) {
            individualImageContent(item)
        }
    }

    const individualImgPopupClose = () => {
        setIndividualImg(false)
    }

    const individualImageContent = (item) => {
        // let filteredItem = images.find((ele) => ele.src === item)

        // if (filteredItem && typeof filteredItem !== 'undefined')
        return <img src={item.thumbnail} alt="slide omg" width={120} height={90} />
    }
    const sendSellerMessage = async () => {
        try {
            if (!sellerMessage.subject || !sellerMessage.messages) {
                return enqueueSnackbar('Kindly fill the all details!', {
                    variant: 'error',
                })
            }
            const contactData = {
                from_email: email,
                to_email: state.product_details.seller_email,
                name: userNameVal,
                to_name: ' ',
                phone_number: userDetails.phone,
                subject: sellerMessage.subject,
                messages: sellerMessage.messages,
                is_send_email: 1,
            }
            const res = await request('post', '/send_contactus', contactData)
            if (res.data.success) {
                setSellerMessage({
                    subject: '',
                    messages: '',
                })
                setOpenSeller(false)
                enqueueSnackbar('Message sent successfully!', {
                    variant: 'success',
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleImageUpload = (event) => {
        setImageAttach({
            selectedFile: [...imageAttach.selectedFile, ...event.target.files],
        })
    }
    const getProductMessages = async () => {
        try {
            setCommentLoader(true)
            const res = await request('post', '/get_product_messages', { pid: id, type: 'product' })
            if (res.data.success) {
                var reverse = res.data.live_chat.reverse().map((val) => {
                    return val
                })
                // res.data.live_chat.length > 10
                //     ? res.data.live_chat.reverse().slice(comments.from, comments.to)
                //     : res.data.live_chat.reverse()
                // if (res.data.live_chat.length > 10) {
                //     //reverse = res.data.live_chat.reverse()
                //     setComments({
                //         ...comments,
                //         length: res.data.live_chat.length,
                //         data: reverse.slice(comments.from, comments.to),
                //     })
                // } else {
                //     // reverse = res.data.live_chat.reverse()
                //     setComments({
                //         ...comments,
                //         length: res.data.live_chat.length,
                //         data: reverse,
                //     })
                // }
                setComments({
                    ...comments,
                    length: res.data.live_chat.length,
                    data: reverse,
                })
                setTotalComments(reverse)
            }
            setCommentLoader(false)
        } catch (error) {
            setCommentLoader(false)
            console.log(error)
        }
    }
    const viewMore = () => {
        setComments((prev) => ({
            ...prev,
            data: [
                ...prev.data,
                ...totalComments.slice(prev.from + prev.limit, prev.to + prev.limit),
            ],
            from: prev.from + prev.limit,
            to: prev.to + prev.limit,
        }))
    }

    useEffect(() => {
        if (Comments_length == 0 && totalComments.length > 0) {
            setCommentLength(totalComments.length)
        }
    }, [totalComments])

    const sendMessage = async (msg, mail = 'buyer', u_id = 0) => {
        try {
            if (msg === '') {
                setIsMessageError(true)
                return
            }

            setIsMessageError(false)
            const data = {
                from_id: u_id ? u_id : user_id,
                to_id: '0',
                to_email:
                    parseInt(user_id) === parseInt(state.product_details.user_id) ? 'seller' : mail,
                message: msg,
                pro_id: id,
                type: 'product',
                // image: imageAttach.selectedFile,
                isImage: Boolean(imageAttach.selectedFile.length) ? true : false,
            }
            const fromData = new FormData()
            for (const [key, value] of Object.entries(data)) {
                if (key === 'isImage') {
                    if (value) {
                        fromData.set(key, value)
                    }
                } else {
                    fromData.set(key, value)
                }
            }
            if (Boolean(imageAttach.selectedFile.length)) {
                imageAttach.selectedFile.map((value) => fromData.append('image', value))
            }
            const res = await request('post', '/product_message', fromData, true)
            if (res.data.success) {
                setMessage('')
                setImageAttach({ selectedFile: [] })
                return true
            }
            return false
        } catch (error) {
            console.log(error)
        }
    }
    const getProductDetails = async (product_id) => {
        try {
            try {
                const ip = await publicIp.v4()
                await request('post', '/insertProductViewed', {
                    project_id: product_id,
                    user_id: ip,
                })
            } catch (error) {
                console.log(error)
            }
            const res = await request('post', '/getprodetails', { product_id }, true)
            if (res.data.success === 'yes') {
                if (res.data.biddetails.bidtopwinner) {
                    if (
                        !res.data.biddetails.bidtopstatus.includes('!') &&
                        !res.data.biddetails.bidtopstatus.includes('unfortunately,')
                    ) {
                        let reserveCheck = res.data.biddetails.bidtopstatus.split('. ')
                        if (reserveCheck.length > 1) {
                            var autobid = res.data.biddetails.bidtopstatus
                                .split('. ')[0]
                                .replace(/[^\d.]/g, '')
                            var autobid1 = res.data.biddetails.bidtopstatus
                                .split('. ')[1]
                                .replace(/[^\d.]/g, '')
                            if (parseInt(autobid) < parseInt(res.data.results.rprice)) {
                                if (parseInt(autobid) === parseInt(autobid1)) {
                                    res.data.biddetails.bidtopstatus = `Your bid of $${parseInt(
                                        autobid1,
                                    ).toLocaleString()} on this item placed, but reserve price not yet met.`
                                } else {
                                    res.data.biddetails.bidtopstatus = `Your bid of $${parseInt(
                                        autobid,
                                    ).toLocaleString()} and auto bid of $${parseInt(
                                        autobid1,
                                    ).toLocaleString()} on this item placed, but reserve price not yet met.`
                                }
                            } else {
                                if (parseInt(autobid) === parseInt(autobid1)) {
                                    res.data.biddetails.bidtopstatus = `You are winning this item at $${parseInt(
                                        autobid1,
                                    ).toLocaleString()}.`
                                } else {
                                    res.data.biddetails.bidtopstatus = `You are winning this item at $${parseInt(
                                        autobid,
                                    ).toLocaleString()}. Your autoBid of $${parseInt(
                                        autobid1,
                                    ).toLocaleString()} has been placed successfully.`
                                }
                            }
                        }
                    } else {
                        if (res.data.biddetails.bidtopstatus.includes('losing')) {
                            res.data.biddetails.bidtopstatus = `You've been outbid on this item. Current leading bid is $${parseInt(
                                res.data.results.latestbid,
                            ).toLocaleString()}`
                        } else if (res.data.biddetails.bidtopstatus.includes('unfortunately,')) {
                            res.data.biddetails.bidtopstatus = `unfortunately you lost this item`
                        } else if (res.data.biddetails.bidtopstatus.includes('!')) {
                            res.data.biddetails.bidtopstatus = `Congratulations, you have bought this item for $${parseInt(
                                res.data.results.latestbid,
                            ).toLocaleString()}`
                        }
                    }
                } else {
                    if (res.data.biddetails.bidtopstatus.includes('losing')) {
                        res.data.biddetails.bidtopstatus = `You've been outbid on this item. Current leading bid is $${parseInt(
                            res.data.results.latestbid,
                        ).toLocaleString()}`
                    }
                }
                var attachments = []
                var gallery = []
                var attachments_length = res.data.attachments.length
                res.data.attachments.map((value, key) => {
                    attachments.push({
                        original: `${process.env.REACT_APP_PRODUCT_URL}/${value.src}`,
                        thumbnail: `${process.env.REACT_APP_PRODUCT_URL}/${value.src}`,
                        originalClass: 'popupSlider',
                        src: `${process.env.REACT_APP_PRODUCT_URL}/${value.src}`,
                        w: 1200,
                        h: 900,
                    })
                    gallery.push(`${process.env.REACT_APP_PRODUCT_URL}/${value.src}`)
                })
                res.data.results.custom_field_8 = res.data.results.custom_field_8.includes('{')
                    ? JSON.parse(res.data.results.custom_field_8)
                    : res.data.results.custom_field_8
                // if (attachments_length >= 4) {
                //     attachments.splice(-2)
                // }

                setImages(attachments)
                setPhotoGallery(gallery)

                setNextBid(res.data.biddetails.next_bid)
                setState({
                    ...state,
                    product_details: res.data.results,
                    bid_details: res.data.biddetails,
                    top_message: res.data.biddetails.bidtopstatus,
                    extra_fields: res.data.results.content_head2.includes('{')
                        ? JSON.parse(res.data.results.content_head2)
                        : {},
                    new_fields: res.data.results.norefund.includes('{')
                        ? JSON.parse(res.data.results.norefund)
                        : {},
                })
                b = parseInt(res.data.results.cbidnot)
                rprice = parseInt(res.data.results.rprice)
                setbid_or_not(parseInt(res.data.results.cbidnot))
                setDateClosed(res.data.results.date_closed)
                setChecked(res.data.results.wlistpop)
                setIsSeller(parseInt(res.data.results.user_id) === parseInt(user_id))
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBidDetails = async (product_id, place_bid = 0) => {
        try {
            const res = await request('post', '/getbiddetails', { product_id }, true)
            if (res.data.success === 'yes') {
                // var old_message_array = bid_history
                setBidApi({
                    ...bidApi,
                    data: res.data.results,
                    bid_count: res.data.results.length,
                })
                // bid_history = res.data.results
                // if (place_bid == 0) {
                //     setbidArrayCount(res.data.results.length)
                //     console.log('rrrrrrrrrrrrrrrrrrrrrr', bidArrayCount)
                // }
                setBidCount(res.data.results.length)
                setHighBidder(res.data.results[0].username)
                if (place_bid && check_bid == 1) {
                    var messageArray = res.data.results.slice(
                        0,
                        res.data.results.length - bidArrayCount,
                    )
                    messageArray.reverse()
                    // .map(
                    //     async (val) =>
                    //         await sendMessage(
                    //             `$${parseInt(
                    //                 val.realproposed_amount,
                    //             ).toLocaleString()} placed by ${val.username}`,
                    //             'bidhistory',
                    //             val.user_id,
                    //         ),
                    // )
                    var index = 0
                    while (index < messageArray.length) {
                        const element = messageArray[index]
                        // await sendMessage(
                        //     `$${parseInt(element.realproposed_amount).toLocaleString()} placed by ${
                        //         element.username
                        //     }`,
                        //     'bidhistory',
                        //     element.user_id,
                        // )
                        index++
                        // console.log(index)
                    }
                    check_bid = 0

                    // var new_message_array = res.data.results
                    // var ids = old_message_array.map((val) => val.bidid)

                    // var messageArray = new_message_array.filter((val) => !ids.includes(val.bidid))
                    // console.log(messageArray, old_message_array, bidApi.data)
                    // messageArray
                    //     .reverse()
                    //     .map(
                    //         async (val) =>
                    //             await sendMessage(
                    //                 `$${parseInt(
                    //                     val.realproposed_amount,
                    //                 ).toLocaleString()} placed by ${val.username}`,
                    //                 'bidhistory',
                    //                 val.user_id,
                    //             ),
                    //     )
                    // check_bid = 0
                }
                bidArrayCount = bid_socket_count
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!props.match.params.id) {
            return history.push('/')
        }
        getProductDetails(id)
        getBidDetails(id)
        getProductMessages()
        getUserProfile()
        setUserDetails(isAuthenticated() ? JwtDecode(isAuthenticated()) : {})
    }, [])

    const getUserProfile = async () => {
        try {
            const res = await request('post', '/getUserProfileDetails', {}, true)
            if (res.data.success === 'yes') {
                var user = res.data.data
                first_name = res.data.data.username
                setUserNameVal(res.data.data.username)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        chatmessages((data) => {
            if (parseInt(data.messages.pro_id) === parseInt(id)) {
                setComments((prev) => ({ ...prev, data: [data.messages, ...prev.data] }))
                setTotalComments((prev) => [data.messages, ...prev])
                comment_length = comment_length + 1
                setSocketCommentLength(comment_length)
            }
        })
        realClosedUpdates((data) => {
            const project_id = id
            if (data.pid === parseInt(project_id)) {
                getProductDetails(project_id)
            }
        })
        bidAddtime((data) => {
            if (parseInt(data.id) === parseInt(id)) {
                if (parseInt(data.bpop_belowFive) === 1) {
                    setDateClosed(data.bpop_belowFiveIncrement)
                }
                if (data.status === 'failed' && parseInt(user_id) === parseInt(data.bpop_cbidder)) {
                    return enqueueSnackbar(data.error, { variant: 'error' })
                }
                if (data.status !== 'failed') {
                    // if (bidArrayCount == 0) {
                    //     bidArrayCount = data.bpop_bidcount - 1
                    // }
                    // bid_socket_count = data.bpop_bidcount
                    if (parseInt(user_id) === parseInt(data.bpop_cbidder)) {
                        //getBidDetails(id, 1)
                        enqueueSnackbar(data.bpop_error, { variant: 'success' })
                        // sendMessage(
                        //     `$${parseInt(data.bidAmount).toLocaleString()} placed by ${first_name}`,
                        //     'bidhistory',
                        // )
                        if (parseInt(user_id) === parseInt(data.bpop_higher)) {
                            if (parseInt(rprice) <= parseInt(data.bpop_wprice)) {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toLocaleString()}. `
                                } else {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toLocaleString()}. Your autoBid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toLocaleString()} has been placed successfully.`
                                }
                            } else {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toLocaleString()} on this item placed, but reserve price not yet met.`
                                } else {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toLocaleString()} and auto bid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toLocaleString()} on this item placed, but reserve price not yet met.`
                                }
                            }
                            if (bidArrayCount == 0) {
                                bidArrayCount = data.bpop_bidcount - 1
                            }
                        } else if (data.bpop_bidstatus.includes('losing')) {
                            if (bidArrayCount == 0) {
                                bidArrayCount = data.bpop_bidcount - 2
                            }
                            data.bpop_bidstatus = `You've been outbid on this item. Current leading bid is $${data.bpop_wprice}`
                        }
                        bid_socket_count = data.bpop_bidcount
                        // getBidDetails(id, 1)
                        setNextBid(data.bpop_cuser_nextbid)
                        setBidAmount('')
                        setState((prevState) => ({
                            ...prevState,
                            product_details: {
                                ...prevState.product_details,
                                latestbid: data.bpop_wprice,
                            },
                            bid_details: {
                                ...prevState.bid_details,
                                bidtopstatus: data.bpop_bidstatus,
                            },
                        }))
                        setbid_or_not(1)
                        b = 1
                        setBidApi({
                            ...bidApi,
                            bid_count: data.bpop_bidcount,
                        })
                    } else {
                        bidArrayCount = data.bpop_bidcount
                        if (parseInt(bid_or_not) > 0 || parseInt(b) > 0) {
                            if (parseInt(user_id) === parseInt(data.bpop_higher)) {
                                if (parseInt(next_bid) < parseInt(data.bpop_nextbid)) {
                                    var text = ''
                                    if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                        text = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toLocaleString()}.`
                                    } else {
                                        text = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toLocaleString()}. Your autoBid of $${parseFloat(
                                            data.bpop_wprice_morehigh,
                                        ).toLocaleString()} has been placed successfully.`
                                    }
                                    setNextBid(data.bpop_nextbid_org)
                                    setState((prevState) => ({
                                        ...prevState,
                                        product_details: {
                                            ...prevState.product_details,
                                            latestbid: data.bpop_wprice,
                                        },
                                        bid_details: {
                                            ...prevState.bid_details,
                                            bidtopstatus: text,
                                        },
                                    }))
                                    setBidApi({ ...bidApi, bid_count: data.bpop_bidcount })
                                    setbid_or_not(1)
                                } else {
                                    var msg
                                    if (parseInt(rprice) <= parseInt(data.bpop_wprice)) {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            msg = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toLocaleString()}.`
                                        } else {
                                            msg = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toLocaleString()}. Your autoBid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toLocaleString()} has been placed successfully.`
                                        }
                                    } else {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            msg = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toLocaleString()} on this item placed, but reserve price not yet met.`
                                        } else {
                                            msg = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toLocaleString()} and auto bid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toLocaleString()} on this item placed, but reserve price not yet met.`
                                        }
                                    }
                                    setState((prevState) => ({
                                        ...prevState,
                                        product_details: {
                                            ...prevState.product_details,
                                            latestbid: data.bpop_wprice,
                                        },
                                        bid_details: {
                                            ...prevState.bid_details,
                                            bidtopstatus: msg,
                                        },
                                    }))
                                    setBidApi({ ...bidApi, bid_count: data.bpop_bidcount })
                                }
                            } else {
                                setNextBid(data.bpop_nextbid_org)
                                setState((prevState) => ({
                                    ...prevState,
                                    product_details: {
                                        ...prevState.product_details,
                                        latestbid: data.bpop_wprice,
                                    },
                                    bid_details: {
                                        ...prevState.bid_details,
                                        bidtopstatus: `You've been outbid on this item. Current leading bid is $${data.bpop_wprice}`,
                                    },
                                }))
                                setBidApi({ ...bidApi, bid_count: data.bpop_bidcount })
                                setbid_or_not(1)
                            }
                        } else {
                            setNextBid(data.bpop_nextbid_org)
                            setState((prevState) => ({
                                ...prevState,
                                product_details: {
                                    ...prevState.product_details,
                                    latestbid: data.bpop_wprice,
                                },
                            }))
                            setBidApi({ ...bidApi, bid_count: data.bpop_bidcount })
                        }
                    }
                }
            } else {
                console.log('whole else')
            }
            getProductMessages()
        })
    }, [])
    const handleRemoveImg = (index, key) => {
        imageAttach.selectedFile.splice(imageAttach.selectedFile.indexOf(index), 1)
        setImageAttach({ selectedFile: [...imageAttach.selectedFile] })
    }
    const confirm_popup = (hardBid, openpopup) => {
        if (parseFloat(next_bid) <= parseFloat(bidAmount)) {
            if (openpopup) {
                setBidType(hardBid)
                setOpenBidPopup(true)
            } else {
                setOpenBidPopup(false)
                placeBid(hardBid)
            }
        } else {
            setBidLoader(false)
            enqueueSnackbar('Bid $' + next_bid + ' or more', {
                variant: 'error',
            })
        }
    }
    const placeBid = async (hardBid) => {
        try {
            check_bid = 1
            if (String(localStorage.getItem('prodtimer')) === 'true') {
                return enqueueSnackbar('Wait until auction start!', {
                    variant: 'info',
                })
            }
            if (parseFloat(next_bid) <= parseFloat(bidAmount)) {
                let body = {
                    id: id,
                    wsprice: bidAmount,
                    userid: user_id,
                    email: email,
                    hard_bid: hardBid ? 1 : undefined,
                    insert_msg: 1,
                }
                const res = await request('post', '/mobileconfirmForward', body, true)

                setBidLoader(false)
            } else {
                setBidLoader(false)
                enqueueSnackbar('Bid $' + next_bid + ' or more', {
                    variant: 'error',
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const watchlist = async () => {
        try {
            if (!isAuthenticated()) {
                enqueueSnackbar('Kindly login to add watchlist!', {
                    variant: 'info',
                })
                return history.push(`/login?return=${window.location.pathname}`)
            }
            const data = {
                product_id: id,
                watchlistemail: 1,
            }
            const res = await request(
                'post',
                !checked ? '/add_watchlist' : '/remove_watchlist',
                data,
                true,
            )
            if (res.data.status === 'yes') {
                enqueueSnackbar(
                    !checked
                        ? 'Product added to watchlist successfully!'
                        : 'Product removed from watchlist successfully!',
                    {
                        variant: 'success',
                    },
                )
                setChecked(!checked)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const [open, setOpen] = React.useState(false)
    const handleClosePopup = () => {
        setProductSlide(false)
    }

    const handleOpenPopup = () => {
        setProductSlide(true)
    }
    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="customContainer whiteBg">
                    <div className="productViewbox">
                        <BreadCrumbs />
                        <div className="titBox nwVwWrp">
                            <div className="otrWrapper">
                                <div className="lftContainer subTit">
                                    <h4 className="text-left mb-3 pdtTtle">
                                        {state.product_details.tags} {state.product_details.title}{' '}
                                        {state.product_details.category_id}
                                    </h4>
                                    <div className="d-flex align-items-center lftTxtContainer">
                                        {parseInt(state.product_details.rprice) === 0 && (
                                            <div className="noRsrvTxt mr-3">No Reserve</div>
                                        )}
                                        {state.product_details.market_status === 'open' ? (
                                            <small>
                                                {state.product_details.latestbid
                                                    ? 'CURRENT BID'
                                                    : 'CURRENT BID'}{' '}
                                                :{' '}
                                                <b>
                                                    {' '}
                                                    $
                                                    {parseInt(
                                                        state.product_details.latestbid
                                                            ? state.product_details.latestbid
                                                            : state.product_details.sprice,
                                                    ).toLocaleString()}{' '}
                                                </b>{' '}
                                                &nbsp;{' '}
                                                <span className="timer">
                                                    <Timer
                                                        date_closed={dateClosed}
                                                        date_added={
                                                            state.product_details.date_added
                                                        }
                                                        withText={1}
                                                    />
                                                </span>
                                                {/* ENDS IN :
                                    <b>
                                        <span> 1:02:03</span>
                                    </b> */}
                                            </small>
                                        ) : (
                                            <small className="so-wrap">
                                                {state.product_details.market_status ===
                                                'closed' ? (
                                                    <span className="sold-for">UNSOLD</span>
                                                ) : (
                                                    <>
                                                        <span className="sold-for">
                                                            SOLD FOR :
                                                            <b> ${state.product_details.wprice} </b>
                                                        </span>
                                                        <span className="sold-on"> ON : </span>
                                                        <b>
                                                            {' '}
                                                            {moment(
                                                                state.product_details.date_closed,
                                                            ).format(dateTimeFormat)}{' '}
                                                        </b>
                                                    </>
                                                )}
                                            </small>
                                        )}
                                        {parseInt(state.product_details.rprice) <=
                                            state.product_details.latestbid &&
                                        parseInt(state.product_details.rprice) != 0 ? (
                                            <span className="yesRsrvTxt mr-3">Reserve Met</span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className="rightContainer">
                                    {/* <span onClick={handleClickOpen} className="carfax-btn">
                                    <img src={'/assets/svg/car_fax.svg'} alt="car-fax" />
                                </span> */}
                                    <div className="btnCombo btn-combo-list my-1">
                                        <SecondaryButton
                                            label="Bid Now!"
                                            onClick={moveonnext}
                                            customClassName="confirm-btn"
                                        />
                                        <Fragment>
                                            <SecondaryButton
                                                label="How It Works"
                                                customClassName="confirm-btn ml-1"
                                                onClick={(e) => {
                                                    props.history.push('/howitworks')
                                                }}
                                            />
                                        </Fragment>
                                        {isAuthenticated() && !isSeller && (
                                            <Fragment>
                                                {state.product_details.market_status ===
                                                'sold' ? null : (
                                                    <SecondaryButton
                                                        label={
                                                            !checked
                                                                ? 'Add To Watchlist'
                                                                : 'Remove from watchlist'
                                                        }
                                                        customClassName="confirm-btn adWtchLst mx-1"
                                                        onClick={watchlist}
                                                    >
                                                        {!checked ? <StarBorderIcon /> : <Star />}
                                                    </SecondaryButton>
                                                )}
                                                {/* <PrimaryButton
                                            label="See Results"
                                            customClassName="confirm-btn"
                                        /> */}
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="subTit">
                                {/* <small>
                                    Current Bid : <b> $24,000 </b> Ends in :<b> 1/4/21 </b>
                                </small> */}
                                {/* <div className="buttons-product-view">
                                    <SecondaryButton
                                        label="How It Works"
                                        customClassName="confirm-btn"
                                    />
                                    <SecondaryButton label="Watch" customClassName="confirm-btn" />
                                    <PrimaryButton
                                        label="Bid & Comment"
                                        customClassName="confirm-btn"
                                    />
                                </div> */}
                                {/* {
                                    (Auction = !null ? (
                                        <>
                                            <SecondaryButton
                                                label="How it Works"
                                                customClassName="confirm-btn"
                                            />
                                            <SecondaryButton
                                                label="Watch"
                                                customClassName="confirm-btn"
                                            >
                                                <StarBorderIcon />
                                            </SecondaryButton>
                                        </>
                                    ) : (
                                        <PrimaryButton
                                            label="See Results"
                                            customClassName="confirm-btn"
                                        />
                                    ))
                                } */}
                            </div>
                        </div>
                        <div className="productCardWrapper nwVwWrp">
                            <ImageGallery
                                items={images}
                                showFullscreenButton={true}
                                showPlayButton={false}
                                lazyLoad={true}
                                showThumbnails={true}
                                thumbnailPosition={'left'}
                                slideOnThumbnailOver={true}
                            />
                        </div>
                        <div className="productCard">
                            <div className="productCarosal">
                                <div className="photoGallery">
                                    <h4 className="text-left mb-3">Photo Gallery</h4>
                                    <span className="span d-block">
                                        <PhotoSwipeGallery
                                            isOpen={individualImg}
                                            items={images}
                                            // options={options}
                                            thumbnailContent={individualImageContent}
                                            onClose={individualImgPopupClose}
                                        />

                                        {/* {photoGallery.slice(0, 11).map((data, index) => (
                                        <>
                                            {index + 1 !== 11 && (
                                                <a className="" onClick={handleOpenPopup}>
                                                    <figure className="m-0">
                                                        <img
                                                            alt="product-img"
                                                            src={data}
                                                            onClick={() =>
                                                                viewIndividualImage(data, true)
                                                            }
                                                        />
                                                    </figure>
                                                </a>
                                            )}

                                            {index + 1 === 11 && (
                                                <a className="lastEle" onClick={handleOpenPopup}>
                                                    <b> +{photoGallery.length - 10} </b>
                                                    <figure className="m-0">
                                                        <img
                                                            alt="product-img"
                                                            src={data}
                                                            onClick={() =>
                                                                viewIndividualImage(data, true)
                                                            }
                                                        />
                                                    </figure>
                                                </a>
                                            )}
                                        </>
                                    ))} */}
                                    </span>
                                    {parseInt(state.product_details.tags) >= 1981 && (
                                        <div className="mt-5 d-flex justify-content-center premiumPlus">
                                            <CarFax vin={state.product_details.modelnumber} />
                                        </div>
                                    )}
                                </div>

                                {/* {state.product_details.modelnumber && (
                                <div className="">
                                    <iframe
                                        scrolling="no"
                                        style={{ border: 'none', overflow: 'hidden' }}
                                        width="100%"
                                        height="600"
                                        src={`https://vini.az/en/api/report?key=dccb76e34bb18f7fa249753b1543dd38&vin=${state.product_details.modelnumber}&type=carfax`}
                                    ></iframe>
                                    <a
                                        href={`https://vini.az/en/api/report?key=dccb76e34bb18f7fa249753b1543dd38&vin=${state.product_details.modelnumber}&type=carfax`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        See Full CarFax Report
                                    </a>
                                </div>
                            )} */}
                            </div>
                            <div className="productInfo">
                                <div className="titBox">
                                    <div className="decCarosal">
                                        <h4 className="text-left mb-3">Description</h4>
                                        <p className="dscrptinWrpp m-0 text-left pr-5">
                                            {ReactHtmlParser(state.product_details.description)}
                                        </p>
                                    </div>

                                    <div className="essentialBox">
                                        <h4 className="text-left">Highlights</h4>
                                        <div className="essGraybox hgLghtsFld">
                                            <p className="ltNmbr">
                                                Lot <b># {state.product_details.id}</b>
                                            </p>
                                            <div className="cstmHltGrd">
                                                <ul>
                                                    <li>
                                                        <label>Seller:</label>
                                                        <b> {state.product_details.username}</b>
                                                    </li>
                                                    <li>
                                                        <label>Location:</label>
                                                        <b>
                                                            {`
                                                        ${state.product_details.location},
                                                        ${state.product_details.state},
                                                        ${state.product_details.city}`}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <label>Year of Vehicle:</label>
                                                        <b> {state.product_details.tags}</b>
                                                    </li>
                                                    <li>
                                                        <label>Vehicle Make:</label>
                                                        <b> {state.product_details.title}</b>
                                                    </li>
                                                    <li>
                                                        <label>Vehicle Model:</label>
                                                        <b> {state.product_details.category_id}</b>
                                                    </li>
                                                    <li>
                                                        <label>Vehicle Sold By:</label>
                                                        <b> {state.product_details.qcdby}</b>
                                                    </li>
                                                    <li>
                                                        <label>VIN# / Registration#: </label>
                                                        <b> {state.product_details.modelnumber}</b>
                                                    </li>
                                                    <li>
                                                        <label>Miles / Hours: </label>
                                                        <b>
                                                            {' '}
                                                            {callNumber(
                                                                state.product_details.content_head3
                                                                    ? state.product_details.content_head3
                                                                          .toString()
                                                                          .split(',')
                                                                          .join('')
                                                                    : '',
                                                            )}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <label>Body Style: </label>
                                                        <b> {state.new_fields.body_style}</b>
                                                    </li>
                                                    <li>
                                                        <label>Exterior Color: </label>
                                                        <b> {state.new_fields.exterior}</b>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <label>Interior Color: </label>
                                                        <b> {state.new_fields.interior}</b>
                                                    </li>
                                                    <li>
                                                        <label>Engine Size:</label>
                                                        <b>
                                                            {state.product_details.custom_field_6}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <label>Original Engine:</label>
                                                        <b>
                                                            {state.product_details
                                                                .custom_field_5 === '1'
                                                                ? 'Yes'
                                                                : 'No'}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <label>Drivetrain: </label>
                                                        <b> {state.new_fields.drive_train}</b>
                                                    </li>
                                                    <li>
                                                        <label>Transmission Type:</label>
                                                        <b>
                                                            {state.product_details.custom_field_8}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <label>Service Records Available:</label>
                                                        <b>
                                                            {state.product_details.sell_location ===
                                                            '1'
                                                                ? 'Yes'
                                                                : 'No'}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <label>Race Car:</label>
                                                        <b>
                                                            {state.product_details
                                                                .custom_field_4 === '2'
                                                                ? 'No'
                                                                : 'Yes'}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <label>Repaint:</label>
                                                        <b>
                                                            {state.product_details.custom_field_7}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        <label>Title Status: </label>
                                                        <b> {state.new_fields.title_status}</b>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="essGraybox">
                                            {/* <span className="pv-mw100">
                                            <ul>
                                                <li>Glass Sunroof</li>
                                                <li>Power-Adjustable Front Seats</li>
                                            </ul>
                                        </span> */}
                                            {isAuthenticated() && !isSeller && (
                                                <PrimaryButton
                                                    customClassName="confirm-btn my-3"
                                                    label="Contact Seller"
                                                    iconLeft={
                                                        <i className="material-icons">mail</i>
                                                    }
                                                    onClick={() => setOpenSeller(true)}
                                                ></PrimaryButton>
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end my-3">
                                            <p className="shrThLstngTxt text-right my-0 mr-3">
                                                Share This Listing
                                            </p>
                                            <SocialIcon copyLink={window.location.href} />
                                        </div>
                                    </div>
                                    <hr></hr>
                                    {state.extra_fields.length > 0 &&
                                        state.extra_fields.slice(0, 5).map((value, key) => (
                                            <div className="essentialBox adtnlCstmFlds">
                                                <h4 className="text-left">
                                                    {headings[key] && headings[key].title}
                                                </h4>
                                                <div className="essGraybox cstmGryBx">
                                                    <div>
                                                        <ul>
                                                            {Object.entries(value).map(
                                                                ([ObjKey, ObjValue]) =>
                                                                    ObjValue && <li>{ObjValue}</li>,
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    <div className="essentialBox">
                                        <h4 className="text-left mt-5">Ownership History</h4>
                                        <div className="essGraybox ownrShpHstry">
                                            <ul>
                                                <li>
                                                    Length of Ownership :
                                                    <b>{state.product_details.manufacturer}</b>
                                                </li>
                                                <li>
                                                    Miles/Hours Added By Current Owner :
                                                    <b>
                                                        {callNumber(
                                                            state.product_details.content_head4
                                                                ? state.product_details.content_head4
                                                                      .toString()
                                                                      .split(',')
                                                                      .join('')
                                                                : '',
                                                        )}
                                                    </b>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="essentialBox">
                                        <h4 className="text-left mt-5">Videos</h4>
                                        <div className="essGraybox vdoBox">
                                            {state.extra_fields.length > 1 && (
                                                <ul>
                                                    <li>
                                                        Exterior Video :
                                                        <b>
                                                            {state.extra_fields[5].url_1 ? (
                                                                <>
                                                                    <ReactPlayer
                                                                        width="100%"
                                                                        height="160px"
                                                                        light={true}
                                                                        url={
                                                                            state.extra_fields[5]
                                                                                .url_1
                                                                        }
                                                                        controls
                                                                    />
                                                                </>
                                                            ) : (
                                                                // <a
                                                                //     href={state.extra_fields[5].url_1}
                                                                //     target="_blank"
                                                                //     rel="noreferrer"
                                                                // >
                                                                //     {state.extra_fields[5].url_1}
                                                                // </a>
                                                                '-'
                                                            )}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        Interior Video :
                                                        <b>
                                                            {state.extra_fields[5].url_2 ? (
                                                                <ReactPlayer
                                                                    width="100%"
                                                                    height="160px"
                                                                    light={true}
                                                                    url={
                                                                        state.extra_fields[5].url_2
                                                                    }
                                                                    controls
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        Driving Video :
                                                        <b>
                                                            {state.extra_fields[5].url_3 ? (
                                                                <ReactPlayer
                                                                    width="100%"
                                                                    height="160px"
                                                                    light={true}
                                                                    url={
                                                                        state.extra_fields[5].url_3
                                                                    }
                                                                    controls
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        Engine Video :
                                                        <b>
                                                            {state.extra_fields[5].url_4 ? (
                                                                <ReactPlayer
                                                                    width="100%"
                                                                    height="160px"
                                                                    light={true}
                                                                    url={
                                                                        state.extra_fields[5].url_4
                                                                    }
                                                                    controls
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        Cold Start Video :
                                                        <b>
                                                            {state.extra_fields[5].url_5 ? (
                                                                <ReactPlayer
                                                                    width="100%"
                                                                    height="160px"
                                                                    light={true}
                                                                    url={
                                                                        state.extra_fields[5].url_5
                                                                    }
                                                                    controls
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </b>
                                                    </li>
                                                    <li>
                                                        {state.extra_fields[5].url_6 ? (
                                                            <>
                                                                Additional Video :
                                                                <b>
                                                                    <ReactPlayer
                                                                        width="100%"
                                                                        height="160px"
                                                                        light={true}
                                                                        url={
                                                                            state.extra_fields[5]
                                                                                .url_6
                                                                        }
                                                                        controls
                                                                    />
                                                                </b>
                                                            </>
                                                        ) : null}
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <div id="bidding" className="essentialBox bdngDtlsBox">
                                        <h4 className="text-left mt-5">
                                            {state.product_details.market_status === 'open'
                                                ? 'Bidding Details'
                                                : 'Auction Result'}
                                        </h4>
                                        <div className="essGraybox">
                                            <span>
                                                <ul>
                                                    <li>High Bid </li>
                                                </ul>
                                                <ul>
                                                    {bidApi.bid_count >= 1 ? (
                                                        <li>
                                                            USD $
                                                            {parseInt(
                                                                state.product_details.latestbid,
                                                            ).toLocaleString()}{' '}
                                                            by : <b>{highBidder}</b>
                                                        </li>
                                                    ) : (
                                                        <li>
                                                            <b>No bid placed</b>
                                                        </li>
                                                    )}
                                                </ul>
                                            </span>
                                        </div>
                                    </div>
                                    {isAuthenticated() && !isSeller && (
                                        <div className="essentialBox">
                                            <div className="essGraybox">
                                                <span>
                                                    <ul>
                                                        <li>Your Status </li>
                                                    </ul>
                                                    <ul>
                                                        <li
                                                            className={`text-${
                                                                state.bid_details.bidtopstatus
                                                                    ? state.bid_details.bidtopstatus.includes(
                                                                          'lost',
                                                                      ) ||
                                                                      state.bid_details.bidtopstatus.includes(
                                                                          'losing',
                                                                      ) ||
                                                                      state.bid_details.bidtopstatus.includes(
                                                                          'outbid',
                                                                      ) ||
                                                                      state.bid_details.bidtopstatus.includes(
                                                                          'reserve',
                                                                      )
                                                                        ? 'danger'
                                                                        : state.bid_details.bidtopstatus.includes(
                                                                              'winning',
                                                                          ) ||
                                                                          state.bid_details.bidtopstatus.includes(
                                                                              'proxy',
                                                                          )
                                                                        ? 'success'
                                                                        : 'warning'
                                                                    : null
                                                            }`}
                                                        >
                                                            {state.bid_details.bidtopstatus
                                                                ? state.bid_details.bidtopstatus
                                                                : '-'}
                                                        </li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="essentialBox">
                                        <div className="essGraybox">
                                            <span>
                                                <ul>
                                                    <li>
                                                        Auction{' '}
                                                        {state.product_details.market_status ===
                                                        'open'
                                                            ? 'Ending'
                                                            : 'Ended'}
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        {moment(
                                                            state.product_details.date_closed,
                                                        ).format(dateTimeFormat)}
                                                    </li>
                                                </ul>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="essentialBox">
                                        <div className="essGraybox">
                                            <span>
                                                <ul>
                                                    <li>Bids </li>
                                                </ul>
                                                <ul>
                                                    <li>{bidApi.bid_count}</li>
                                                </ul>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className="essentialBox">
                                <div className="essGraybox">
                                    <span>
                                        <ul>
                                            <li>Reserve Price </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                {parseFloat(state.product_details.rprice) >
                                                    parseFloat(
                                                        state.product_details.latestbid
                                                            ? state.product_details.latestbid
                                                            : state.product_details.wprice,
                                                    ) && ' Not'}{' '}
                                                Met
                                            </li>
                                        </ul>
                                    </span>
                                </div>
                            </div> */}
                                    {state.product_details.market_status === 'open' && (
                                        <div className="essentialBox">
                                            <div className="essGraybox">
                                                <span className="pv-mw100">
                                                    <ul>
                                                        <li>Place Bid </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            {isAuthenticated() ? (
                                                                !isSeller ? (
                                                                    <div className="row pvAct">
                                                                        <div className="col-12 input-boxes">
                                                                            <CustomInput
                                                                                inputClassName="mb-2"
                                                                                label=""
                                                                                placeholder={
                                                                                    next_bid
                                                                                        ? `Place $ ${parseFloat(
                                                                                              next_bid,
                                                                                          ).toLocaleString()} or more`
                                                                                        : '-'
                                                                                }
                                                                                labelClass="vch-id-label"
                                                                                name="bid"
                                                                                id="bid"
                                                                                value={bidAmount}
                                                                                size="small"
                                                                                onChange={(e) =>
                                                                                    setBidAmount(
                                                                                        e.target
                                                                                            .value,
                                                                                    )
                                                                                }
                                                                                pattern="([0-9]+(\.[0-9]+)?)"
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 bid-now-btn d-flex">
                                                                            <PrimaryButton
                                                                                // customClassName="mr-2"
                                                                                label="Bid Now"
                                                                                customClassName="mr-2 noGridBtn text-center"
                                                                                onClick={() =>
                                                                                    confirm_popup(
                                                                                        1,
                                                                                        1,
                                                                                    )
                                                                                }
                                                                            />
                                                                            {state.product_details
                                                                                .auctioncondition ===
                                                                                'yes' && (
                                                                                <PrimaryButton
                                                                                    label="Place Auto Bid"
                                                                                    customClassName="noGridBtn text-center"
                                                                                    onClick={() =>
                                                                                        confirm_popup(
                                                                                            0,
                                                                                            1,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    'You are not able to bid on your own product!'
                                                                )
                                                            ) : (
                                                                <PrimaryButton
                                                                    label="Regiser to Bid"
                                                                    customClassName="noGridBtn"
                                                                    onClick={() =>
                                                                        history.push(
                                                                            '/registration_step_1',
                                                                        )
                                                                    }
                                                                    disabled={bidLoader}
                                                                />
                                                            )}
                                                        </li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="essentialBox mb-4">
                                        <div className="essGraybox">
                                            <span className="pv-mw100">
                                                <ul>
                                                    {isAuthenticated() && !isSeller && (
                                                        <li className="biddingInformation">
                                                            <a
                                                                href="/howitworks"
                                                                style={{ color: 'inherit' }}
                                                            >
                                                                How It Works
                                                            </a>
                                                            {state.product_details.market_status ===
                                                            'sold' ? null : (
                                                                <>
                                                                    <span>|</span>
                                                                    <a onClick={watchlist}>
                                                                        {!checked ? (
                                                                            <StarBorderIcon
                                                                                style={{
                                                                                    width: '16px',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <Star
                                                                                style={{
                                                                                    width: '16px',
                                                                                }}
                                                                            />
                                                                        )}
                                                                        {!checked
                                                                            ? 'Add To Watchlist'
                                                                            : 'Remove From Watchlist'}
                                                                    </a>
                                                                </>
                                                            )}
                                                        </li>
                                                    )}
                                                </ul>
                                                <ul>
                                                    <li className="ttlViews">
                                                        {parseInt(
                                                            state.product_details.viwers,
                                                        ).toLocaleString()}{' '}
                                                        views |{' '}
                                                        {parseInt(
                                                            state.product_details.watchers,
                                                        ).toLocaleString()}{' '}
                                                        watchers{' '}
                                                    </li>
                                                </ul>
                                            </span>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    {state.product_details.upc === '1' ? (
                                        <div className="essentialBox">
                                            <h4>
                                                Sellers can attach and post additional images within
                                                the comments section
                                            </h4>
                                            <p className="text-left">
                                                {totalComments.length} Comments
                                            </p>
                                            <div className="comment-area">
                                                <CustomTextArea
                                                    placeholder={'Add a comment here'}
                                                    value={message}
                                                    Images={comment_images}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    disabled={
                                                        state.bid_details.bidtopstatus ||
                                                        (isAuthenticated() && isSeller)
                                                            ? false
                                                            : true
                                                    }
                                                />
                                                {isMessageError ? (
                                                    <p style={{ color: 'red', fontSize: '13px' }}>
                                                        Please enter valid message
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="submit-area justify-content-end">
                                                {/* <div className="conv-mail">
                                                <p className="conv-email-txt">
                                                    <a href="#">
                                                        Keep me in this conversation via email
                                                    </a>
                                                </p>
                                            </div> */}
                                                <div className="submit-btn">
                                                    {/* {isAuthenticated() && isSeller ? (
                                                    <Button variant="contained" component="label">
                                                        Upload Image
                                                        <input
                                                            onChange={handlechangeFile}
                                                            type="file"
                                                            hidden
                                                        />
                                                    </Button>
                                                ) : (
                                                    ''
                                                )} */}
                                                    {isAuthenticated() ? (
                                                        <div className="seller-comment-area">
                                                            {isAuthenticated() && isSeller && (
                                                                <div className="file-comment-img">
                                                                    <input
                                                                        type="file"
                                                                        onChange={handleImageUpload}
                                                                        style={{ display: 'none' }}
                                                                        ref={fileimageupload}
                                                                        accept="image/*"
                                                                        multiple
                                                                    />
                                                                    <AttachFileIcon
                                                                        className="img-attach"
                                                                        onClick={() =>
                                                                            fileimageupload.current.click()
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            <SecondaryButton
                                                                label={
                                                                    commentLoader
                                                                        ? 'Sending...'
                                                                        : 'submit'
                                                                }
                                                                customClassName="productViewSecontdry"
                                                                onClick={() => sendMessage(message)}
                                                                disabled={
                                                                    state.bid_details
                                                                        .bidtopstatus ||
                                                                    (isAuthenticated() &&
                                                                        isSeller) ||
                                                                    !commentLoader
                                                                        ? false
                                                                        : true
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <SecondaryButton
                                                            label="submit"
                                                            customClassName="productViewSecontdry"
                                                            onClick={() =>
                                                                history.push(
                                                                    '/login?return=' +
                                                                        window.location.pathname,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <h5>Comments are closed!</h5>
                                        </div>
                                    )}
                                    {/* Bid history section */}
                                    <div className="row pt-5">
                                        {Boolean(imageAttach.selectedFile.length) &&
                                            imageAttach.selectedFile.map((ele, key) => (
                                                <div className="col-md-3 mt-2">
                                                    <div className="pp-uimg">
                                                        <span
                                                            className="img-del "
                                                            onClick={() =>
                                                                handleRemoveImg(ele, key)
                                                            }
                                                        >
                                                            {' '}
                                                            X{' '}
                                                        </span>
                                                    </div>
                                                    <img
                                                        src={URL.createObjectURL(ele)}
                                                        style={{ width: '150px', height: '150px' }}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                    <div
                                        className="bit-hist"
                                        style={{ height: '100%', minHeight: '500px' }}
                                    >
                                        {comments.data.map((ele) => (
                                            <BidHistory data={ele} />
                                        ))}
                                        {/* {comments.to <
                                        parseInt(Comments_length) +
                                            parseInt(Comments_socket_length) ? (
                                            <PrimaryButton
                                                customClassName="historyView"
                                                label="View More"
                                                onClick={viewMore}
                                            />
                                        ) : null} */}
                                    </div>
                                    {/* Bid history section */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Dialog
                className="diaglogcarton"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div className="d-flex align-items-center justify-content-between">
                        CARFAX REPORT
                        <Button onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent dividers className="ovrFlwFix">
                    <iframe
                        style={{ border: 'none', overflow: 'hidden' }}
                        width="100%"
                        height="500"
                        src={`https://vini.az/en/api/report?key=dccb76e34bb18f7fa249753b1543dd38&vin=${state.product_details.modelnumber}&type=carfax`}
                    ></iframe>
                </DialogContent>
            </Dialog>

            <Popup
                open={openSeller}
                title="Contact Seller"
                className="paymentCardPopup"
                width="xl"
                handleClose={() => setOpenSeller(false)}
                closeButtonClass="card-close"
                fullWidthValue={false}
            >
                <div className="input-boxes">
                    <CustomInput
                        label=""
                        placeholder="Subject"
                        labelClass="vch-id-label"
                        name="subject"
                        id="subject"
                        value={sellerMessage.subject}
                        size="small"
                        onChange={sellerMessageChange}
                        autoComplete="off"
                    />
                </div>
                <div className="comment-area">
                    <CustomTextArea
                        placeholder="Write your message here..."
                        name="messages"
                        value={sellerMessage.messages}
                        onChange={sellerMessageChange}
                    />
                </div>
                <div className="submit-btn pt-2">
                    <SecondaryButton
                        label="submit"
                        customClassName="productViewSecontdry"
                        onClick={sendSellerMessage}
                    />
                </div>
            </Popup>
            <Popup
                open={bid_popup_open}
                title="Bid Amount Verification"
                className="paymentCardPopup"
                width="xl"
                handleClose={() => setOpenBidPopup(false)}
                closeButtonClass="card-close"
                fullWidthValue={false}
            >
                <div className="input-boxes">
                    Are you sure you wish to bid this amount ( $
                    {parseFloat(bidAmount).toLocaleString()})?
                </div>

                <div className="row d-flex align-items-center mt-4">
                    <div className="col-6">
                        <SecondaryButton
                            label="NO"
                            customClassName=""
                            onClick={() => setOpenBidPopup(false)}
                        />
                    </div>
                    <div className="col-6">
                        <PrimaryButton
                            label="YES"
                            customClassName="m-0"
                            onClick={() => confirm_popup(bid_type, 0)}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

const ProductView = connect(mapStateToProps, mapDispatchToProps)(ProductViewComponent)
export default ProductView
