import React from 'react'
import TextField from '@material-ui/core/TextField'

const CustomInput = (props) => {
    // const shrink = props.shrink ? props.shrink.toString() : 'false'
    return (
        <div className={`customInput ${props.inputClassName}`}>
            <p className={`${props.labelClass}`}>{props.label}</p>
            <TextField
                value={props.value}
                autoFocus={props.autoFocus}
                name={props.name}
                // classname={props.className}
                onChange={props.onChange ? (e) => props.onChange(e) : null}
                onBlur={props.onBlur ? (e) => props.onBlur(e) : null}
                InputProps={{
                    inputProps: props.inputProps,
                    startAdornment: props.startAdornment,
                    endAdornment: props.endAdornment,
                    // shrink: shrink,
                    pattern: props.pattern,
                }}
                id={props.id}
                // label={props.label}
                type={props.type}
                size={props.size}
                disabled={props.disabled}
                variant="outlined"
                placeholder={props.placeholder}
                error={props.error}
                helperText={props.helperText}
                autoComplete={props.autoComplete}
            />
        </div>
    )
}

export default CustomInput
