import React, { useState } from 'react'
import { useFormik } from 'formik'

import '../PostListing/PostListingPageOne/postListingPageOne.css'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import CustomInputUpload from '../../components/atoms/Inputs/FileUpload'
import { useSnackbar } from 'notistack'
import imageCompression from 'browser-image-compression'
import { useParams, useHistory } from 'react-router-dom'
import { request } from '../../Utils/Request'
const RequestImage = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { id, title } = useParams()
    const history = useHistory()
    const [details] = useState({
        images: [],
    })
    const [images, setImages] = useState([])
    const [loader, setLoader] = useState(false)
    const formik = useFormik({
        initialValues: details,
        // validationSchema: product1Schema,
        onSubmit: async () => {
            if (images.length < 1) {
                return enqueueSnackbar('Kindly upload atleast one image!', {
                    variant: 'warning',
                })
            }
            try {
                setLoader(true)
                const formData = new FormData()
                formData.set('product_id', id)
                images.map((value) => formData.append('product_images', value))
                const res = await request('post', '/request_to_upload_image', formData, true)
                if (res.data.success) {
                    enqueueSnackbar('Image uploaded successfully!', {
                        variant: 'success',
                    })
                    return history.push('/dashboard/seller/myauction')
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
                console.log(error)
            }
        },
    })

    const removeImage = (index) => {
        images.splice(images.indexOf(index), 1)
        setImages([...images])
    }
    const compressionOptions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    }
    const handleImageChange = (e) => {
        try {
            // console.log(e.target.files.length)
            var placeholder = new Array(e.target.files.length).fill('/assets/svg/loader.svg')
            var arr = [...images, ...placeholder]
            setImages(arr)
            Object.keys(e.target.files).map(async (value, key) => {
                var img = await imageCompression(e.target.files[key], compressionOptions)
                var temp = arr
                var index = temp.indexOf('/assets/svg/loader.svg')
                temp[index] = img
                console.log(temp)
                setImages(() => [...temp])
                // setImages(temp)
                // var img = e.target.files[key]
            })
            e.target.value = ''
        } catch (error) {
            console.log(error)
        }
    }

    // console.log(formik.values.make, details.make)
    return (
        <form className="product-list-page-one container pt-5" onSubmit={formik.handleSubmit}>
            <h3>Submit Additional Images of Your {title}</h3>
            <div className="row product-fist-half pt-2">
                <div className="col-12 upload-area">
                    <CustomInputUpload
                        uploadClassName={'fileupload-signup'}
                        test={'Browse'}
                        label="Please upload clear images of the interior, exterior, undercarriage, and engine compartment"
                        htmlFor="images"
                        id="images"
                        uploadClass="img-upload-wrapper"
                        buttonClass="browse-upload"
                        name="images"
                        onChange={handleImageChange}
                        multiple={true}
                        error={formik.errors.img_upload ? formik.errors.img_upload : null}
                        accept="image"
                        preview_image={images}
                        removeImage={removeImage}
                    />
                    <br />
                    <small style={{ color: 'red' }}>
                        {formik.errors.images ? formik.errors.images : null}
                    </small>
                </div>
            </div>
            <div className="nxt-page-btn">
                <PrimaryButton type="submit" label={'Submit'} customClassName="product-post-next" />
            </div>
        </form>
    )
}

export default RequestImage
