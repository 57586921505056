import React from 'react'
import PropTypes from 'prop-types'
import './staticpages.css'
import StaticPage from '../../Utils/StaticPage'

function HDW(props) {
    const {} = props

    return (
        <>
            <StaticPage page_id="how_does_it_work" />
        </>
    )
}

HDW.propTypes = {}

export default HDW
