import React, { Fragment } from 'react'
import Slider from 'react-slick'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import { VehicleMake, VehicleModel, transmissionType } from '../../Utils/Constants'
import { getAllMake, getModelsOfMake } from '../../Utils/makemodel'
const Banner = ({
    filterData,
    searchTrigger,
    setSearchTrigger,
    handleFilterChange,
    clearSearchValue,
}) => {
    const slider = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    }
    return (
        <Fragment>
            <div className="position-relative">
                <Slider {...slider}>
                    <div>
                        <img
                            src="./assets/images/1.webp"
                            onError={(e) => (e.target.src = './assets/images/1.jpeg')}
                            alt="silder1"
                        />
                    </div>
                    <div>
                        <img
                            src="./assets/images/2.webp"
                            onError={(e) => (e.target.src = './assets/images/1.jpeg')}
                            alt="silder2"
                        />
                    </div>
                    <div>
                        <img
                            src="./assets/images/3.webp"
                            onError={(e) => (e.target.src = './assets/images/1.jpg')}
                            alt="silder3"
                        />
                    </div>
                </Slider>
                <div className="slider-content light-white">
                    <h1>Welcome To Coach & Gavel!</h1>
                    <div className="light-white slide-content-area">
                        <p>Honest Car Auctions For Honest Car People</p>
                    </div>
                    {/* <div className="slider-buttons">
                    <PrimaryButton label={'Find a Vehicle'} customClassName={'first-button mx-2'} />
                    <PrimaryButton
                        label={'List a Vehicle'}
                        customClassName={'second-button mx-2'}
                    />
                </div> */}
                </div>
            </div>
            <div className="search-filter">
                <div className="search-filter-main">
                    <p className="text-left light-white">Find your Vehicle</p>
                    <div className="search-filter-area">
                        <div className="search-filter-dropdown pl-3 py-2">
                            <CustomSelect
                                label={'Make'}
                                labelClass={'text-left'}
                                selectClassName={'search-filter-make'}
                                name="make"
                                value={filterData.make}
                                onChange={(e) => handleFilterChange(e)}
                            >
                                <option value="">Select a make</option>
                                {getAllMake().map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.value}
                                    </option>
                                ))}
                            </CustomSelect>

                            <CustomSelect
                                label={'Model'}
                                labelClass={'text-left'}
                                selectClassName={'search-filter-model'}
                                name="model"
                                value={filterData.model}
                                onChange={(e) => handleFilterChange(e)}
                            >
                                <option value="">Select a model</option>
                                {filterData.make &&
                                    getModelsOfMake(filterData.make).map((el) => (
                                        <option key={el.id} value={el.id}>
                                            {el.value}
                                        </option>
                                    ))}
                            </CustomSelect>

                            {/* <CustomSelect
                                label={'Transmission type'}
                                labelClass={'text-left'}
                                selectClassName={'search-filter-year'}
                                name="transmission"
                                value={filterData.transmission}
                                onChange={(e) => handleFilterChange(e)}
                            >
                                <option value="">Select a transmission</option>
                                {transmissionType.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.type}
                                    </option>
                                ))}
                            </CustomSelect> */}
                        </div>
                        <div className="dropdown-search">
                            <PrimaryButton
                                label={'Search'}
                                customClassName={'filter-search'}
                                onClick={() => setSearchTrigger(!searchTrigger)}
                            />
                        </div>
                    </div>
                    <p className="clear-all-search" onClick={clearSearchValue}>
                        Clear All
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

export default Banner
