import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import './registrationStep1.css'
import RadioBox from '../../components/atoms/RadioBox'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import CheckBox from '../../components/atoms/CheckBox'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { Formik } from 'formik'
import { register1Schema } from '../../Utils/Validatior'
import { request } from '../../Utils/Request'
import { useSnackbar } from 'notistack'
import AttachFileIcon from '@material-ui/icons/AttachFile'

const RegistrationStep1 = () => {
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const [userDetails, setUserDetails] = useState({
        full_name: '',
        user_name: '',
        email: '',
        password: '',
        role: 'for_my_self',
        terms: false,
    })

    const loginConfimation = () => {
        return (
            <p className="light-white m-0 agree-condition">
                I agree the <span className="terms">terms</span> and &nbsp;
                <span className="conditions">Conditions</span>
            </p>
        )
    }

    const checkBoxLabel = [
        { id: 'for_my_self', description: 'For Myself' },
        { id: 'for_bussiness', description: 'For My Business' },
    ]
    const signUpRedirect = async (val) => {
        const stpe_1_data = {
            first_name: val.full_name,
            username: val.user_name,
            email: val.email,
            password: val.password,
            searchUrl: location.search,
        }
        try {
            if (!userDetails.terms) {
                return enqueueSnackbar('Please accept our terms and conditions!', {
                    variant: 'warning',
                })
            }
            enqueueSnackbar('Validating your email!', {
                variant: 'info',
            })
            const res = await request('post', '/checkEmailExistance', { email: val.email })
            if (!res.data.status) {
                return enqueueSnackbar(res.data.message, {
                    variant: 'error',
                })
            }
            enqueueSnackbar('Your email validated successfully!', {
                variant: 'info',
            })

            history.push({ pathname: `/registration_step_2`, state: stpe_1_data })
        } catch (error) {
            console.log(error)
        }
        // Shistory.push({ pathname: `/registration_step_2${location.search}`, state: stpe_1_data })
    }
    const handleChangeRadio = (e) => {
        setUserDetails({
            ...userDetails,
            role: e.target.value,
        })
    }
    const loginRedirect = () => {
        history.push('/login')
    }
    return (
        <div className="mobile-signup row m-0">
            <div className=" col-lg-5 col-12 login-content-area">
                <div className="already-login">
                    <p className="m-0 text-right light-white already-member">Already a Member ?</p>
                    <p className="m-0 text-right login-sign-in light-white" onClick={loginRedirect}>
                        Sign in
                    </p>
                </div>
                <div className="signup-area-content">
                    <h4 className="text-left light-white">Sign Up</h4>
                    <Formik
                        initialValues={userDetails}
                        validationSchema={register1Schema}
                        onSubmit={(values, { setSubmitting }) => {
                            signUpRedirect(values)
                            setSubmitting(false)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form className="sign-up-content" onSubmit={handleSubmit}>
                                {/* <div className="d-flex login-radio-buttons">
                                    <RadioBox
                                        items={checkBoxLabel}
                                        labelClassName={'light-white login-radio-btn'}
                                        onChange={handleChangeRadio}
                                        name="role"
                                        value={userDetails.role}
                                    />
                                </div> */}
                                <div className="signup-content-input">
                                    <CustomInput
                                        placeholder="Full Legal Name"
                                        shrink={true}
                                        label="Full Legal Name"
                                        type="text"
                                        labelClass="text-left light-white"
                                        inputClassName="input-login"
                                        name="full_name"
                                        id="full_name"
                                        value={values.full_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                            touched.full_name && errors.full_name
                                                ? errors.full_name
                                                : null
                                        }
                                        error={touched.full_name && errors.full_name}
                                    />
                                    <CustomInput
                                        placeholder="Email"
                                        shrink={true}
                                        label="Email"
                                        type="email"
                                        labelClass="text-left light-white"
                                        inputClassName="input-login"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                            touched.email && errors.email ? errors.email : null
                                        }
                                        error={touched.email && errors.email}
                                    />
                                    <CustomInput
                                        placeholder="User Name"
                                        shrink={true}
                                        label="User Name"
                                        type="text"
                                        labelClass="text-left light-white"
                                        inputClassName="input-login"
                                        name="user_name"
                                        value={values.user_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                            touched.user_name && errors.user_name
                                                ? errors.user_name
                                                : null
                                        }
                                        error={touched.user_name && errors.user_name}
                                    />
                                    <CustomInput
                                        placeholder="Password"
                                        shrink={true}
                                        label="Password"
                                        type="password"
                                        labelClass="text-left light-white"
                                        inputClassName="input-login"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                            touched.password && errors.password
                                                ? errors.password
                                                : null
                                        }
                                        error={touched.password && errors.password}
                                    />
                                </div>
                                <div className="login-confirmation">
                                    <CheckBox
                                        label={loginConfimation()}
                                        value={userDetails.terms}
                                        checked={userDetails.terms}
                                        onclick={() =>
                                            setUserDetails({
                                                ...userDetails,
                                                terms: !userDetails.terms,
                                            })
                                        }
                                    />
                                    <div className="login-confirm-button">
                                        <PrimaryButton
                                            label={'Continue Sign Up'}
                                            // onClick={signUpRedirect}
                                            disabled={isSubmitting}
                                            type="submit"
                                        />
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className="col-lg-7 col-12 p-0">
                <div className="login-car-image">
                    <div className="garvel-logo">
                        <Link to="/">
                            <img
                                src="/assets/svg/gavel-logo.svg"
                                alt="gavel-logo"
                                className="garvel-logo-image"
                            />
                        </Link>
                    </div>
                    <div className="car-image-content">
                        <h1 className="light-white">Join Our Community</h1>
                        <p className="light-white">Become a Member</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationStep1
