import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import '../dashboard.css'

import DashboardLayout from '../../../components/templates/DashboardLayout/DashboardLayout'
import { request } from '../../../Utils/Request'
import { apiEndPoint } from '../../../Utils/Redux/Store/apiEndpoint'
import Timer from '../../../Utils/Timer'
import { dateTimeFormat, dateTimeYearFormat } from '../../../Utils/Constants'
import Pagination from '../../../components/atoms/Pagination/Pagination'
import moment from 'moment'
import RatingComponent from './Rating'
import Rating from '@material-ui/lab/Rating'
import FormDialog from '../../../components/atoms/Modal'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 500,
    },
    tabPanel: {
        border: '1px solid #233e7c',
        backgroundColor: '#FFFFFF',
        color: 'var(--progressLine)',
        textTransform: 'capitalize',
    },
})
const MyAuction = () => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const [trigger, setTrigger] = useState(false)
    const [bidactive, setBidactive] = useState({
        length: 0,
        page: 1,
        data: [],
        status: 'bidactive',
        limit: 10,
    })
    const [bidwon, setBidwon] = useState({
        length: 0,
        page: 1,
        data: [],
        status: 'bidwon',
        limit: 10,
    })
    const [bidlost, setBidlost] = useState({
        length: 0,
        page: 1,
        data: [],
        status: 'bidlost',
        limit: 10,
    })
    const [reviews, setReviews] = useState({ length: 0, page: 1, limit: 10, data: [] })

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const auctionTabs = async () => {
        try {
            let data
            if (value === 0) {
                data = {
                    page: bidactive.page,
                    sh_limit: bidactive.limit,
                    status: bidactive.status,
                }
            } else if (value === 1) {
                data = {
                    page: bidwon.page,
                    sh_limit: bidwon.limit,
                    status: bidwon.status,
                }
            } else if (value === 2) {
                data = {
                    page: bidlost.page,
                    sh_limit: bidlost.limit,
                    status: bidlost.status,
                }
            } else if (value === 3) {
                data = {
                    seller_id: JSON.parse(localStorage.getItem('coachUser')).id,
                    role: 0,
                }
            }

            const res = await request(
                'post',
                value === 3 ? '/getSellerFeedbacks' : apiEndPoint.buyerDashboard,
                data,
                true,
            )

            if (res.data.success === 'yes' || res.data.status === 'yes') {
                if (value === 0) {
                    setBidactive({
                        ...bidactive,
                        data: res.data.results,
                        length: res.data.total_pagecnt,
                    })
                } else if (value === 1) {
                    setBidwon({
                        ...bidwon,
                        data: res.data.results,
                        length: res.data.total_pagecnt,
                    })
                } else if (value === 2) {
                    setBidlost({
                        ...bidlost,
                        data: res.data.results,
                        length: res.data.total_pagecnt,
                    })
                } else if (value === 3) {
                    setReviews({
                        ...reviews,
                        data: res.data.data,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        auctionTabs()
    }, [value, bidactive.page, bidwon.page, bidlost.page, trigger])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        if (params.has('index')) {
            if (!isNaN(params.get('index'))) {
                setValue(parseInt(params.get('index')))
            }
        }
    }, [])
    return (
        <DashboardLayout userType="buyer">
            <Tabs
                className={`${classes.root} buyer-my-auction`}
                value={value}
                onChange={handleChange}
                centered
                aria-label="simple tabs example"
            >
                <Tab label="Active Auction" {...a11yProps(0)} className={classes.tabPanel} />
                <Tab label="Won Auction" {...a11yProps(1)} className={classes.tabPanel} />
                <Tab label="Lost Auction" {...a11yProps(2)} className={classes.tabPanel} />
                <Tab label="Reviews" {...a11yProps(3)} className={classes.tabPanel} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Auction id</th>
                                <th scope="col">Auction Name</th>
                                <th scope="col">Bid Placed On</th>
                                <th scope="col">Time Left</th>
                                <th scope="col">My Bid</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bidactive.data.map((ele) => (
                                <tr key={ele.id}>
                                    <td>{ele.id}</td>
                                    <td>{ele.title}</td>
                                    <td>{moment(ele.bidTime).format(dateTimeYearFormat)}</td>
                                    <td>
                                        {
                                            <Timer
                                                date_added={ele.date_added}
                                                date_closed={ele.date_closed}
                                            />
                                        }
                                    </td>
                                    <td>$ {parseInt(ele.mybid).toLocaleString()}</td>
                                    <td className="auction-view">
                                        <Link to={`/product/${ele.id}/${ele.title}`}>View</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {bidactive &&
                    typeof bidactive !== 'undefined' &&
                    bidactive.data.length === 0 ? (
                        <p className="text-center">No data found</p>
                    ) : null}
                </div>
                {Math.ceil(bidactive.length / bidactive.limit) > 1 ? (
                    <div className="float-right pb-4">
                        <Pagination
                            length={bidactive.length}
                            limit={bidactive.limit}
                            page={bidactive.page}
                            onChange={(e, v) => setBidactive({ ...bidactive, page: v })}
                        />
                    </div>
                ) : null}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Auction id</th>
                                <th scope="col">Auction Name</th>
                                <th scope="col">Won On</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                                <th scope="col">Review</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bidwon.data.map((ele) => (
                                <tr key={ele.id}>
                                    <td>{ele.id}</td>
                                    <td>{ele.title}</td>
                                    <td>{moment(ele.date_closed).format(dateTimeYearFormat)}</td>
                                    <td>$ {parseInt(ele.wprice).toLocaleString()}</td>
                                    <td className={`${ele.paid ? 'paid' : 'unpaid'}`}>
                                        {ele.paid ? 'Paid' : 'Unpaid'}
                                    </td>
                                    <td className="auction-view">
                                        {ele.paid ? (
                                            <span className="auction-view">
                                                <Link
                                                    to={`/invoice/${ele.common_invoice}/${ele.category_id}`}
                                                >
                                                    Invoice
                                                </Link>
                                            </span>
                                        ) : (
                                            (ele.autopay === 3 || ele.autopay === 4) && (
                                                <span className="paid">
                                                    <Link
                                                        to={`/checkout/${ele.common_invoice}/${ele.category_id}`}
                                                    >
                                                        Pay Now
                                                    </Link>
                                                </span>
                                            )
                                        )}
                                    </td>
                                    <td>
                                        {!ele.reviewer_rating ? (
                                            <RatingComponent
                                                seller_id={ele.user_id}
                                                product_id={ele.id}
                                                trigger={trigger}
                                                setTrigger={setTrigger}
                                                role={1}
                                            />
                                        ) : (
                                            <Rating
                                                value={ele.reviewer_rating}
                                                name="your_rating"
                                                readOnly
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {bidwon && typeof bidwon !== 'undefined' && bidwon.data.length === 0 ? (
                        <p className="text-center">No data found</p>
                    ) : null}

                    {Math.ceil(bidwon.length / bidwon.limit) > 1 ? (
                        <div className="float-right pb-4">
                            <Pagination
                                length={bidwon.length}
                                limit={bidwon.limit}
                                page={bidwon.page}
                                onChange={(e, v) => setBidwon({ ...bidwon, page: v })}
                            />
                        </div>
                    ) : null}
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Auction id</th>
                                <th scope="col">Auction Name</th>
                                <th scope="col">Bid Placed On</th>
                                {/* <th scope="col">My Bid</th> */}
                                <th scope="col">Winning Bid</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bidlost.data.map((ele) => (
                                <tr key={ele.id}>
                                    <td>{ele.id}</td>
                                    <td>{ele.title}</td>
                                    <td>{moment(ele.bidTime).format(dateTimeYearFormat)}</td>
                                    <td>$ {parseInt(ele.wprice).toLocaleString()}</td>
                                    <td className="auction-view">Sold</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {bidlost && typeof bidlost !== 'undefined' && bidlost.data.length === 0 ? (
                        <p className="text-center">No data found</p>
                    ) : null}
                    {Math.ceil(bidlost.length / bidlost.limit) > 1 ? (
                        <div className="float-right pb-4">
                            <Pagination
                                length={bidlost.length}
                                limit={bidlost.limit}
                                page={bidlost.page}
                                onChange={(e, v) => setbidLost({ ...bidlost, page: v })}
                            />
                        </div>
                    ) : null}
                </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div className="cusTable">
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th scope="col">Auction Id</th>
                                <th scope="col">Vehicle Name</th>
                                <th scope="col">Review Posted By</th>
                                <th scope="col">Review Posted On</th>
                                <th scope="col">Review</th>
                                <th scope="col">Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reviews && typeof reviews.data !== 'undefined' && reviews.data.length
                                ? reviews.data.map((ele) => (
                                      <tr key={ele.message}>
                                          <td>{ele.message}</td>
                                          <td>{ele.title ? ele.title : ''}</td>
                                          <td>{ele.first_name}</td>
                                          <td>
                                              {moment(ele.date_added).format(dateTimeYearFormat)}
                                          </td>
                                          <td>
                                              <Rating value={ele.rating} readOnly />{' '}
                                          </td>

                                          <td className="auction-view">
                                              {ele.subject ? (
                                                  <FormDialog
                                                      title="View comment"
                                                      message={ele.subject}
                                                      role={'buyer'}
                                                  />
                                              ) : (
                                                  'No Comment'
                                              )}
                                          </td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                    {reviews && typeof reviews.data !== 'undefined' && reviews.data.length === 0 ? (
                        <p className="text-center">No data found</p>
                    ) : null}
                </div>
            </TabPanel>
        </DashboardLayout>
    )
}

export default MyAuction
