import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { request } from '../../Utils/Request'
import { useSnackbar } from 'notistack'

const EditProduct = ({ product_id, trigger, setTrigger }) => {
    const [open, setOpen] = React.useState(false)
    const [loader, setLoader] = useState(false)
    const [rprice, setRprice] = useState('')
    const { enqueueSnackbar } = useSnackbar()
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const editReserve = async () => {
        try {
            if (!rprice) {
                return enqueueSnackbar('Kindly enter reserve price!', {
                    variant: 'error',
                })
            }
            if (isNaN(rprice) || parseFloat(rprice) < 1) {
                return enqueueSnackbar('Invalid reserve price!', {
                    variant: 'error',
                })
            }

            setLoader(true)
            const res = await request('post', '/edit_reserve_price', { product_id, rprice }, true)
            if (res.data.status === 'yes') {
                enqueueSnackbar(res.data.message, {
                    variant: 'success',
                })
                setTrigger(!trigger)
                setRprice('')
                setOpen(false)
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)

            console.log(error)
        }
    }
    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Lower My Reserve
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Reserve Price</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Reserve Price"
                        type="text"
                        value={rprice}
                        onChange={(e) => setRprice(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={editReserve} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EditProduct
