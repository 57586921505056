import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { mapData } from '../../common/components'

import WelcomeHeader from '../../components/organisms/WelcomeHeader'
import './login.css'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import CheckBox from '../../components/atoms/CheckBox'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import csc from 'country-state-city'

import { request } from '../../Utils/Request'
import { apiEndPoint } from '../../Utils/Redux/Store/apiEndpoint'
import JwtDecode from 'jwt-decode'
import { bankSchema } from '../../Utils/Validatior'
import RadioBox from '../../components/atoms/RadioBox'
import publicIp from 'public-ip'
import { countryList } from '../../Utils/Constants'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { TrendingUpTwoTone } from '@material-ui/icons'
const BankDetails = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [details, setDetails] = useState({
        email: '',
        country: '',
        currency: 'usd',
        business_type: 'individual',
        first_name: '',
        last_name: '',
        dob: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: '',
        phone: '',
        id_number: '',
        mcc: 5511,
        url: window.location.origin,
        ip: '',
        // account_holder_name: '',
        // account_holder_type: 'individual',
        // routing_number: '',
        // account_number: '',
        card_name: '',
        card_number: '',
        card_exp_date: '',
        card_cvc: '',
        card_address_line1: '',
        card_address_line2: '',
        card_address_city: '',
        card_address_state: '',
        card_address_country: '',
        card_address_zip: '',
        card_token: true,
    })

    const formik = useFormik({
        initialValues: details,
        enableReinitialize: false,
        validationSchema: bankSchema,
        onSubmit: async (values) => {
            try {
                setLoader(true)
                var arr = moment(values.dob).format('yyyy-MM-DD').split('-')
                var country = csc.getCountryById(values.country).sortname
                var data = {
                    email: values.email,
                    country,
                    business_type: values.business_type,
                    individual: {
                        first_name: values.first_name,
                        last_name: values.last_name,
                        email: values.email,
                        dob: {
                            day: arr[2],
                            month: arr[1],
                            year: arr[0],
                        },
                        address: {
                            line1: values.line1,
                            line2: values.line2,
                            city: values.city,
                            state: csc.getStateById(values.state).name,
                            country,
                            postal_code: values.postal_code,
                        },
                        phone: `+${csc.getCountryById(values.country).phonecode}${values.phone}`,
                        id_number: values.id_number,
                    },
                    business_profile: {
                        mcc: values.mcc,
                        url: 'http://coachgavel.moblearn.net',
                    },
                    tos_acceptance: {
                        ip: '192.168.20.109',
                    },
                    // external_account: {
                    //     country,
                    //     currency: values.currency,
                    //     account_holder_name: values.account_holder_name,
                    //     account_holder_type: values.account_holder_type,
                    //     routing_number: values.routing_number,
                    //     account_number: values.account_number,
                    // },
                    card_name: values.card_name,
                    card_number: values.card_number.split(' ').join(''),
                    card_exp_month: values.card_exp_date.split('/')[0],
                    card_exp_year: `20${values.card_exp_date.split('/')[1]}`,
                    card_cvc: values.card_cvc,
                    card_address_line1: values.card_address_line1,
                    card_address_line2: values.card_address_line2,
                    card_address_city: values.card_address_city,
                    card_address_state: values.card_address_state,
                    card_address_country: values.card_address_country,
                    card_address_zip: values.card_address_zip,
                    card_token: true,
                }
                const res = await request('post', '/onBoarding', data, false, 4)
                if (res.data.status === 'error') {
                    if (res.data.data.responseType === 3) {
                        setLoader(false)
                        return enqueueSnackbar(res.data.data.message, {
                            variant: 'error',
                        })
                    }
                }
                await request(
                    'post',
                    '/store_account_id',
                    { account_id: res.data.data.responseData.id },
                    true,
                )
                setLoader(false)
                history.push('/')
            } catch (error) {
                setLoader(false)
                console.log(error)
            }
        },
    })
    const billingRadio = [
        { id: 'individual', description: 'Individual' },
        { id: 'company', description: 'Company' },
    ]
    const businessRadio = [
        { id: 'individual', description: 'Individual' },
        { id: 'company', description: 'Company' },
    ]
    const handleChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.value)
    }
    function cardNumberFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }
        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }
    function dateFormat(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{2,4}/g)
        var match = (matches && matches[0]) || ''
        var parts = []
        var i
        var len
        for (i = 0, len = match.length; i < len; i += 2) {
            parts.push(match.substring(i, i + 2))
        }
        if (parts.length) {
            return parts.join('/')
        } else {
            return value
        }
    }
    const firsthalf = [
        {
            label: 'First Name',
            type: 'text',
            placeholder: 'First Name',
            class: 'col-6',
            name: 'first_name',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Last Name',
            type: 'text',
            placeholder: 'Last Name',
            class: 'col-6',
            name: 'last_name',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Email',
            type: 'email',
            placeholder: 'Email',
            class: 'col-6',
            name: 'email',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Phone Number',
            type: 'text',
            placeholder: 'Phone Number',
            class: 'col-6',
            name: 'phone',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Address Line 1',
            type: 'text',
            placeholder: 'Address Line 1',
            class: 'col-6',
            name: 'line1',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Address Line 2',
            type: 'text',
            placeholder: 'Address Line 2',
            class: 'col-6',
            name: 'line2',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Country',
            type: 'select',
            placeholder: 'Select a country',
            class: 'col-6',
            options: csc.getAllCountries(),
            name: 'country',
            formik: formik,
            selectClassName: 'input-login',
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
            onChange: handleChange,
        },
        {
            label: 'State',
            type: 'select',
            placeholder: 'Select a state',
            class: 'col-6',
            options: csc.getStatesOfCountry(formik.values.country),
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
            name: 'state',
            id: 'state',
            formik: formik,
            selectClassName: 'input-login',
            onChange: handleChange,
        },
        {
            label: 'City',
            type: 'text',
            placeholder: 'City',
            class: 'col-6',
            name: 'city',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Postal Code',
            type: 'text',
            placeholder: 'Postal Code',
            class: 'col-3',
            name: 'postal_code',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
    ]
    // const secondhalf = [
    //     {
    //         label: 'Account Holder Name',
    //         type: 'text',
    //         placeholder: 'Account Holder Name',
    //         class: 'col-6',
    //         name: 'account_holder_name',
    //         formik: formik,
    //         labelClass: 'text-left login-user-name-label light-white',
    //         inputClassName: 'input-login login-user-name',
    //     },
    //     {
    //         label: 'Account Number',
    //         type: 'text',
    //         placeholder: 'Account Number',
    //         class: 'col-6',
    //         name: 'account_number',
    //         formik: formik,
    //         labelClass: 'text-left login-user-name-label light-white',
    //         inputClassName: 'input-login login-user-name',
    //     },
    //     {
    //         label: 'Routing number',
    //         type: 'text',
    //         placeholder: 'Routing number',
    //         class: 'col-6',
    //         name: 'routing_number',
    //         formik: formik,
    //         labelClass: 'text-left login-user-name-label light-white',
    //         inputClassName: 'input-login login-user-name',
    //     },
    //     {
    //         label: 'Social Security Number',
    //         type: 'text',
    //         placeholder: 'Social Security Number',
    //         class: 'col-6',
    //         name: 'id_number',
    //         formik: formik,
    //         labelClass: 'text-left login-user-name-label light-white',
    //         inputClassName: 'input-login login-user-name',
    //     },
    // ]
    const secondhalf = [
        {
            label: 'Card Holder Name',
            type: 'text',
            placeholder: 'Card Holder Name',
            class: 'col-6',
            name: 'card_name',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Card Number',
            type: 'text',
            placeholder: 'Card Number',
            class: 'col-6',
            name: 'card_number',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
            onChange: (e) => formik.setFieldValue('card_number', cardNumberFormat(e.target.value)),
        },
        {
            label: 'Expiration Date',
            type: 'text',
            placeholder: 'MM/YY',
            class: 'col-3',
            name: 'card_exp_date',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
            onChange: (e) => formik.setFieldValue('card_exp_date', dateFormat(e.target.value)),
        },
        {
            label: 'CVC/CVV',
            type: 'text',
            placeholder: 'CVC/CVV',
            class: 'col-3',
            name: 'card_cvc',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Social Security Number',
            type: 'text',
            placeholder: 'Social Security Number',
            class: 'col-6',
            name: 'id_number',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
    ]
    const thirdHalf = [
        {
            label: 'Card Address Line 1',
            type: 'text',
            placeholder: 'Card Address Line 1',
            class: 'col-6',
            name: 'card_address_line1',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Card Address Line 2',
            type: 'text',
            placeholder: 'Card Address Line 2',
            class: 'col-6',
            name: 'card_address_line2',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Card Country',
            type: 'select',
            placeholder: 'Select card country',
            class: 'col-6',
            options: csc.getAllCountries(),
            name: 'card_address_country',
            formik: formik,
            selectClassName: 'input-login',
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
            onChange: handleChange,
        },
        {
            label: 'Card State',
            type: 'select',
            placeholder: 'Select card state',
            class: 'col-6',
            options: csc.getStatesOfCountry(formik.values.card_address_country),
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
            name: 'card_address_state',
            id: 'state',
            formik: formik,
            selectClassName: 'input-login',
            onChange: handleChange,
        },
        {
            label: 'Card City',
            type: 'text',
            placeholder: 'Card City',
            class: 'col-6',
            name: 'card_address_city',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
        {
            label: 'Card Postal Code',
            type: 'text',
            placeholder: 'Card Postal Code',
            class: 'col-6',
            name: 'card_address_zip',
            formik: formik,
            labelClass: 'text-left login-user-name-label light-white',
            inputClassName: 'input-login login-user-name',
        },
    ]
    return (
        <WelcomeHeader>
            <div className="container-fluid bankFormDetails">
                <h5 className="light-white my-3 welcome-heading">Welcome to Coach & Gavel !</h5>
                <div className="login-container-area">
                    <h4 className="light-white">Debit Card Details</h4>
                    <div className="login-content">
                        <form onSubmit={formik.handleSubmit} className="row">
                            {/* <div className="col-6 d-flex align-items-center rdoBxWrpr my-4">
                                <p className="text-left login-user-name-label light-white m-0 mr-2">
                                    Account Holder Type
                                </p>
                                <RadioBox
                                    items={billingRadio}
                                    labelClassName={'light-white login-radio-btn'}
                                    parentRadioClassName={'bill-radio-btn'}
                                    name="account_holder_type"
                                    value={formik.values.billing_card}
                                    onChange={handleChange}
                                />
                            </div> */}
                            <div className="col-12 d-flex align-items-center rdoBxWrpr my-4">
                                <p className="text-left login-user-name-label light-white m-0 mr-2">
                                    Business Type
                                </p>
                                <RadioBox
                                    items={businessRadio}
                                    labelClassName={'light-white login-radio-btn'}
                                    parentRadioClassName={'bill-radio-btn'}
                                    name="business_type"
                                    value={formik.values.billing_card}
                                    onChange={handleChange}
                                />
                            </div>

                            {Object.values(mapData(firsthalf))}
                            <div className="col-3 input-boxes">
                                <p className="text-left login-user-name-label light-white">DOB</p>
                                <DatePicker
                                    name="dob"
                                    id="dob"
                                    showYearDropdown
                                    showMonthDropdown
                                    dateFormat={'dd-MM-yyyy'}
                                    selected={formik.values.dob}
                                    onChange={(date) => {
                                        formik.setFieldValue('dob', date)
                                    }}
                                    onYearChange={(date) => {
                                        formik.setFieldValue('dob', date)
                                    }}
                                    onMonthChange={(date) => {
                                        formik.setFieldValue('dob', date)
                                    }}
                                    maxDate={new Date()}
                                    autoComplete="off"
                                    value={formik.values.dob}
                                />
                                <br />
                                <small style={{ color: 'red' }}>
                                    {formik.errors.dob ? formik.errors.dob : null}
                                </small>
                            </div>
                            {Object.values(mapData(secondhalf))}
                            {Object.values(mapData(thirdHalf))}

                            <div className="login-confirm-area col-12">
                                <div className="login-button mx-auto">
                                    <PrimaryButton
                                        customClassName="login-confirm-btn "
                                        label="Submit"
                                        type="submit"
                                        disabled={loader}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </WelcomeHeader>
    )
}

export default BankDetails
