import React from 'react'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined'
import './ProductCart.css'
import FavoriteCheckbox from '../../atoms/FavoriteCheckbox'
import Timer from '../../../Utils/Timer'
import LazyImage from '../../atoms/LazyImage'
import numeral from 'numeral'
import { dummyProductItems } from './dummyProductItems'
const ProductCard = (props) => {
    const { data } = props
    console.log(data, 'this is hte data')

    return (
        <div className="product-card">
            <div className="pcImgWrapper" onClick={props.onClick}>
                <LazyImage
                    src={`${process.env.REACT_APP_PRODUCT_URL}/${data.file_name}`}
                    alt={data.protitle}
                    className="product-img"
                />
            </div>
            {data.market_status === 'sold' ? null : (
                <FavoriteCheckbox
                    checked={data.watch}
                    productId={data.id}
                    onChecked={props.handleChecked}
                />
            )}
            {parseInt(data.rprice) === 0 ? (
                <span className="noRsrvTxt">No Reserve</span>
            ) : parseInt(data.rprice) <= data.wprice ? (
                <span className="yesRsrvTxt">Reserve Met</span>
            ) : (
                ''
            )}
            <div className="product-description mt-2">
                <div className="product-title">
                    <p>
                        {data.tags} &nbsp;{data.title} &nbsp;{data.category_id}
                    </p>
                </div>
                <div className="product-details">
                    <div className="product-price">
                        <p>
                            <MonetizationOnOutlinedIcon className="dollor-icon" />{' '}
                            <span className="product-price-dynamic">
                                {parseInt(data.wprice).toLocaleString().length > 5
                                    ? numeral(parseInt(data.wprice).toLocaleString()).format('0 a')
                                    : `${parseInt(data.wprice).toLocaleString()}`}
                            </span>
                        </p>
                    </div>
                </div>
                <div className="product-time">
                    <p>
                        <QueryBuilderOutlinedIcon className="price-icon" />
                        <span className="product-time-dynamic">
                            {' '}
                            <Timer
                                date_added={data.date_added}
                                date_closed={data.date_closed}
                                withText={1}
                            />
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ProductCard
