import axios from 'axios'
import { isAuthenticated } from './Auth'

export const request = async (method, url, data = {}, userToken = false, urlPrefix = 1) => {
    const token = isAuthenticated() ? isAuthenticated() : ''
    var headers = {
        Authorization: userToken ? `Bearer ${token}` : '',
        domain: 'coachgavel.moblearn.net',
    }
    var fullURL
    if (urlPrefix === 1) {
        // fullURL = 'http://192.168.20.109:8080/mobileapi' + url
        fullURL = process.env.REACT_APP_MOBILE_API_URL + url
    } else if (urlPrefix === 2) {
        headers = undefined
        data.account_id = process.env.REACT_APP_AUCTION_PAY_ID
        fullURL = process.env.REACT_APP_AUCTION_PAY_URL + url
    } else if (urlPrefix === 3) {
        fullURL = process.env.REACT_APP_BASE_URL + url
    } else {
        headers = undefined
        fullURL = process.env.REACT_APP_AUCTION_PAY_URL + url
    }
    try {
        const res = await axios({ method, url: fullURL, data, headers })
        // console.log(res)
        if (res) {
            return res
        }
    } catch (error) {
        return error
    }
}
