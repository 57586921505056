import React, { useState } from 'react'
import Rating from '@material-ui/lab/Rating'
import { request } from '../../../../Utils/Request'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useSnackbar } from 'notistack'
const RatingComponent = ({ role, seller_id, product_id, trigger, setTrigger }) => {
    const [value, setValue] = useState(0)
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const [subject, setSubject] = useState('')
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const postRating = async () => {
        try {
            if (!value) {
                return enqueueSnackbar('Kindly choose your rating!', {
                    variant: 'error',
                })
            }
            setLoader(true)
            const data = {
                ratings: value,
                seller_id,
                product_id,
                subject,
                role,
            }
            const res = await request('post', '/give_ratings', data, true)
            if (res.data.status === 'yes') {
                enqueueSnackbar(res.data.message, {
                    variant: 'success',
                })
                setValue(0)
                setSubject('')
                setOpen(false)
                setTrigger(!trigger)
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    return (
        <div>
            <Button variant="outlined" color="success" onClick={handleClickOpen}>
                REVIEW
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">YOUR REVIEW</DialogTitle>
                <DialogContent>
                    <Rating value={value} name="your_rating" onChange={(e, v) => setValue(v)} />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="subject"
                        label="Comments"
                        type="text"
                        fullWidth
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        multiline={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" disabled={loader}>
                        Cancel
                    </Button>
                    <Button onClick={postRating} color="primary" disabled={loader}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RatingComponent
