import React, { useState, useEffect } from 'react'
import './BidHistory.css'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import moment from 'moment'
import { dateTimeFormat } from '../../../Utils/Constants'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import { Attachment } from '@material-ui/icons'
import { getIn } from 'formik'

function BidHistory(props) {
    const { data } = props
    // console.log(data, '==> this si the data from the comment')

    const [commentimages, setCommentImages] = useState([])
    const [individualCommentImg, setIndividualCommentImg] = useState(false)

    const commentImagePopup = () => {
        setIndividualCommentImg(false)
    }

    const commentImageContent = (item) => {
        return <img src={item.thumbnail} alt="slide omg" width={120} height={90} />
    }

    const getImages = (data) => {
        var commentImage = []

        {
            data.subject && data.subject.includes('[')
                ? JSON.parse(data.subject).map((value) =>
                      commentImage.push({
                          original: `${process.env.REACT_APP_AVATAR_URL}/${value}`,
                          thumbnail: `${process.env.REACT_APP_AVATAR_URL}/${value}`,
                          originalClass: 'popupSlider',
                          src: `${process.env.REACT_APP_AVATAR_URL}/${value}`,
                          w: 1200,
                          h: 900,
                      }),
                  )
                : commentImage.push({
                      original: `${process.env.REACT_APP_AVATAR_URL}/${data.subject}`,
                      thumbnail: `${process.env.REACT_APP_AVATAR_URL}/${data.subject}`,
                      originalClass: 'popupSlider',
                      src: `${process.env.REACT_APP_AVATAR_URL}/${data.subject}`,
                      w: 1200,
                      h: 900,
                  })
        }

        setCommentImages(commentImage)
    }

    useEffect(() => {
        getImages(data)
    }, [data])

    return (
        <div className="bidAcordent">
            <div className="bid-time">
                <span>
                    {data.username}
                    {data.to_email === 'seller' && '(seller)'}
                </span>{' '}
                <span className="hisDateTime">{moment(data.date_sent).format(dateTimeFormat)}</span>
            </div>
            {data.to_email === 'bidhistory' ? (
                <div className="accorLabel">
                    <p className="comment-dtls">
                        {data.message.replace(data.from_id, data.username)}
                    </p>
                </div>
            ) : (
                <div className="post-by-seller">
                    <div className="post-seller-heading">
                        {/* {data.to_email === 'seller' && (
                            <p className="seller-heading">this is the seller </p>
                        )} */}
                        {/* <p className="seller-likes">
                            <i className="far fa-thumbs-up"></i>
                            <span className="thumb-count"> {data.thumbsUpCount} </span>
                        </p> */}
                    </div>
                    <div className="seller-content">
                        <p>{data.message}</p>
                        {data.subject && (
                            <>
                                <PhotoSwipeGallery
                                    // key={index}
                                    isOpen={individualCommentImg}
                                    items={commentimages}
                                    thumbnailContent={commentImageContent}
                                    onClose={commentImagePopup}
                                    id="CommentPhotos"
                                />
                            </>
                        )}
                    </div>
                    {/* <div className="thumbs-up-two">
                        <p className="second-thumb">
                            <i className="far fa-thumbs-up"></i> <span>{data.thumbsUpCount} </span>
                        </p>
                        <p className="contrative-link">
                            <a href="#">Flag as not constructive</a>
                        </p>
                    </div> */}
                </div>
            )}
        </div>
    )
}

export default BidHistory
