import React from 'react'
import PropTypes from 'prop-types'
import './staticpages.css'
import StaticPage from '../../Utils/StaticPage'

function GearStore(props) {
    const {} = props

    return (
        <>
            <StaticPage page_id="gear_store" />
        </>
    )
}

GearStore.propTypes = {}

export default GearStore
