import React from 'react'
import PropTypes from 'prop-types'
import './staticpages.css'
import StaticPage from '../../Utils/StaticPage'

function Privacy(props) {
    const {} = props

    return (
        <>
            <StaticPage page_id="privacy policy" />
        </>
    )
}

Privacy.propTypes = {}

export default Privacy
