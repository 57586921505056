import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}))

const Page = (props) => {
    const classes = useStyles()
    const { length, limit, page, onChange } = props
    return (
        <div className={classes.root}>
            <Pagination
                variant="outlined"
                shape="rounded"
                count={Math.ceil(length / limit)}
                color="primary"
                showFirstButton
                showLastButton
                onChange={onChange ? (e, v) => onChange(e, v) : null}
                page={page}
            />
        </div>
    )
}
export default Page
