import React from 'react'
import PlanCard from '../../components/molecules/PlanCard'
import './Subscription.css'

function Subscription() {
    const planCard = [
        {
            Name: 'Classic',
            price: '$ 99',
            description:
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore',
        },
        {
            Name: 'Plus',
            price: '$ 349',
            description:
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore',
        },
        {
            Name: 'White Glove',
            price: '$ 499',
            description:
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore',
        },
    ]
    return (
        <div className="subContainer customContainer">
            <div className="container">
                <h2 className="SubTitle">Choose Your Plan</h2>
                <div className="cardContainer d-flex justify-content-between">
                    {planCard.map((data, index) => (
                        <PlanCard
                            planName={data.Name}
                            planPrice={data.price}
                            planDecs={data.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Subscription
