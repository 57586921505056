import React from 'react'
import WelcomeHeader from '../../components/organisms/WelcomeHeader'
import './resetPassword.css'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
const reset_password = () => {
    return (
        <WelcomeHeader>
            <div className="reset-passwrd">
                <h5 className="light-white my-3 welcome-heading">Welcome to Coach & Gavel !</h5>
                <div className="reset-passwrd-container-area">
                    <h4 className="light-white">Reset Password</h4>
                    <div className="reset-passwrd-content">
                        <CustomInput
                            label="New Password"
                            type="password"
                            inputClassName="input-login login-user-name reset-new-passwrd"
                            labelClass="login-user-name-label light-white "
                            placeholder="New Password"
                        />
                        <CustomInput
                            label="Confirm New Password"
                            type="password"
                            inputClassName="input-login login-user-name reset-cnfrm-passwrd"
                            labelClass="login-user-name-label light-white"
                            placeholder="Confirm New Password"
                        />
                        <div className="reset-passwrd-btn-area">
                            <PrimaryButton
                                customClassName="reset-passwrd-btn"
                                label="Reset My Password"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </WelcomeHeader>
    )
}

export default reset_password
