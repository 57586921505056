import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter, Switch } from 'react-router-dom'
import Homepage from '../views/Homepage'
// const Homepage = lazy(() => import('../views/Homepage'))
import Login from '../views/Login'
import ProductView from '../views/ProductView'
import Header from '../components/organisms/Header'
import Footer from '../components/organisms/Footer'
import Subscription from '../views/Subscription'
import ForgetPassword from '../views/ForgetPassword'
import ResetPassword from '../views/ResetPassword'
import PostListing from '../views/PostListing/index'
import RegistrationStep1 from '../views/Registration/RegistrationStep1'
import RegistrationStep2 from '../views/Registration/RegistrationStep2'
import Checkout from '../views/Checkout'
import BuyerMyAuction from '../views/Dashboard/BuyerMyAuction/index'
import BuyerWatchlist from '../views/Dashboard/BuyerWatchlist'
import BuyerPayment from '../views/Dashboard/BuyerPayment'
import BuyerNotificaton from '../views/Dashboard/BuyerNotification'
import BuyerProfile from '../views/Dashboard/BuyerProfile'
import SellerMyAuction from '../views/Dashboard/SellerMyAuction'
import SellerProfile from '../views/Dashboard/SellerProfile'
import SellerNotification from '../views/Dashboard/SellerNotification'
import SellerPayment from '../views/Dashboard/SellerPayment'
import LiveAuction from '../views/LiveAuction'
import PastAuction from '../views/PastAuction'
import GearStore from '../views/StaticPages/GearStore'
import HDW from '../views/StaticPages/HDW'
import JoinEmailList from '../views/StaticPages/JoinEmailList'
import SubmitVehicle from '../views/StaticPages/SubmitVehicle'
import Invoice from '../views/Invoice'
import Terms from '../views/StaticPages/Terms'
import WhatCG from '../views/StaticPages/WhatCG'
import EmailVerification from '../views/EmailVerification'
import CommonRoutes from './CommonRoutes'
import LazyLoad from 'react-lazyload'
import ChangeForgotPassword from '../views/ForgetPassword/ChangeForgotPassword'
import PostCar from '../views/PostCar'
import WhyCG from '../views/StaticPages/WhyCG'
import BankDetails from '../views/BankDetails'
import RequestImage from '../views/Dashboard/RequestImages'
import Privacy from '../views/StaticPages/Privacy'
import UpcomingAuction from '../views/UpcomingAuction'

const Routes = (PropTypes) => {
    return (
        <>
            {PropTypes.location.pathname === '/registration_step_1' ||
                PropTypes.location.pathname === '/registration_step_2' ||
                PropTypes.location.pathname === '/login' ||
                PropTypes.location.pathname.includes('/forgot_password') ||
                PropTypes.location.pathname === '/reset_password' ||
                PropTypes.location.pathname.includes('/bank_details') ||
                PropTypes.location.pathname.includes('/verifyuser') || (
                    // <LazyLoad height={200} once={true}>
                    <Header hdrCls={PropTypes.location.pathname !== '/' ? 'innerHeaderbg' : null} />
                    // </LazyLoad>
                )}
            <Switch>
                {/* <Suspense
                    fallback={
                        <div>
                            <img
                                className="img-fluid mx-auto d-block"
                                src="./assets/svg/loader.svg"
                            />
                        </div>
                    }
                > */}
                <Route exact path="/" component={Homepage} />
                {/* </Suspense> */}
                <Route exact path="/login" component={Login} />
                <Route exact path="/registration_step_1" component={RegistrationStep1} />
                <Route exact path="/registration_step_2" component={RegistrationStep2} />
                <Route exact path="/forgot_password" component={ForgetPassword} />
                <Route exact path="/reset_password" component={ResetPassword} />
                <Route exact path="/product/:id/:title" component={ProductView} />
                <Route exact path="/login/forgot_password" component={ChangeForgotPassword} />
                <CommonRoutes exact path="/request_images/:id/:title" component={RequestImage} />
                <CommonRoutes exact path="/product_posting" component={PostListing} />
                <CommonRoutes exact path="/checkout/:id/:title" component={Checkout} />
                {/* <Route exact path="/subscription" component={Subscription} /> */}
                <CommonRoutes exact path="/dashboard/buyer/myauction" component={BuyerMyAuction} />
                <CommonRoutes exact path="/dashboard/buyer/watchlist" component={BuyerWatchlist} />
                <CommonRoutes exact path="/dashboard/buyer/payment" component={BuyerPayment} />
                <CommonRoutes
                    exact
                    path="/dashboard/buyer/notification"
                    component={BuyerNotificaton}
                />
                <CommonRoutes exact path="/dashboard/buyer/profile" component={BuyerProfile} />
                <CommonRoutes
                    exact
                    path="/dashboard/seller/myauction"
                    component={SellerMyAuction}
                />
                <CommonRoutes exact path="/dashboard/seller/payment" component={BuyerPayment} />
                <CommonRoutes
                    exact
                    path="/dashboard/seller/notification"
                    component={BuyerNotificaton}
                />
                <CommonRoutes exact path="/dashboard/seller/profile" component={BuyerProfile} />
                <Route exact path="/liveauction" component={LiveAuction} />
                <Route exact path="/upcomingauction" component={UpcomingAuction} />
                <Route exact path="/pastauction" component={PastAuction} />
                <Route exact path="/gearstore" component={GearStore} />
                <Route exact path="/howitworks" component={HDW} />
                <Route exact path="/joinemaillist" component={JoinEmailList} />
                <Route exact path="/submitvehicle" component={SubmitVehicle} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/whatcoachgavel" component={WhatCG} />
                <Route exact path="/whycoachgavel" component={WhyCG} />
                <Route path="/verifyuser" component={EmailVerification} />
                <Route path="/privacy_policy" component={Privacy} />
                <CommonRoutes path="/invoice/:id/:title" component={Invoice} />
                <CommonRoutes path="/bank_details" component={BankDetails} />
            </Switch>
            {PropTypes.location.pathname === '/registration_step_1' ||
                PropTypes.location.pathname === '/registration_step_2' ||
                PropTypes.location.pathname === '/login' ||
                PropTypes.location.pathname.includes('/forgot_password') ||
                PropTypes.location.pathname === '/reset_password' ||
                PropTypes.location.pathname.includes('/bank_details') ||
                PropTypes.location.pathname.includes('/verifyuser') || (
                    <LazyLoad height={200} once={true} offset={100}>
                        {' '}
                        <Footer />
                    </LazyLoad>
                )}
        </>
    )
}
export default withRouter(Routes)
